import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized} from '@angular/router';
import {fuctionService, menu, TokenService, usersService} from '../core/';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzIconService} from 'ng-zorro-antd/icon';

@Component({
  selector: 'app-themenu',
  templateUrl: './themenu.component.html',
  styleUrls: ['./themenu.component.css']
})


export class ThemenuComponent implements OnInit {

  constructor(
    private tokenService: TokenService,
    private usersService: usersService,
    private message: NzMessageService,
    private iconService: NzIconService,
    private fuction: fuctionService,
    private router: Router,
  ) {
    this.iconService.fetchFromIconfont({
      scriptUrl: 'https://at.alicdn.com/t/font_8d5l8fzk5b87iudi.js'
    });
  }

  public isCollapsed = false;
  public arrmenu: menu[];
  public headmenu: menu;
  public index = 0;
  public headarrmenu: any = [];
  visible: boolean = false;
  public accoun = '';
  public management = false;
  public role: any;
  deadline = Date.now();
  public isVisible = false;
  public selectedValue = null;
  public functionId=0;
  public functionValue = [];

  ngOnInit(): void {

    const obj = {
      connecturl: '/TheMap',
      name: 'GIS地图'
    };
    this.headarrmenu.push(obj);
    this.index = (this.headarrmenu.length - 1);
    this.accoun = '账号：' + this.tokenService.getAccoun();
    let acc = this.tokenService.getAccoun();
    this.usersService.findAccount(acc).subscribe(item => {
      if (item.statusCode == 200) {
        if (item.content != null) {
          if (item.content.role !=null){
            this.management = item.content.role.management;
          }
          let date=new  Date(item.content.users.failure);
          this.deadline=date.getTime();
          this.selectofvalue();
        }
      } else {
        if (item.content.code == 40002) {
          this.message.create('error', item.content.subMsg);
        } else if (item.content.code == 20001) {
          this.message.create('error', item.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', item.content);
        }
      }
    });


  }


  onActivate(value:any){
    console.log(value);
  }

  selectofvalue():void{
    let  that=this;
    this.fuction.findFunctionFather().subscribe(data=>{
      if (data.statusCode == 200) {
        let  list=data.content;
        this.functionValue=list;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
         // this.message.create('error', data.content.subMsg);
          that.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

  handleOk(): void {
    if (this.functionId !=0){
      this.isVisible = false;
      this.router.navigate(['/show',this.functionId]);
    }
  }
  closeOk(): void {
    this.isVisible=false;
  }


  log(value:any): void {
    this.functionId=value;
  }



  ngAfterViewInit() {
    let that = this;

    setTimeout(() => {
      let token = that.tokenService.getToken();
      let accoun = that.tokenService.getAccoun();
      if (token == null || token == undefined || token == '') {
       that.router.navigate(['/login']);
      } else {
        that.usersService.checklogin(token, accoun).subscribe((data) => {
          if (data != null) {
            if (data.statusCode == 200) {
              that.router.navigate(['menu/TheMap']);
             // that.router.navigate(['/test']);
            } else {
              that.router.navigate(['/login']);
            }
          } else {
            that.router.navigate(['/login']);
          }
        });
        //usersService


      }

    }, 200);
  }

  goHeroes(connecturl) {
    this.router.navigate(['menu' + connecturl]);
    // connecturl
  }

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onClick(event, i) {
    //阻止父标签事件
    event.stopPropagation();
    this.headarrmenu.splice(i, 1);
    this.index = this.headarrmenu.length - 1;
    if (this.index != -1) {
      if (this.headarrmenu[this.index].connecturl == '/thisDataGeorAphic') {
        this.router.navigate(['menu' + this.headarrmenu[this.index].connecturl + '/0']);
      } else {
        this.router.navigate(['menu' + this.headarrmenu[this.index].connecturl]);
      }
    } else {
      this.router.navigate(['menu']);
    }

  }

  headandjump(clickmenu: menu) {
    if (clickmenu != null) {
      this.headarrmenu.push(clickmenu);
      this.headmenu = clickmenu;
      this.index = (this.headarrmenu.length - 1);

    }
  }


  headandjump2(url: string, name: string) {

    if (url != null) {

      // Management
        const obj = {
          connecturl: url,
          name: name
        };
        let d = this.headarrmenu.filter(item => item.name == name);
        if (d.length <= 0) {
          this.headarrmenu.push(obj);
          this.index = (this.headarrmenu.length - 1);
        } else {
          let findIndex = this.headarrmenu.findIndex(item => item.name == name);
          if (this.index != findIndex) {
            this.index = findIndex;
          } else {
            this.message.create('warning', '功能展示中,请不要重复点击');
          }
        }

    }
  }






  headand(url: string, name: string) {

    if (url != null) {
      const obj = {
        connecturl: url,
        name: name
      };
      let d = this.headarrmenu.filter(item => item.name == name);
      if (d.length <= 0) {

        this.isVisible=true;
      } else {
        let findIndex = this.headarrmenu.findIndex(item => item.name == name);
        if (this.index != findIndex) {
          this.index = findIndex;
        } else {
          this.message.create('warning', '功能展示中,请不要重复点击');
        }
      }

    }
  }





  clickMe(): void {
    let token = this.tokenService.getToken();
    this.usersService.DeleteLogin(token).subscribe(data => {
      if (data.statusCode == 200) {
        this.message.create('success', '您已退出!欢迎再次到来');
        this.router.navigate(['/login']);
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);

        } else {
          this.message.create('error', data.content);
        }
      }
    });
    this.visible = false;
  }

  cancel(): void {
  }
}
