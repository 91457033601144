<nz-layout class="app-layout"></nz-layout>
<div nz-row style="height: 100%">
  <div nz-col nzSpan="24">
    <button nz-button (click)="showModal()" nzType="primary">添加</button>
    <nz-table #editRowTable nzBordered [nzData]="listOfData">
      <thead>
      <tr>
        <th nzWidth="30%">角色名称</th>
        <th>创建时间</th>
        <th>等级</th>
        <th>后台管理</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of editRowTable.data" class="editable-row">
        <td>
          <div class="editable-cell">
            {{ data.rolename }}
          </div>
        </td>
        <td>{{ data.createtime }}</td>
        <td>{{ data.levelid }}</td>
        <td> <nz-switch  [ngModel]="data.management" [nzLoading]="loading"   (click)="clickSwitch(data.id,data.management)" nzCheckedChildren="开" nzUnCheckedChildren="关"></nz-switch></td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="添加" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm" (ngSubmit)="submitForm(validateForm.value)">

    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>角色名称</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <input nz-input formControlName="roleName" placeholder="请输入您的角色名称" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>等级(小则大,大则小)</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <nz-input-number   formControlName="levelId" [ngModel]="1"  [nzMax]="9" [nzMin]="1"  [nzStep]="1"></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>是否允许后台管理</nz-form-label>
      <nz-form-control [nzSpan]="12" >
        <nz-switch [ngModel]="false" formControlName="management" nzCheckedChildren="开" nzUnCheckedChildren="关"></nz-switch>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control [nzOffset]="7" [nzSpan]="12">
        <button nz-button nzType="primary">提交</button>
      </nz-form-control>
    </nz-form-item>

  </form>


</nz-modal>

