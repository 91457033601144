<nz-layout class="app-layout"></nz-layout>
<div nz-row style="height: 97%">
  <div ng-zorro nz-col [nzSpan]="4" class="leftoption" #leftheight >
    <nz-input-group [nzSuffix]="suffixIcon">
      <input type="text" nz-input placeholder="查找名称" [(ngModel)]="searchValue" />
    </nz-input-group>
    <ng-template #suffixIcon>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-tree
      [nzVirtualHeight]="leftoverheight"
      [nzData]="nodes"
      [nzSearchValue]="searchValue"
      (nzClick)="nzEvent($event)"
      (nzExpandChange)="nzEvent($event)"
      (nzSearchValueChange)="nzEvent($event)"
      [nzExpandAll]="false"
      nzShowIcon  >
    </nz-tree>
  </div>
  <div ng-zorro nz-col [nzSpan]="19" class="rightoption" #body>

    <!-- 查询条件-->
    <div nz-row>

    </div>

    <nz-drawer [nzClosable]="false" [nzVisible]="visible2" [nzPlacement]="placement" nzTitle="查询条件" (nzOnClose)="close()" nzHeight="40%">
      <form #formhead style="width: 100%;" nz-form [formGroup]="validateForm" class="ant-advanced-search-form">
      <div nz-row nzGutter="24" >
            <div nz-col [nzSpan]="4" >
              <nz-form-item>

                <nz-form-label>&nbsp;&nbsp;&nbsp;&nbsp;标&nbsp;&nbsp;&nbsp;&nbsp;识&nbsp;&nbsp;&nbsp;&nbsp;</nz-form-label>
                <nz-form-control>
                  <input class="input" nz-input  placeholder="标识"   formControlName="id"  />
                </nz-form-control>
              </nz-form-item>
            </div>

        <div nz-col [nzSpan]="4" >
          <nz-form-item>
            <nz-form-label>&nbsp;流&nbsp;&nbsp;水&nbsp;&nbsp;号&nbsp;&nbsp;</nz-form-label>
            <nz-form-control>
              <input nz-input class="input" placeholder="流水号"   formControlName="serialNumber"  />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="4" >
          <nz-form-item>
            <nz-form-label>&nbsp;&nbsp;&nbsp;&nbsp;车&nbsp;&nbsp;&nbsp;&nbsp;号&nbsp;&nbsp;&nbsp;&nbsp;</nz-form-label>
            <nz-form-control>
              <input nz-input  class="input" placeholder="车号"   formControlName="carId"  />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="4" >
          <nz-form-item>
            <nz-form-label>折方系数</nz-form-label>
            <nz-form-control>
              <input nz-input class="input" placeholder="折方系数"   formControlName="zfXs"  />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="4" >
          <nz-form-item>
            <nz-form-label>折&nbsp;&nbsp;方&nbsp;量</nz-form-label>
            <nz-form-control>
              <input nz-input class="input" placeholder="折方量"   formControlName="fl"  />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSpan]="4" >
          <nz-form-item>
            <nz-form-label>扣&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重</nz-form-label>
            <nz-form-control>
              <input nz-input class="input" placeholder="扣重"   formControlName="decWeight"  />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

        <div nz-row nzGutter="12">
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>&nbsp;&nbsp;&nbsp;规&nbsp;&nbsp;&nbsp;&nbsp;格&nbsp;&nbsp;&nbsp;&nbsp;</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="规格"   formControlName="grossWeight"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>皮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重&nbsp;</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="皮重"   formControlName="actualTare"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>&nbsp;&nbsp;&nbsp;&nbsp;净&nbsp;&nbsp;&nbsp;&nbsp;重&nbsp;&nbsp;&nbsp;&nbsp;</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="净重"   formControlName="netWeight"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>实&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;重</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="实重"   formControlName="trueWeight"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>&nbsp;&nbsp;单&nbsp;&nbsp;&nbsp;&nbsp;价&nbsp;&nbsp;</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="单价"   formControlName="unitPrice"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>过&nbsp;&nbsp;磅&nbsp;&nbsp;费</nz-form-label>
              <nz-form-control>

                <input nz-input class="input" placeholder="过磅费"   formControlName="weighingFee"  />
              </nz-form-control>
            </nz-form-item>
          </div>



        </div>

        <div nz-row nzGutter="12">
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>毛重过磅人</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="毛重过磅人"   formControlName="mzWeighMan"  />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>皮重过磅人</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="皮重过磅人"   formControlName="pzWeighMan"  />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>&nbsp;&nbsp;&nbsp;&nbsp;金&nbsp;&nbsp;&nbsp;&nbsp;额&nbsp;&nbsp;&nbsp;&nbsp;</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="金额"   formControlName="theMoney"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>创建时间</nz-form-label>
              <nz-form-control>
                <nz-form-control>
                  <nz-range-picker
                    [nzRanges]="ranges1"
                    nzShowTime
                    nzFormat="yyyy/MM/dd HH:mm:ss"
                    ngModel
                    (ngModelChange)="onChangecreate($event)"
                    formControlName="createTime"></nz-range-picker>
                </nz-form-control>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>修改时间</nz-form-label>
              <nz-form-control>

                <nz-range-picker
                  [nzRanges]="ranges2"
                  nzShowTime
                  nzFormat="yyyy/MM/dd HH:mm:ss"
                  ngModel
                  (ngModelChange)="onChangeUpdate($event)"
                  formControlName="updateTime"></nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>过磅类型</nz-form-label>
              <nz-form-control>
                <input nz-input class="input" placeholder="过磅类型"   formControlName="weighType"  />
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>

        <div nz-row nzGutter="12">
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>一次过磅时</nz-form-label>
              <nz-form-control>
                <nz-range-picker
                  [nzRanges]="ranges3"
                  nzShowTime
                  nzFormat="yyyy/MM/dd HH:mm:ss"
                  ngModel
                  (ngModelChange)="onChangeweighTime($event)"
                  formControlName="weighTime"></nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4" >
            <nz-form-item>
              <nz-form-label>二次过磅时</nz-form-label>
              <nz-form-control>
                <nz-range-picker
                  [nzRanges]="ranges4"
                  nzShowTime
                  nzFormat="yyyy/MM/dd HH:mm:ss"
                  ngModel
                  (ngModelChange)="onChangeweightWoTime($event)"
                  formControlName="weightWoTime"></nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row nzGutter="12">
          <div nz-col [nzSpan]="24" class="data-seach">
            <button nz-button nzType="primary" (click)="search()"><i nz-icon nzType="search"></i>查询</button>&nbsp;&nbsp;
            <button nz-button nzType="primary" nzDanger><i nz-icon nzType="line"></i>重置</button>
          </div>
        </div>


      </form>
    </nz-drawer>
    <div class="table-operations">
      <button nz-button nzType="primary" (click)="open()">新增</button>
    <button nz-button nzType="primary" (click)="open()">查询</button>
    </div>
<!--    [nzHideOnSinglePage]="true"-->
    <nz-table #editRowTable
              [nzShowPagination]="false"
              [nzScroll]="{ y: scrollY}"
              [nzPageSize]="pagesize"
              [nzLoading]="nzLoading"
              [nzData]="listOfData" nzTableLayout="fixed">
      <thead>
      <tr>
        <th nzWidth="140px" >标识</th>
        <th nzWidth="140px" [nzSortOrder]="null"   [nzSortFn]=""  [nzFilterMultiple]="true" >流水号</th>
        <th nzWidth="140px" >车牌号
          <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
            <i nz-icon nzType="search"></i>
          </nz-filter-trigger>
        </th>
        <th nzWidth="140px" >过磅类型</th>
        <th nzWidth="140px" >规格</th>
        <th nzWidth="140px" >皮重</th>
        <th nzWidth="140px" >净重</th>
        <th nzWidth="140px" >扣重</th>
        <th nzWidth="140px" >实重</th>
        <th nzWidth="140px" >超重</th>
        <th nzWidth="140px" >单价</th>
        <th nzWidth="140px" >金额</th>
        <th nzWidth="140px" >折方系数</th>
        <th nzWidth="140px" >折方量</th>
        <th nzWidth="140px" >过磅费</th>
        <th nzWidth="140px" >毛重司磅员</th>
        <th nzWidth="140px" >皮重司磅员</th>
        <th nzWidth="140px" >过磅时间</th>
        <th nzWidth="140px" >二次过磅时间</th>
        <th nzWidth="140px" >创建时间</th>
        <th nzWidth="140px" >修改时间</th>
        <th nzWidth="140px" > 操 作 </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of editRowTable.data" (click)="dataBase(data)" [ngStyle]="{'background-color':data.vehicle < data.trueWeight ? 'rgb(219,112,147)':'white'}">
        <ng-container *ngIf="!editCache[data.id].edit else editTemplate">
          <td>{{ data.id }}</td>
          <td>{{ data.serialNumber }}</td>
          <td>{{ data.carId }}</td>
          <!--<td>{{ data.functionId }}</td>-->
          <td>{{ data.weighType }}</td>
          <td>{{ data.grossWeight }}</td>
          <td>{{ data.actualTare }}</td>
          <td>{{ data.netWeight }}</td>
          <td>{{ data.decWeight }}</td>
          <td>{{ data.trueWeight }}</td>
          <td>{{ data.vehicle >= data.trueWeight ? 0 : data.trueWeight-data.vehicle }}</td>
          <td>{{ data.unitPrice }}</td>
          <td>{{ data.theMoney }}</td>
          <td>{{ data.zfXs }}</td>
          <td>{{ data.fl }}</td>
          <td>{{ data.weighingFee }}</td>
          <td>{{ data.mzWeighMan }}</td>
          <td>{{ data.pzWeighMan }}</td>
          <td>{{ data.weighTime }}</td>
          <td>{{ data.weightWoTime }}</td>
          <td>{{ data.createTime }}</td>
          <td>{{ data.updateTime }}</td>
          <!--<td>{{ data.address }}</td>-->
          <td><a (click)="startEdit(data.id);$event.cancelBubble=true;">Edit</a></td>
        </ng-container>
        <ng-template #editTemplate >
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.carId" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.weighType" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.grossWeight" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.actualTare" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.netWeight" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.decWeight" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.trueWeight" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.unitPrice" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.theMoney" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.zfXs" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.fl" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.weighingFee" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.mzWeighMan" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.pzWeighMan" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.weighTime" /></td>
          <td (click)="$event.cancelBubble=true;"><input type="text" nz-input [(ngModel)]="editCache[data.id].data.weightWoTime" /></td>
          <td (click)="$event.cancelBubble=true;">
            <a (click)="saveEdit(data.id)" class="save">Save</a>
            <a nz-popconfirm nzTitle="Sure to cancel?" (nzOnConfirm)="cancelEdit(data.id)">Cancel</a>
          </td>
        </ng-template>
      </tr>
      <tr>
        <td colspan="21">
        <nz-pagination
                       [nzPageIndex]="pagenum"
                       [nzTotal]="Total"
                       (nzPageIndexChange)="changepage($event)"
                       (nzPageSizeChange)="changePageSize($event)"
                       [nzPageSizeOptions]=[10,20,30,50,100]
                       nzShowSizeChanger
                       nzShowQuickJumper ></nz-pagination>
        </td>
      </tr>
      </tbody>
      </nz-table>

  </div>
</div>

<!--<nz-drawer  [nzClosable]="false" [nzVisible]="nzVisible" [nzHeight]="divewheight" [nzPlacement]="nzPlacement"-->
<!--            [nzTitle]="nzTitle" (nzOnClose)="Closeimage()">-->
<!--<div nz-row>-->
<!--  <div nz-col [nzSpan]="4">-->
<!--    <nz-space nzDirection="vertical">-->
<!--      <nz-space-item>-->
<!--        <nz-card nzTitle="数据信息" style="width: 300px">-->
<!--          <p>流水号：  {{nzCard.serialNumber}}</p>-->
<!--          <p>过磅时间：{{nzCard.weighTime}}</p>-->
<!--          <p>规格：   {{nzCard.grossWeight}}</p>-->
<!--        </nz-card>-->
<!--      </nz-space-item>-->
<!--    </nz-space>-->

<!--  </div>-->
<!--<div nz-col [nzSpan]="20">-->
<!--  <section >-->
<!--    <div dImagePreview >-->
<!--      <nz-space >-->
<!--        <div nz-col [nzSpan]="6" *ngFor="let array of images;let i=index" >-->
<!--        <nz-space-item >-->
<!--        <img  (click)="nzVisibleClose($event,14+i+1)"   [hidden]="array.isdel== 1 ? true :false " src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{array.imageurl}}"  class="imageurl" />-->
<!--        </nz-space-item>-->
<!--        </div>-->
<!--      </nz-space>-->
<!--    </div>-->
<!--  </section>-->
<!--</div>-->
<!--</div>-->
<!--  <div nz-row>-->
<!--    <div nz-col [nzSpan]="18"></div>-->
<!--    <div nz-col [nzSpan]="6" *ngFor="let array of videos">-->
<!--    <video  width="100%" height="50%"    src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{array.videoUrl}}" controls="controls">-->
<!--      您的浏览器不支持 video 标签。-->
<!--    </video>-->
<!--    </div>-->
<!--  </div>-->
<!--</nz-drawer>-->




<nz-modal [(nzVisible)]="ShowMessage" nzTitle="信息窗口" (nzOnCancel)="Closeimage()" (nzOnOk)="Closeimage()"
          [nzWidth]="'70%'" style="z-index: 0;">

  <nz-descriptions nzTitle="检测数据" nzBordered [nzSize]="['default']">
    <nz-descriptions-item nzTitle="流水号">{{nzCard.serialNumber}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="源头企业">{{nzCard.enterpriseName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="站点">{{nzCard.functionName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="车牌号">
      {{nzCard.carId}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="车轴数" [nzSpan]="2">
      {{nzCard.shaftNumber}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="实重" [nzSpan]="3">
      <nz-badge nzStatus="processing" nzText="{{nzCard.trueWeight}}"></nz-badge>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="超限量">{{amountChang(nzCard.trueWeight,nzCard.vehicle)}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="标准">{{nzCard.vehicle}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="检测时间">{{timeChange(nzCard.createTime)}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="过磅文件" [nzSpan]="3">

      <div *ngFor="let data of urlList">
        <img src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{data.fileUrl}}" *ngIf="data.fileType=='image'"
             [ngStyle]="{ width: '100px',height:'100px',float:'left',margin:'0px 0px 0px 30px'}"
             (click)="imageClick(data.id)">

        <video src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{data.fileUrl}}"
               [ngStyle]="{ width: '200px',height:'100px',float:'left',margin:'0px 0px 0px 30px' }"
               *ngIf="data.fileType=='video'"
               controls="controls" (click)="imageClick(data.id)">您的浏览器不支持 video 标签。
        </video>
      </div>

    </nz-descriptions-item>

  </nz-descriptions>

</nz-modal>



  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible = false" nzWidth="60%">

    <ng-template #modalContent >

      <button id="up-map-left" nz-button nzType="primary" nzShape="circle"
              style="left:-10%;top: 50%;position:absolute;z-index:9999;" (click)="clickLeft()">
        <i nz-icon nzType="left"></i>

      </button>
      <img [src]="previewImage" [ngStyle]="{ width: '100%'}" *ngIf="fileType=='image'"/>

      <video [src]="previewImage" *ngIf="fileType=='video'"
             [ngStyle]="{ width: '100%' }"
             controls="controls">您的浏览器不支持 video 标签。
      </video>

      <button id="up-map-right" nz-button nzType="primary" style="right:-10%;top: 50%;position:absolute;z-index:9999;"
              nzShape="circle" (click)="clickRight()"><i nz-icon nzType="right"></i></button>

    </ng-template>

  </nz-modal>





<nz-dropdown-menu #menu="nzDropdownMenu">
  <div class="ant-table-filter-dropdown">
    <div class="search-box">
      <input type="text" nz-input placeholder="输入车牌号" [(ngModel)]="carIdseach" />
      <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">
        查看
      </button>
      <button nz-button nzSize="small" (click)="reset()">清空</button>
    </div>
  </div>
</nz-dropdown-menu>
