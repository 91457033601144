<nz-layout class="app-layout"></nz-layout>

<div nz-row style="height: 100%">
  <div nz-row style="height: 10%">
    <ul nz-menu nzMode="horizontal">
      <li nz-menu-item nzSelected (click)="clickshowall(0)">
        <i nz-icon nzType="user"></i>
        基本信息
      </li>
      <li nz-menu-item (click)="clickshowall(1)">
        <i nz-icon nzType="field-time"></i>
        使用失效时间
      </li>

      <li nz-menu-item (click)="clickshowall(2)">
        <i nz-icon nzType="key"></i>
        修改密码
      </li>

      <li nz-menu-item (click)="clickshowall(3)">
        <i nz-icon nzType="idcard"></i>
        角色设置
      </li>

      <li nz-menu-item (click)="clickshowall(4)">
        <i nz-icon nzType="ordered-list"></i>
        功能权限设置
      </li>
    </ul>
  </div>

  <div nz-row style="width: 88%;margin-top: 30px;">

    <div nz-col nzSpan="24" *ngIf="basi">
      <form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm" (ngSubmit)="submitForm(validateForm.value)">
        <nz-form-item #autoTips>
          <nz-form-label [nzSpan]="7" nzRequired>姓名</nz-form-label>
          <nz-form-control [nzSpan]="12" nzValidatingTip="Validating...">
            <input nz-input formControlName="userName" placeholder="你的姓名"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>手机号码</nz-form-label>
          <nz-form-control [nzSpan]="12">
            <input nz-input formControlName="mobile" placeholder="手机号码"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>邮箱</nz-form-label>
          <nz-form-control [nzSpan]="12">
            <input nz-input formControlName="email" readonly onfocus="this.removeAttribute('readonly');"
                   placeholder="邮箱地址" type="email"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>身份证号码</nz-form-label>
          <nz-form-control [nzSpan]="12" nzDisableAutoTips nzErrorTip="你的身份证!">
            <input nz-input formControlName="idCard" placeholder="身份证号码" (ngModelChange)="validateConfirmPassword()"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>年龄</nz-form-label>
          <nz-form-control [nzSpan]="12" nzDisableAutoTips nzErrorTip="你的年龄!">
            <input nz-input formControlName="age" placeholder="年龄" (ngModelChange)="validateConfirmPassword()"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>公司名称</nz-form-label>
          <nz-form-control [nzSpan]="12" nzDisableAutoTips nzErrorTip="你的公司!">
            <input nz-input formControlName="company" placeholder="公司名称" (ngModelChange)="validateConfirmPassword()"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>角色名称</nz-form-label>
          <nz-form-control [nzSpan]="12" nzDisableAutoTips nzErrorTip="你的角色!">
            <input nz-input formControlName="roleName" placeholder="角色名称" (ngModelChange)="validateConfirmPassword()"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary">提交</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>

    <div nz-col nzSpan="24" *ngIf="timeOver">
      <div nz-row>
        <nz-countdown style="margin: auto;" [nzValue]="deadline" [nzTitle]="'剩余使用时间'"
                      [nzFormat]="'D 天 H 时 m 分 s 秒'"></nz-countdown>
      </div>
      <form nz-form [formGroup]="timeForm" (ngSubmit)="timeSubmit()">
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>使用失效时间</nz-form-label>
          <nz-form-control [nzSpan]="12" nzDisableAutoTips nzErrorTip="请填写失效时间!">
            <nz-form-control>
              <nz-date-picker
                formControlName="failure"
                [ngModel]="startValue"
                nzShowTime
                (nzOnOpenChange)="handleDateOpenChange($event)"
                (nzOnPanelChange)="handleDatePanelChange($event)">
              </nz-date-picker>
            </nz-form-control>

          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary">提交</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div nz-col nzSpan="24" *ngIf="passWord">
      <form nz-form [formGroup]="validateForm" (ngSubmit)="passWordSubmit()">

        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password" nzRequired>密码</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="输入你的密码!">
            <input nz-input type="password" id="password" formControlName="password"
                   (ngModelChange)="updateConfirmValidator()"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>确认密码</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
            <input nz-input type="password" formControlName="confirm" id="checkPassword"/>
            <ng-template #errorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">
                请确认你的密码!
              </ng-container>
              <ng-container *ngIf="control.hasError('confirm')">
                2此输入密码一致!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary">提交</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>

    <div nz-col nzSpan="24" *ngIf="setupthe">
      <form nz-form [formGroup]="roleForm" (ngSubmit)="submitrole()">
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired>当前角色</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <div nz-row [nzGutter]="16">
              <div nz-col nzSpan="6">
                <input nz-input formControlName="roleName" placeholder="角色名称"/>
              </div>
              <div nz-col nzSpan="6">
                <!--                (nzOpenChange)="change($event)"-->
                <nz-select [(ngModel)]="selectedProvince" formControlName="roleNameup" nzAllowClear
                           nzPlaceHolder="请选择！">
                  <nz-option *ngFor="let data of rolses" nzValue="{{data.id}}" nzLabel="{{data.rolename}}"></nz-option>
                </nz-select>
              </div>

            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item></nz-form-item>

        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary">提交</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>

    <div nz-col nzSpan="24" *ngIf="function">

      <button nz-button (click)="showModal()" nzType="primary">添加</button>
      <br/>
      <br/>
      <nz-table #editRowTable nzBordered [nzData]="listOfData">
        <thead>
        <tr>
          <th nzWidth="30%">功能名称</th>
          <th>备注</th>
          <th>创建时间</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of editRowTable.data" class="editable-row">
          <td>
<!--            [hidden]="editId === data.id" (click)="startEdit(data.id)"-->
            <div class="editable-cell"  >
              {{ data.functionname }}
            </div>
<!--            <input [hidden]="editId !== data.id" type="text" nz-input [(ngModel)]="data.functionname"-->
<!--                   (blur)="stopEdit()"/>-->
          </td>
          <td>
            {{ data.remark }}
          </td>
          <td>{{ data.createtime }}</td>
          <td>
            <a nz-popconfirm nzPopconfirmTitle="是否确认删除?" (nzOnConfirm)="deleteRow(data.id)">删除</a>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>

  </div>
</div>


<nz-modal [(nzVisible)]="isVisible" nzTitle="赋给操作" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <nz-table #editRowTable nzBordered [nzData]="loginOfData">
    <thead>
    <tr>
      <th nzWidth="30%">功能名称</th>
      <th>备注</th>
      <th>创建时间</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of editRowTable.data" class="editable-row">
      <td>
        <div class="editable-cell"  >
          {{ data.functionname }}
        </div>
      </td>
      <td>
        {{ data.remark }}
      </td>
      <td>{{ data.createtime }}</td>
      <td>
        <a nz-popconfirm nzPopconfirmTitle="确认赋给当前人员?" (nzOnConfirm)="addRowList(data.id)">赋给</a>
      </td>
    </tr>
    </tbody>
  </nz-table>
</nz-modal>
