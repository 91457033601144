import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {companyService, usersService} from '../core/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  validateForm: FormGroup;
  isVisible = false;
  listOfData: any[] = [];
  id=0;

  constructor(
    private router: Router,
    private usersService: usersService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private company:companyService
  ) {
    this.validateForm = this.fb.group({
      companyName: ['', [this.confirmValidator]],
      count: ['', [this.confirmValidator]],
    });
  }

  ngOnInit(): void {

    let that = this;
    that.company.findAll().subscribe(data=>{
      if (data.statusCode == 200) {
        that.listOfData = data.content;
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          that.message.create('error', data.content);
        }
      }
    });

  }

  update(companyName:string,id:number){
    this.validateForm.controls.companyName.setValue(companyName);
    this.isVisible=true;
    this.id=id;
  }


  submitForm(value: { companyName: string; count: number; }): void {

    let that = this;
      this.company.addCompany(that.id,value.companyName, value.count+'').subscribe(data => {
        if (data.statusCode == 200) {
          that.isVisible=false;
          this.message.create("success","添加成功")
          that.ngOnInit();
        } else {
          if (data.content.code == 40002) {
            that.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            that.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            that.message.create('error', data.content);
          }
        }
      });

  }


  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {error: true, required: true};
    }
    return {};
  };

  showModal(): void {
    this.isVisible = true;
    this.id=0;
  }
  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  autoTips: Record<string, Record<string, string>> = {
    'zh-cn': {
      required: '必填项',
    }
  };

}
