import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TestComponent} from './test/test.component';
import {LoginComponent} from './login/login.component';
import {ThemenuComponent} from './themenu/themenu.component';
import {GeographicDataComponent} from './geographic-data/geographic-data.component';
import {ThemapComponent} from './themap/themap.component';
import {ReportComponent} from './report/report.component';
import {ManagementComponent} from './management/management.component';
import {UsersComponent} from './users/users.component';
import {ActorComponent} from './actor/actor.component';
import {ShowComponent} from './show/show.component';
import {ShowVideoComponent} from './show-video/show-video.component';

import {CompanyComponent} from './company/company.component';
import {ElectronicsComponent} from './electronics/electronics.component';
import {NoSiteGuard} from './core/guard';
const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    data: {title: '登录'}
  },
  {
    path: 'test',
    component: TestComponent,
    data: {title: '登录'}
  },

  {
    path: 'menu',
    component: ThemenuComponent,
    children: [
      {
        path: 'thisDataGeorAphic/:linkfunctionId',
        component: GeographicDataComponent,
        data: {title: '实时数据'},
        canActivate:[NoSiteGuard]
      }, {
        path: 'TheMap',
        component: ThemapComponent,
        data: {title: 'GIS地图'},
        canActivate:[NoSiteGuard]
      },
      {
        path: 'Report',
        component: ReportComponent,
        data: {title: '报表'},
        canActivate:[NoSiteGuard]
      },  {
        path: 'showVideo',
        component: ShowVideoComponent,
        data: {title: '视频'},
        canActivate:[NoSiteGuard]
      }, {
        path: 'electronics',
        component: ElectronicsComponent,
        data: {title: '电子围栏'},
        canActivate:[NoSiteGuard]
      }],

  },
  {
    path: 'Management',
    component: ManagementComponent,
    children: [
      {
        path: 'use/:usersId',
        component: UsersComponent,
        data: {title: '实时数据'}
      },{
        path: 'Actor',
        component: ActorComponent,
        data:{title: '角色管理'}
      },{
        path: 'Company',
        component: CompanyComponent,
        data:{title: '角色管理'}
      }
      ]
  }, {
    path: 'show/:functionId',
    component: ShowComponent,
    data: {title: '大屏映射'}
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes),RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
