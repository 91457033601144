import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class imagesService {
  constructor(
    private apiService: ApiService
  ) { }

  findImagesByweightId(weightId:number) {
    return this.apiService.callApi('/ImagesController/findImagesByweightId',
      {
        weightId: weightId,
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

}
