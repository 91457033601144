<!--<nz-layout class="app-layout"></nz-layout>-->
<div nz-row id="html">
  <!--背景-->
  <div nz-row id="body">
    <div nz-col [nzSpan]="8"></div>
    <!--显示登录内容-->
    <div nz-col [nzSpan]="8" class="login">


      <section>
        <d-tabs [type]="'tabs'" (activeTabChange)="activeTabChange($event)" class="login-form">
          <d-tab id="tab1" title="账密登录" tabId="tab1">
            <ng-template dTabContent>
              <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" class="forms-sample">
                <!-- 账号-->
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please input your username!">
                    <nz-input-group nzPrefixIcon="user">
                      <input type="text" nz-input formControlName="userName" placeholder="账号/手机号/身份证/邮箱"/>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <!-- 密码-->
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please input your Password!">
                    <nz-input-group nzPrefixIcon="lock">
                      <input type="password" nz-input formControlName="password" placeholder="密码"/>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>

                <div nz-row class="login-form-margin" nzGutter="4">
                  <div nz-col [nzSpan]="12" class="rememberpassword">
                    <!--formControlName="remember"-->
                    <label nz-checkbox>
                      <span>记住密码</span>
                    </label>
                  </div>
                  <div nz-col [nzSpan]="12">
                    <a class="login-form-forgot" (click)="RetrievePassword()">找回密码</a>
                  </div>
                </div>
                <d-toast [value]="msgs"></d-toast>
                <d-button type="submit"
                          bsStyle="primary" class="login-form-button login-form-margin"
                          style="width: 65%;margin-left: 18%;margin-top: 5%;" width="100%">登 录
                </d-button>

                <div nz-row class="login-form-margin">
                  <a class="registered" (click)="open()">免费注册</a>
                </div>
                <br/>

              </form>
            </ng-template>
          </d-tab>

          <d-tab id="tab2" title="扫码登录" tabId="tab2">
            <ng-template dTabContent>
              <p style="color: white">当前版本暂不支持</p>
            </ng-template>
          </d-tab>
        </d-tabs>
      </section>
    </div>

    <div nz-col [nzSpan]="8"></div>
  </div>

</div>



<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="找回 密码"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzOkLoading]="isOkLoading">

  <form nz-form [formGroup]="validateForm"  *ngIf="!result" (ngSubmit)="submitRetrieve()">
    <nz-form-item>
      <nz-input-group nzPrefixIcon="user">
        <input type="text" nz-input formControlName="userName" placeholder="账号/手机号/身份证/邮箱"/>
      </nz-input-group>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="captcha" nzRequired>验证码</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="请 输 入 你 得 到 的 验 证 码!"
        nzExtra="我 们 必 须 确 保 你 是 不 是 一 个 人.">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="12">
            <input nz-input formControlName="captcha"/>
          </div>
          <div nz-col [nzSpan]="12">
            <button nz-button [disabled]="prohibit" (click)="sendOut($event)">发送</button>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
    <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">下一步</button>
  </form>

  <form nz-form [formGroup]="validateForm" (ngSubmit)="updatePassword()" *ngIf="result">

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password" nzRequired>密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="combineTpl">
        <input nz-input type="password" formControlName="passwordRegistered"
               (ngModelChange)="updateConfirmValidator()"/>
        <ng-template #combineTpl let-control>
          <ng-container *ngIf="control.hasError('confirm')">密码不能低于6位</ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>确认密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
        <input nz-input type="password" formControlName="checkPassword"/>
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            请确认的密码!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            输入密码与上次输入不一致!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">重置 密码</button>
  </form>



</nz-modal>


<nz-drawer
  [nzClosable]="false"
  [nzOffsetX]="childrenVisible ? 180 : 0"
  [nzWidth]="320"
  [nzVisible]="visible"
  nzTitle="注册账号"
  (nzOnClose)="close()">
  <form nz-form [formGroup]="validateForm" (ngSubmit)="submitFormRegistered()">
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">邮箱</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="输入你的邮箱！">
        <input nz-input formControlName="email" id="email" readonly onfocus="this.removeAttribute('readonly');"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password" nzRequired>密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="combineTpl">
        <input nz-input type="password" id="passwordRegistered" formControlName="passwordRegistered"
               (ngModelChange)="updateConfirmValidator()"/>
        <ng-template #combineTpl let-control>
          <ng-container *ngIf="control.hasError('confirm')">密码不能低于6位</ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>确认密码</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
        <input nz-input type="password" formControlName="checkPassword" id="checkPassword"/>
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            请确认的密码!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            输入密码与上次输入不一致!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>公司</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="companyTpl">
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="选择您的公司" formControlName="company"
                   [(ngModel)]="selectedValue">
          <nz-option *ngFor="let data of companyData" nzLabel="{{data.companyName}}" nzValue="{{data.id}}"></nz-option>
        </nz-select>
        <ng-template #companyTpl let-control>
          <ng-container *ngIf="control.hasError('confirm')">
            请选您的公司名称
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="idCard" nzRequired>
          <span>
            身份证
          </span>
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorCardTpl">
        <input nz-input id="idCard" formControlName="idCard"/>
        <ng-template #errorCardTpl let-control>
          <ng-container *ngIf="control.hasError('confirm')">
            请输入正确的身份证号!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="nickname" nzRequired>
          <span>
            名称
            <i nz-icon nz-tooltip nzTooltipTitle=" 你 的 名 字 叫 什 么 " nzType="question-circle" nzTheme="outline"></i>
          </span>
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="输 入 你 的 名 字!">
        <input nz-input id="nickname" formControlName="nickname"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber" nzRequired>手机号码</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzValidateStatus]="validateForm.controls['phoneNumber']"
        nzErrorTip="请 输 入 你 的 手 机 号 码!">
        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
          <ng-template #addOnBeforeTemplate>
            <nz-select formControlName="phoneNumberPrefix" class="phone-select">
              <nz-option nzLabel="+86" nzValue="+86"></nz-option>
              <nz-option nzLabel="+87" nzValue="+87"></nz-option>
            </nz-select>
          </ng-template>
          <input formControlName="phoneNumber" id="'phoneNumber'" nz-input/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="captcha" nzRequired>验证码</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="请 输 入 你 得 到 的 验 证 码!"
        nzExtra="我 们 必 须 确 保 你 是 不 是 一 个 人.">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="12">
            <input nz-input formControlName="captcha" id="captcha"/>
          </div>
          <div nz-col [nzSpan]="12">
            <button nz-button [disabled]="prohibit" (click)="getCaptcha($event)">发送</button>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="registrationcode" nzRequired>激活码</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="请 输 入 你 得 到 的 激 活 码!">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="24">
            <input nz-input formControlName="registrationcode"/>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>


    <nz-form-item nz-row class="register-area">
      <nz-form-control [nzSpan]="14" [nzOffset]="6">
        <label nz-checkbox formControlName="agree">
          <span>我同意 <a>协议</a></span>
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row class="register-area">
      <nz-form-control [nzSpan]="14" [nzOffset]="6">
        <button nz-button nzType="primary">注册</button>
      </nz-form-control>
    </nz-form-item>
  </form>

</nz-drawer>




