<nz-layout class="app-layout"></nz-layout>
<div nz-row style="height: 100%">

  <div ng-zorro nz-col [nzSpan]="4" #leftheight>
    <nz-input-group [nzSuffix]="suffixIcon">
      <input type="text" nz-input placeholder="查找名称" [(ngModel)]="searchValue"/>
    </nz-input-group>
    <ng-template #suffixIcon>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-tree
      [nzVirtualHeight]="leftoverheight"
      [nzData]="nodes"
      [nzSearchValue]="searchValue"
      (nzClick)="nzEvent($event)"
      (nzExpandChange)="nzEvent($event)"
      (nzSearchValueChange)="nzEvent($event)"
      [nzExpandAll]="false"
      nzShowIcon>
    </nz-tree>
  </div>
  <div ng-zorro nz-col [nzSpan]="2">
    <div class="addVideo">
      <button nz-button nzType="primary" (click)="showModal()">添加摄像头</button>
      <nz-select ngModel="four" (ngModelChange)="selectChang($event)">
        <nz-option nzValue="one" nzLabel="1*1"></nz-option>
        <nz-option nzValue="two" nzLabel="2*2"></nz-option>
        <nz-option nzValue="three" nzLabel="3*3"></nz-option>
        <nz-option nzValue="four" nzLabel="4*4"></nz-option>
      </nz-select>
    </div>
  </div>

  <div ng-zorro nz-col [nzSpan]="18" #body style="height: 100%">
    <div nz-row style="height: 100%" nzGutter="12">



      <div *ngFor="let data of listOfData" nz-col [nzSpan]="sizeColl" [ngStyle]="{'height':heightVideo+'%'}">
        <div *ngIf="data.status==false" nz-col [nzSpan]="24"
             style="width:100%;height:100%;text-align: center;font-size: 30px;border: 1px black solid;">
          当前设备不在线....
        </div>

        <div class="hello-ezuikit-js" style="height: 100%;width: 100%;" *ngIf="data.status== true">
          <div id="video-container{{data.id}}" style="width:100%;height:100%;border: 1px black solid;"></div>
        </div>
      </div>

      <div *ngIf="obj== undefined || obj== null || obj.length<=0" nz-col [nzSpan]="24"
           style="text-align: center;font-size: 40px">
        选中站点查看设备/无设备在线
      </div>
    </div>
  </div>

  <nz-modal [(nzVisible)]="isVisible" nzTitle="数据维护" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
    <div nz-row nzGutter="12" >
      <div ng-zorro nz-col [nzSpan]="24">

        <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" *ngIf="cz">
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="deviceName" nzRequired>
              设备名称
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入你的名称!">
              <input nz-input id="deviceName" formControlName="deviceName"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="deviceName" nzRequired>
              设备序列号
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入序列号!">
              <input nz-input id="deviceSerial" formControlName="deviceSerial"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="validateCode" nzRequired>
              验证码
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入序列号!">
              <input nz-input id="validateCode" formControlName="validateCode"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="validateCode" nzRequired>
              萤石KEY
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请输入Key值!">
              <input nz-input id="appKey" formControlName="appKey"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="validateCode" nzRequired>
              萤石秘钥
            </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="萤石秘钥!">
              <input nz-input id="appSecret" formControlName="appSecret"/>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
              <button nz-button [nzType]="'primary'" style="margin: 0 auto;">提交</button>
              <button nz-button (click)="sendRequest()" style="margin: 0 auto;">取消</button>
          </nz-form-item>

        </form>

        <div class="send-request" *ngIf="!cz">
          <button nz-button nzType="primary" (click)="sendRequest()">
            添加
          </button>
        </div>
        <nz-table #basicTable [nzData]="listOfData" *ngIf="!cz">
          <thead>
          <tr>
            <th>设备名称</th>
            <th>设备序列号</th>
            <th>验证码</th>
            <th>是否在线</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.deviceName }}</td>
            <td>{{ data.deviceSerial }}</td>
            <td>{{ data.validateCode }}</td>
            <td>{{ data.status == true ? '在线' : '离线' }}</td>
            <td>
              <a
                  nz-popconfirm
                 nzPopconfirmTitle="是否确认删除?"
                 (nzOnConfirm)="deleteData(data.id)">删除</a>
            </td>
          </tr>
          </tbody>
        </nz-table>

      </div>
    </div>
  </nz-modal>


</div>
