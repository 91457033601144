import { Injectable } from '@angular/core';


@Injectable()
export class TokenService {

  getToken(): string {
    return window.localStorage['token'];
  }
  getAccoun(): string {
    return window.localStorage['account'];
  }

  saveAccount(account: string) {
    window.localStorage['account'] = account;
  }

  saveToken(token: string) {
    window.localStorage['token'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('token');
  }

}
