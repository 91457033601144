import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import EZUIKit from 'ezuikit-js';
import {NzFormatEmitEvent, NzTreeNodeOptions} from 'ng-zorro-antd';
import {EquipmentService, TokenService, usersService} from '../core/services';
import {TransferItem} from 'ng-zorro-antd/transfer';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';
import {NzMessageService} from 'ng-zorro-antd/message';
import {FormBuilder, FormGroup} from '@angular/forms';
import {util} from '../Util';
import {Router} from '@angular/router';

let options = [];

@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
  styleUrls: ['./show-video.component.css']
})
export class ShowVideoComponent implements OnInit {

  @ViewChild('leftheight') leftheight: ElementRef;
  @ViewChild('body') elementView: ElementRef;
  public leftoverheight = '10000px';
  public searchValue = '';
  public scrollY = '0px';
  public nodes: NzTreeNodeOptions[] = [];
  public tsFer: any[] = [];
  public choseValue = [];
  public mapThe = [];
  list: TransferItem[] = [];
  nzOptionsLeft: NzCascaderOption[] = options;
  nzOptionsRight: NzCascaderOption[] = options;
  disabled = false;
  public obj: any[];
  public listOfData: any[];
  public sizeColl=6;
  public heightVideo=25;
  public isVisible = false;
  public validateForm!: FormGroup;
  public cz=false;
  public functionId=0;
  constructor(
    private tokenService: TokenService,
    private usersService: usersService,
    private message: NzMessageService,
    private equipmentService: EquipmentService,
    private fb: FormBuilder,
    private router: Router,
    private utils:util
  ) {
  }
  submitForm() {
    let deviceName=this.validateForm.controls.deviceName.value;
    let deviceSerial=this.validateForm.controls.deviceSerial.value;
    let validateCode=this.validateForm.controls.validateCode.value;

    let appKey=this.validateForm.controls.appKey.value;
    let appSecret=this.validateForm.controls.appSecret.value;

    let functionId=this.functionId;

    if (this.utils.isNull(appKey)){
      this.message.create("warning","请填写萤石KEY");
      return false;
    }
    if (this.utils.isNull(appSecret)){
      this.message.create("warning","请填写秘钥");
      return false;
    }
    if (this.utils.isNull(validateCode)){
      this.message.create("warning","请填写摄像验证码");
      return false;
    }
    if (this.utils.isNull(deviceSerial)){
      this.message.create("warning","请填写摄像请输入序列号");
      return false;
    }
    if (this.utils.isNull(deviceName)){
      this.message.create("warning","请填写验证码");
      return false;
    }

    this.equipmentService.addEquipment(deviceName,deviceSerial,validateCode,functionId,appKey,appSecret).subscribe(data=>{
      if (data.statusCode == 200) {
        this.dataRash(this.functionId);
        this.message.create('success',"添加成功");
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
    this.isVisible=false;
  }
  sendRequest():void{
    this.cz=!this.cz;
  }

  showModal():void{
  this.isVisible = true;
}
  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      deviceName: [''],
      deviceSerial: [''],
      validateCode: [''],
      appKey: [''],
      appSecret: [''],
    });
    this.FunctionLoading();
  }

  onChange(status: boolean): void {
    //console.log(status);
  }

  selectChang(value:string):void{
    if (value=="one"){
      this.sizeColl=24;
      this.heightVideo=100;
    }else if (value=="two"){
      this.sizeColl=12;
      this.heightVideo=50;
    }else if (value=="three"){
      this.sizeColl=8;
      this.heightVideo=33;
    }else if (value=="four"){
      this.sizeColl=6;
      this.heightVideo=25;
    }
  }

  FunctionLoading(): void {
    let that = this;
    let accoun = this.tokenService.getAccoun();
    this.usersService.findFunction(accoun).subscribe((data) => {
      //成功
      if (data.statusCode == 200) {
        this.tsFer = data.content;
        this.nodes = this.forArrayXzTree(data.content);
        that.nzOptionsLeft = that.mapThe;
        that.nzOptionsRight = that.mapThe;
        this.disabled = true;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

  ngAfterViewInit() {
    let a: any;

    setTimeout(() => {
      let isnull = this.leftheight.nativeElement.offsetParent;
      if (isnull != null && isnull != undefined && isnull != 0) {
        let height = this.leftheight.nativeElement.offsetParent.clientHeight;
        let worm = height / 100;
        let x = Math.round(worm * 93);
        this.leftoverheight = x + 'px';
        let height2 = this.elementView.nativeElement.offsetParent.clientHeight;
        //  let head = this.elementhead.nativeElement.clientHeight
        let dij = height2 / 10;
        // let hedas = height2 - dij - (dij / 2) - head;
        let hedas = height2 - dij - (dij / 2);
        this.scrollY = hedas + 'px';

      }
    });
  }

  /**
   * 点击区域文字
   * @param event
   */
  nzEvent(event: NzFormatEmitEvent): void {
    let that = this;
    let isfunction = event.node.key['isfunction'];
    if (!isfunction) {
      let id = event.node.key['id'];
      this.functionId=id;
    this.dataRash(id);
    }

  }

  dataRash(id:number){
    let that = this;
    this.equipmentService.findEquipmentByFunctionId(id).subscribe(data => {
      if (data.statusCode == 200) {
        that.listOfData = data.content;
        that.obj = data.content.filter(item=>item.status==true);
        if (that.listOfData.length==1){
          this.sizeColl=24;
          this.heightVideo=100;
        }else if (that.listOfData.length>1 && that.listOfData.length<=4){
          this.sizeColl=12;
          this.heightVideo=50;
        }else if (that.listOfData.length>4 && that.listOfData.length <=6){
          this.sizeColl=8;
          this.heightVideo=50;
        }else if (that.listOfData.length>6 && that.listOfData.length <=16){
          this.sizeColl=6;
          this.heightVideo=25;
        }
        setTimeout(() => {
          that.obj .forEach(data => {
            new EZUIKit.EZUIKitPlayer({
              autoplay: true,
              id: 'video-container' + data.id,
              accessToken: data.accessToken,
              url: 'ezopen://open.ys7.com/' + data.deviceSerial + '/1.hd.live',
              template: 'simple'
              // width: 600,
              // height: 400
            });
          });
        });
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }



  // ngAfterViewChecked():void{
  //    console.log(this.obj);
  // }

  forArrayXzTree(obj: any) {
    let that = this;
    let array = JSON.parse(JSON.stringify(obj));
    //开始遍历大层遍历
    let datasnodes = [];

    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == null || obj[i].functionid == undefined || obj[i].functionid == 0) {
          if (obj[i].functionname != null && obj[i].functionname != undefined) {
            let father: NzTreeNodeOptions = {
              title: obj[i].functionname,
              key: obj[i],
              icon: 'apartment',
              children: [],
              isLeaf: false
            };
            let moneyNice = {
              value: obj[i].id,
              label: obj[i].functionname,
              children: [],
              isLeaf: false
            };
            that.choseValue.push(obj[i].functionname);
            //删除了一条数据
            let thisid = obj[i].id;
            let das = this.forArrayXzTreemou(array, thisid);
            array.splice(i, 1);
            if (das.mob.length > 0) {
              father.children = das.mob;
              moneyNice.children = das.mos;
            } else {
              father.isLeaf = true;
              moneyNice.isLeaf = true;
            }
            datasnodes.push(father);
            that.mapThe.push(moneyNice);
          }
        }
        if (obj[i].functionname != '' && obj[i].functionname != null && obj[i].functionname != undefined && (obj[i].functionid == null || obj[i].functionid == undefined || obj[i].functionid == 0)) {
          that.list.push({
            key: obj[i].id,
            title: obj[i].functionname,
            disabled: false,
            direction: 'left'
          });
          that.list.push({
            key: obj[i].id,
            title: obj[i].functionname,
            disabled: false,
            direction: 'right'
          });
        }
      }

    }
    return datasnodes;
  }

  forArrayXzTreemou(obj: any, obj2: number) {
    let datasnodes = {mob: [], mos: []};
    let array = JSON.parse(JSON.stringify(obj));
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == obj2) {
          let luck = {mob: [], mos: []};
          let father: NzTreeNodeOptions = {
            title: obj[i].functionname,
            key: obj[i],
            children: [],
            icon: 'apartment',
            isLeaf: false
          };
          let moneyNice = {
            value: obj[i].id,
            label: obj[i].functionname,
            children: [],
            isLeaf: false
          };
          this.choseValue.push(obj[i].functionname);
          let thisid = obj[i].id;
          array.splice(i, 1);
          let das = this.forArrayXzTreemou(array, thisid);

          if (das.mob.length > 0) {
            father.children = das.mob;
            moneyNice.children = das.mos;
            father.icon = 'apartment';
          } else {
            father.isLeaf = true;
            moneyNice.isLeaf = true;
            father.icon = 'home';
          }
          datasnodes.mob.push(father);
          datasnodes.mos.push(moneyNice);
        }
      }
    }
    return datasnodes;
  }

  deleteData(id:number){
    this.equipmentService.deleteEquipment(id).subscribe(data=>{
      if (data.statusCode == 200) {
        this.dataRash(this.functionId);
        this.message.create('success', "删除成功");
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

}
