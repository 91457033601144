<nz-layout class="app-layout"></nz-layout>
<div nz-row>
  <div nz-col nzSpan="3" class="leftoption" #leftheight>

    <nz-input-group [nzSuffix]="suffixIcon">
      <input type="text" nz-input placeholder="查找名称" [(ngModel)]="searchValue"/>
    </nz-input-group>
    <ng-template #suffixIcon>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-tree
      [nzVirtualHeight]="leftoverheight"
      [nzData]="nodes"
      [nzSearchValue]="searchValue"
      (nzClick)="nzEvent($event)"
      (nzExpandChange)="nzEvent($event)"
      (nzSearchValueChange)="nzEvent($event)"
      [nzExpandAll]="false"
      nzShowIcon>
    </nz-tree>

  </div>
  <div nz-col nzSpan="1"></div>
  <div nz-col nzSpan="20" #body>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-collapse>
          <nz-collapse-panel [nzHeader]="['查询列表']" [nzActive]="true" [nzDisabled]="false">
            <form nz-form [formGroup]="validateForm" class="ant-advanced-search-form"  (ngSubmit)="submitForm()">
              <div nz-row [nzGutter]="24">

                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>出厂时间</nz-form-label>
                    <nz-form-control>
                      <nz-range-picker
                        [nzRanges]="ranges1"
                        nzShowTime
                        nzFormat="yyyy/MM/dd HH:mm:ss"
                        ngModel
                        (ngModelChange)="onChangeWeighTime($event)"
                        formControlName="Delivery"></nz-range-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>


                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>车牌号</nz-form-label>
                    <nz-form-control>
                      <input nz-input placeholder="车牌号" formControlName="licenseNumber"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>车轴数</nz-form-label>
                    <nz-form-control>
                      <nz-select nzPlaceHolder="请选择" [(ngModel)]="selectedValue" formControlName="shaftNumber">
                        <nz-option nzValue="two" nzLabel="2轴"></nz-option>
                        <nz-option nzValue="three" nzLabel="3轴"></nz-option>
                        <nz-option nzValue="four" nzLabel="4轴"></nz-option>
                        <nz-option nzValue="fiver" nzLabel="5轴"></nz-option>
                        <nz-option nzValue="six" nzLabel="6轴"></nz-option>
                        <nz-option nzValue="seven" nzLabel="7轴"></nz-option>
                        <nz-option nzValue="eight" nzLabel="8轴"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>是否 超重</nz-form-label>
                    <nz-form-control>
                      <nz-select nzPlaceHolder="请选择" [(ngModel)]="selectedValue" formControlName="isOverWeight">
                        <nz-option nzValue="all" nzLabel="全部"></nz-option>
                        <nz-option nzValue="overWeight" nzLabel="超重"></nz-option>
                        <nz-option nzValue="noWeight" nzLabel="未超重"></nz-option>
                      </nz-select>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>超 限 率(%)</nz-form-label>
                    <nz-form-control>

                     <nz-input-number formControlName="OverrunRateStart" [nzMin]="0" [nzMax]="100" [nzStep]="1" [nzFormatter]="formatterPercent"></nz-input-number> --
                      <nz-input-number formControlName="OverrunRateEnd" [nzMin]="0" [nzMax]="100" [nzStep]="1" [nzFormatter]="formatterPercent"></nz-input-number>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>超 重</nz-form-label>
                    <nz-form-control>

                      <nz-input-number formControlName="OverWeightStart" [nzMin]="0" [nzMax]="100" [nzStep]="1" [nzFormatter]="formatterPercent"></nz-input-number> --
                      <nz-input-number formControlName="OverWeightEnd" [nzMin]="0" [nzMax]="100" [nzStep]="1" [nzFormatter]="formatterPercent"></nz-input-number>

                    </nz-form-control>
                  </nz-form-item>
                </div>


                <div nz-col [nzSpan]="8">
                  <nz-form-item>
                    <nz-form-label>流水号</nz-form-label>
                    <nz-form-control>
                      <input nz-input placeholder="流水号" formControlName="serialNumber"/>
                    </nz-form-control>
                  </nz-form-item>
                </div>

              </div>
              <div nz-row>
                <div nz-col [nzSpan]="24" class="search-area">
                  <button nz-button [nzType]="'primary'">查询</button>
                  <button nz-button (click)="resetForm()">清除</button>
                </div>
              </div>
            </form>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>



    <!--    [nzPageSize]="pageSize"-->
    <div nz-row class="search-result-list" >
      <div nz-col nzSpan="24" class="span-table">
        <nz-table #basicTable
                  [nzShowPagination]="false"
                  [nzData]="list"
                  [nzLoading]="loading"
                  [nzScroll]="{ y: scrollY}"
                  class="span-table">
          <thead>
          <tr>
            <th>公司名称</th>
            <th>流水号</th>
            <th>出厂时间</th>
            <th>车牌号</th>
            <th>过磅时间</th>
            <th>车轴数</th>
            <th>标准</th>
            <th>实重</th>
            <th>超限率</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of basicTable.data"
              (click)="imageUrl(data.electronicsId,data.weightsId,data.serialNumber,data)">
            <td>{{ data.enterpriseName }}</td>
            <td>{{ data.serialNumber }}</td>
            <td>{{ timeChange(data.updatetime) }}</td>
            <td>{{ data.licenseNumber }}</td>
            <td>{{ timeChange(data.weightscreateTime) }}</td>
            <td>{{ NullOfZero(data.shaftNumber) }}</td>
            <td>{{ NullOfZero(data.vehicle) }}</td>
            <td>{{ NullOfZero(data.trueweight) }}</td>
            <td>{{ changeValue(data.trueweight, data.vehicle)}}%</td>
            <td>{{ vauleChange(data)}}</td>
            <td>
              <a (click)="imageUrl(data.electronicsId,data.weightsId,data.serialNumber,data)">详情</a>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <nz-pagination
                [nzPageIndex]="pageNumber"
                [nzTotal]="total"
                (nzPageIndexChange)="PageIndexChange($event)"
                (nzPageSizeChange)="PageSizeChange($event)"
                [nzPageSizeOptions]=[10,20,30,50,100]
                nzShowSizeChanger
                nzShowQuickJumper></nz-pagination>
            </td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </div>

  </div>
</div>


<nz-modal [(nzVisible)]="isVisible" nzTitle="信息窗口" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
          [nzWidth]="'70%'" style="z-index: 0;">
  <nz-descriptions nzTitle="检测数据" nzBordered [nzSize]="['default']">
    <nz-descriptions-item nzTitle="流水号">{{trValue.serialNumber}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="源头企业">{{trValue.enterpriseName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="站点">{{trValue.functionName}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="车牌号">
      {{trValue.licenseNumber}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="车轴数" [nzSpan]="2">
      {{trValue.shaftNumber}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="实重" [nzSpan]="3">
      <nz-badge nzStatus="processing" nzText="{{trValue.trueweight}}"></nz-badge>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="超限量">{{ChangeWeight(trValue.trueweight, trValue.vehicle)}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="标准">{{trValue.vehicle}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="检测时间">{{timeChange(trValue.weightscreateTime)}}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="出厂时间" [nzSpan]="3">
      {{timeChange(trValue.updatetime)}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="过磅文件" [nzSpan]="3">
      <div *ngFor="let data of listOfData">

        <img src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{data.fileUrl}}" *ngIf="data.fileType=='image'"
             [ngStyle]="{ width: '100px',height:'100px',float:'left',margin:'0px 0px 0px 30px'}"
             (click)="imageClick(data.id)">

        <video src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{data.fileUrl}}"
               [ngStyle]="{ width: '200px',height:'100px',float:'left',margin:'0px 0px 0px 30px' }"
               *ngIf="data.fileType=='video'"
               controls="controls">您的浏览器不支持 video 标签。
        </video>

      </div>
    </nz-descriptions-item>
  </nz-descriptions>
</nz-modal>


<div class="clearfix">
  <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
            (nzOnCancel)="previewVisible = false">

    <ng-template #modalContent>
      <button id="up-map-left" nz-button nzType="primary" nzShape="circle"
              style="left:-80%;top: 50%;position:absolute;z-index:9999;" (click)="clickLeft()"><i nz-icon
                                                                                                  nzType="left"></i>
      </button>
      <img [src]="previewImage" [ngStyle]="{ width: '100%' }" *ngIf="fileType=='image'"/>

      <video [src]="previewImage" *ngIf="fileType=='video'"
             [ngStyle]="{ width: '100%' }"
             controls="controls">您的浏览器不支持 video 标签。
      </video>

      <button id="up-map-right" nz-button nzType="primary" style="right:-80%;top: 50%;position:absolute;z-index:9999;"
              nzShape="circle" (click)="clickRight()"><i nz-icon nzType="right"></i></button>
    </ng-template>

  </nz-modal>
</div>


