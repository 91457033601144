import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class weightService {
  constructor(
    private apiService: ApiService
  ) { }

  findFunctioMapresult(pageSize:number,pageNum:number,fuctionId:number,useraccount:string) {
    return this.apiService.callApi('/WeightController/findRoleAndLevesAndFuctionAndWeightAll',
      {
        pageSize: pageSize,
        pageNum:pageNum,
        fuctionId:fuctionId,
        useraccount:useraccount,
        weight:null
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findFunctioMapresult2(pageSize:number
                        ,pageNum:number
                        ,fuctionId:number
                        ,useraccount:string
                        ,id:number,
                        serialNumber:string
                        ,carId:string
                        ,zfXs:number
                        ,fl:number
                        ,decWeight:number
                        ,grossWeight:number
                        ,actualTare:number
                        ,netWeight:number
                        ,trueWeight:number
                        ,unitPrice:number
                        ,weighingFee:number
                        ,mzWeighMan:string
                        ,pzWeighMan:string
                        ,theMoney:number
                        ,createTime:string
                        ,createTimeEnd:string
                        ,updateTime:string
                        ,updateTimeEnd:string
                        ,weighType:string
                        ,weighTime:string
                        ,weighTimeEnd:string
                        ,weightWoTime:string,
                        weightWoTimeEnd:string) {
    return this.apiService.callApi('/WeightController/findRoleAndLevesAndFuctionAndWeightAll',
      {
        pageSize: pageSize,
        pageNum:pageNum,
        fuctionId:fuctionId,
        useraccount:useraccount,
        id:id,
        serialNumber:serialNumber,
        carId:carId,
        zfXs:zfXs,
        fl:fl,
        decWeight:decWeight,
        grossWeight:grossWeight,
        actualTare:actualTare,
        netWeight:netWeight,
        trueWeight:trueWeight,
        unitPrice:unitPrice,
        weighingFee:weighingFee,
        mzWeighMan:mzWeighMan,
        pzWeighMan:pzWeighMan,
        theMoney:theMoney,
        createTime:createTime,
        createTimeEnd:createTimeEnd,
        updateTime:updateTime,
        updateTimeEnd:updateTimeEnd,
        weighType:weighType,
        weighTime:weighTime,
        weighTimeEnd:weighTimeEnd,
        weightWoTime:weightWoTime,
        weightWoTimeEnd:weightWoTime
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }



  findReport(starttime:string,endtime:string,fuctionId:number,type:number,pageSize:number,pageNum:number) {
    return this.apiService.callApi('/WeightController/findReport',
      {
        starttime: starttime,
        endtime: endtime,
        fuctionId: fuctionId,
        type:type,
        pageSize: pageSize,
        pageNum: pageNum
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findWeightBYRole(functionId:number){
    return this.apiService.callApi('/WeightController/findWeightBYRole',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findWeightRate(functionId:number){
    return this.apiService.callApi('/WeightController/findWeightRate',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findWeiGhtRateToday(functionId:number){
    return this.apiService.callApi('/WeightController/findWeiGhtRateToday',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }
  findWeightBYRoleIdAndToday(functionId:number){
    return this.apiService.callApi('/WeightController/findWeightBYRoleIdAndToday',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findImageBYRoleIdAndToday(functionId:number){
    return this.apiService.callApi('/WeightController/findImageBYRoleIdAndToday',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findWeather(functionId:number){
    return this.apiService.callApi('/WeightController/findWeather',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }


  findStatistical(functionId:number){
    return this.apiService.callApi('/WeightController/findStatistical',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }



  test(date:Date) {
    return this.apiService.callApi('/WeightController/timetest',
      {
        weightWoTime: date,
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

}
