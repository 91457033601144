import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {TokenService, usersService} from '../core/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Router} from '@angular/router';
import {UsersComponent} from '../users/users.component';

export interface AutocompleteOptionGroups {
  title: string;
  count?: number;
  children?: AutocompleteOptionGroups[];
}

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {
  isCollapsed = false;
  levId: number;
  users:any[];
  roles:any[];
  selectedValue = null;
  searchValue:any;
  @ViewChild('child1') child1:UsersComponent;
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private usersService: usersService,
    private message: NzMessageService,
  ) {}

  jump(id:number){
    this.router.navigate(['Management/use/'+id]);

  }

  ngOnInit(): void {
    let that = this;
    let accoun = that.tokenService.getAccoun();
    this.usersService.findAccount(accoun).subscribe(item => {
      if (item.statusCode == 200) {
        if (item.content != null) {
          that.levId = item.content.role.levelid;
        }
      } else {
        if (item.content.code == 40002) {
          that.message.create('error', item.content.subMsg);
        } else if (item.content.code == 20001) {
          that.message.create('error', item.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          that.message.create('error', item.content);
        }
      }
    });
    this.usersService.findUsers().subscribe(data=>{
      if (data.statusCode == 200) {
        if (data.content != null) {
          that.users = data.content.Users;
          that.roles=data.content.Role;
          that.searchValue = [...that.users,...that.roles]

        }
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          that.message.create('error', data.content);
        }
      }

    });
  }

  homeJump():void{
    this.router.navigate(["/menu"]);
  }


}
