import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized } from '@angular/router';
import {TokenService, usersService} from './core/services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
    private router: Router,
    private tokenService:TokenService,
    private usersService: usersService
  ){}

  title = 'trantionweb';
  ngOnInit(): void {



    let token=this.tokenService.getToken();
    let accoun=this.tokenService.getAccoun();
    if (token==null || token ==undefined || token==''){
      this.router.navigate(["/login"]);
    }else {
    this.usersService.checklogin(token,accoun).subscribe((data)=>{
      if (data !=null){
        if (data.statusCode==200){
          this.router.navigate(["/menu"]);
        }else {
          this.router.navigate(["/login"]);
        }
      }else {
        this.router.navigate(["/login"]);
      }
    });
      //usersService


    }


  }
}
