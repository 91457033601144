import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class fuctionService {
  constructor(
    private apiService: ApiService
  ) { }

  saveFunctio(xshaft:number,yshaft:number) {
    return this.apiService.callApi('/fuctionController/sacveFunction',
      {
        xshaft:xshaft,
        yshaft:yshaft
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }


  updateFunction(id:number,functionname:string,remark:string,isdel:boolean,isfunction:boolean){
    return this.apiService.callApi('/fuctionController/upSaveFunction',
      {
        id:id,
        functionname:functionname,
        remark:remark,
        isdel:isdel,
        isfunction:isfunction
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findFunctionUseGis() {
    return this.apiService.callApi('/fuctionController/findFunctionUseGis').pipe(
      map(data => {
          return data;
        }
      ));
  }




  findByIdFunction(id:number) {
    return this.apiService.callApi('/fuctionController/findByIdFunction',{
      id:id,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findByIdFunctionxy(id:number) {
    return this.apiService.callApi('/fuctionController/findByIdFunctionxy',{
      id:id,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }


  findChiDreNOrg(functionId:number){
    return this.apiService.callApi('/fuctionController/findChiDreNOrg',{
      functionId:functionId,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findChiDreName(functionId:number){
    return this.apiService.callApi('/fuctionController/findChiDreFunctionId',{
      functionId:functionId,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  updateFunctionObj(obj:string){
    return this.apiService.callApi('/fuctionController/updateFunction',{
      obj:obj,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findFunctionByuserId(userId:number){
    return this.apiService.callApi('/fuctionController/findFunctionByuserId',{
      userId:userId,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }
  deleteFunctionByUserId(userId:number,functionId:number){
    return this.apiService.callApi('/fuctionController/deleteFunctionByUserId',{
      userId:userId,
      functionId:functionId,
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  loginFunction(){
    return this.apiService.callApi('/fuctionController/loginFunction').pipe(
      map(data => {
          return data;
        }
      ));
  }

  addFunction(userId:number,functionId:number){
    return this.apiService.callApi('/fuctionController/addFunction',{
      userId:userId,
      functionId:functionId
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findFunctionFather(){
    return this.apiService.callApi('/fuctionController/findFunctionByRoleIdAndFather').pipe(
      map(data => {
          return data;
        }
      ));
  }


}

