import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { map, distinctUntilChanged } from 'rxjs/operators';
import {TokenService} from './token.service';


@Injectable()
export class ApiService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private tokenService: TokenService,
  ) {}

  public  token: string;

  private formatErrors(error: any) {
    console.log(error);
    alert('网络错误');
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api_url}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body:any = {}): Observable<any> {
    let params = new HttpParams({
      fromObject: body
    });
    return this.http.put(
      `${environment.api_url}${path}`,
      params
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body:any = {}): Observable<any> {
    const heads=new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;',
    })

    const  params = new HttpParams({
      fromObject: body
    });


    return this.http.post(
      `${environment.api_url}${path}`,
      params,
      {headers:heads}
    ).pipe(catchError(this.formatErrors));
  }

  callApi(path: string, body:any = {}):Observable<any>{

    return this.post(path, body)
      .pipe(map(
          data => {
             if(data.statusCode == 400 || (data.statusCode == 500 && data.content.subCode === "invalid-auth-token")){
                this.router.navigate(["login"]);
             }
            return data;
        }
      ));

  }


  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`
    ).pipe(catchError(this.formatErrors));
  }
}
