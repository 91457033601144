import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class videosService {
  constructor(
    private apiService: ApiService
  ) { }
  findvodeos(weight:number) {
    return  this.apiService.callApi('/videosController/findvodeos',
      {
        weight:weight
      })
      .pipe(
      map(data => {
          return data;
        }
      ));
  }

}
