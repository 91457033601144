import {Component, OnInit} from '@angular/core';
import {
  MapOptions, Point, MarkerOptions, NavigationControlOptions, ControlAnchor,
  NavigationControlType, OverviewMapControlOptions, ScaleControlOptions, MapTypeControlOptions, MapTypeControlType,
  GeolocationControlOptions,BInfoWindow,BMarker,BMapInstance,Animation,
} from 'angular2-baidu-map';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})


export class TestComponent implements OnInit {



  constructor() {


  }

  ngOnInit(): void {
    var box = document.getElementsByClassName("face");
    //box[0].classList.add("flip-horizontal-bottom");
    //box[1].classList.add("flip-horizontal-bottom");
}
cliclic(){

  //box[1].classList.remove("flip-horizontal-bottom");
}

}
