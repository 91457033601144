import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class electronicsService {
  constructor(
    private apiService: ApiService
  ) { }



  // findAllPage(pageSize:number,pageNum:number,functionId:number,electronics:any,weight:any){
  //   return this.apiService.callApi('/electronicsController/findAllPage',{
  //     pageSize:pageSize,
  //     pageNum:pageNum,
  //     functionId:functionId,
  //     electronics:electronics,
  //     weight:weight
  //   }).pipe(
  //     map(data => {
  //         return data;
  //       }
  //     ));
  // }


  findAllPage(data:any){
    return this.apiService.callApi('/electronicsController/findAllPage',data).pipe(
      map(data => {
          return data;
        }
      ));
  }

}
