<!-- NG-ZORRO -->
<!--<a href="https://github.com/NG-ZORRO/ng-zorro-antd" target="_blank" style="display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;">
  <img height="300" src="https://img.alicdn.com/tfs/TB1X.qJJgHqK1RjSZFgXXa7JXXa-89-131.svg">
</a>-->
<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>治超管理系统</title>
  <base href="/">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <link rel="icon" type="image/x-icon" href="favicon.ico">

  <script type="text/javascript"  src="http://api.map.baidu.com/api?v=3.0&ak=fqvhR4bS8I5Cg12ln8HjGQyRvGgR5kjp"></script>
  <script type="text/javascript" src="http://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js"></script>
  <link rel="stylesheet" href="http://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css" />
</head>
<body>
<router-outlet></router-outlet>
</body>
</html>
