import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {companyService, CustomerReuseStrategy, TokenService, usersService} from '../core';
import {users} from '../core/models/users.member.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private usersService: usersService,
    private tokenService: TokenService,
    private companySerive: companyService,
  ) {
  }

  public validateForm!: FormGroup;
  msgs: Array<Object> = [];
  visible = false;
  childrenVisible = false;
  public code = 0;
  public users: users;
  selectedValue = null;
  companyData: any[];
  isVisible = false;
  isOkLoading = false;
  prohibit = false;
  public result = false;

  handleOk(): void {
    this.isOkLoading = true;

    setTimeout(() => {
      this.isVisible = false;
      this.isOkLoading = false;
    }, 3000);

  }

  handleCancel(): void {
    this.isVisible = false;
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      passwordRegistered: [null, [Validators.required, this.passwordRegisteredChangge]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      nickname: [null, [Validators.required]],
      phoneNumberPrefix: ['+86'],
      phoneNumber: [null, [Validators.required]],
      captcha: [null, [Validators.required]],
      idCard: [null, [Validators.required, this.idCardRegistered]],
      agree: [false],
      company: [null, [Validators.required, this.checkcompany]],
      registrationcode: [null, [Validators.required, this.checkCode]]
    });
    this.addform();

    this.companySerive.findAll().subscribe(data => {
      if (data.statusCode == 200) {
        this.companyData = data.content;
      } else {
        if (data.content.code == 40002) {
          this.showToast('danger', data.content.subMsg);
        } else {
          this.showToast('danger', data.content);
        }
      }
    });

  }

  RetrievePassword(): void {
    this.isVisible = true;
  }


  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
    let that = this;
    let iphone = this.validateForm.controls.phoneNumber.value;
    if (iphone != undefined && iphone != null && iphone != '') {

      this.usersService.sendLogin(iphone).subscribe(data => {
        if (data.statusCode == 200) {
          that.code = data.content;
          this.prohibit = true;
          setTimeout(() => {
            this.prohibit = false;
          }, 60000);

        } else {
          if (data.content.code == 40002) {
            this.showToast('danger', data.content.subMsg);
          } else {
            this.showToast('danger', data.content);
          }
        }
      });

    }
  }


  sendOut(e: MouseEvent) {
    e.preventDefault();
    let that = this;
    let userName = this.validateForm.controls.userName.value;
    let status = 0;
    if (userName != undefined && userName != null && userName != '') {

      if (this.isPoneAvailable(userName)) {
        //判断是否为手机号
        status = 2;
      } else if (this.isEmail(userName)) {
        //判断是否为邮箱
        status = 3;
      } else if (this.CardID(userName)) {
        //判断是否为身份证
        status = 1;
        this.showToast('warn', '请输入手机哈邮箱');
        return false;
      } else if (this.special(userName)) {
        //判断是否为系统账号
        status = 0;
        this.showToast('warn', '请输入手机或邮箱');
        return false;
      } else {
        this.showToast('warn', '请输入正确的账号');
        return false;
      }

      this.usersService.RetrieveUsers(userName, Number(status)).subscribe(data => {
        if (data.statusCode == 200) {
          that.code = data.content;
          this.prohibit = true;
          setTimeout(() => {
            this.prohibit = false;
          }, 60000);

        } else {
          if (data.content.code == 40002) {
            this.showToast('danger', data.content.subMsg);
          } else {
            this.showToast('danger', data.content);
          }
        }
      });


    }
  }


  passwordRegisteredChangge = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value.length < 6) {
      return {confirm: true, error: true};
    }
    return {};
  };

  checkcompany = (control: FormControl): { [s: string]: boolean } => {
    if (control.value == null || control.value == undefined || control.value == '') {
      return {confirm: true, error: true};
    }
    return {};
  };

  checkCode = (control: FormControl): { [s: string]: boolean } => {
    if (control.value == null || control.value == undefined || control.value == '') {
      return {confirm: true, error: true};
    }
    return {};
  };

  idCardRegistered = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (!this.CardID(control.value)) {
      return {confirm: true, error: true};
    }
    return {};
  };
  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.validateForm.controls.passwordRegistered.value) {
      return {confirm: true, error: true};
    }
    return {};
  };

  //找回
  submitRetrieve() {
    //账号
    let userName = this.validateForm.controls.userName.value;
    let code = this.validateForm.controls.captcha.value;
    let status = 0;
    if (userName != null && userName != undefined && userName != '') {
      if (this.isPoneAvailable(userName)) {
        //判断是否为手机号
        status = 2;
        // this.showToast('success',"当前登录方式为手机号");
      } else if (this.isEmail(userName)) {
        //判断是否为邮箱
        status = 3;
        // this.showToast('success',"当前登录方式为邮箱");
      } else if (this.CardID(userName)) {
        //判断是否为身份证
        status = 1;
        this.showToast('warn', '请输入手机哈邮箱');
        return false;
      } else if (this.special(userName)) {
        //判断是否为系统账号
        status = 0;
        this.showToast('warn', '请输入手机哈邮箱');
        return false;
      } else {
        this.showToast('warn', '请输入正确的账号');
        return false;
      }
    } else {
      this.showToast('warn', '请输入账号');
      return false;
    }

    this.usersService.checkCodeValue(userName, code).subscribe(data => {
      if (data.statusCode == 200) {
        this.result = data.content;
        if (data.content == false) {
          this.showToast('danger', '验证码错误! ! !');
        }
      } else {
        if (data.content.code == 40002) {
          this.showToast('danger', data.content.subMsg);
        } else {
          if (data.content == false) {
            this.showToast('danger',"验证码过期 ! ! !");
          } else {
            this.showToast('danger', data.content);
          }
        }
      }
    });

  }


  updatePassword() {
    let userName = this.validateForm.controls.userName.value;
    let password = this.validateForm.controls.passwordRegistered.value;
    let status = 0;
    if (userName != undefined && userName != null && userName != '') {
      if (this.isPoneAvailable(userName)) {
        //判断是否为手机号
        status = 2;
      } else if (this.isEmail(userName)) {
        //判断是否为邮箱
        status = 3;
      } else if (this.CardID(userName)) {
        //判断是否为身份证
        status = 1;
        this.showToast('warn', '请输入手机哈邮箱');
        return false;
      } else if (this.special(userName)) {
        //判断是否为系统账号
        status = 0;
        this.showToast('warn', '请输入手机或邮箱');
        return false;
      } else {
        this.showToast('warn', '请输入正确的账号');
        return false;
      }

      this.usersService.upUserPassword(userName,password,status).subscribe(data=>{
        if (data.statusCode == 200) {
          this.result = data.content;
          if (data.content == true) {
            this.showToast('success', '密码重置成功');
            this.isVisible=false;
          }
        } else {
          if (data.content.code == 40002) {
            this.showToast('danger', data.content.subMsg);
          } else {
            if (data.content == false) {
              this.showToast('danger',"重置是吧");
            } else {
              this.showToast('danger', data.content);
            }
          }
        }
      });
    }
  }

  //注册
  submitFormRegistered() {
    let email = this.validateForm.controls.email.value;
    let password = this.validateForm.controls.passwordRegistered.value;
    let checkPassword = this.validateForm.controls.checkPassword.value;
    let username = this.validateForm.controls.nickname.value;
    let phone = this.validateForm.controls.phoneNumber.value;
    let agree = this.validateForm.controls.agree.value;
    let idCard = this.validateForm.controls.idCard.value;
    let captcha = this.validateForm.controls.captcha.value;
    let company = this.validateForm.controls.company.value;
    let registrationcode = this.validateForm.controls.registrationcode.value.replace(/\s/g, '');
    if (checkPassword != password) {
      this.showToast('danger', '2次输入密码不一致');
      return false;
    }
    if (agree == false) {
      this.showToast('danger', '请同意协议');
      return false;
    }

    if (company == null || company == undefined) {
      this.showToast('danger', '请选择您的公司');
      return false;
    }
    if (registrationcode == null || registrationcode == undefined) {
      this.showToast('danger', '请填写激活码');
      return false;
    }

    this.usersService.SaveRegister(username, password, idCard, phone, email, captcha, company, registrationcode).subscribe(item => {
      if (item.statusCode == 200) {
        this.showToast('success', item.content.account + ':' + '注册成功');
      } else {
        if (item.content.code == 40002) {
          this.showToast('danger', item.content.subMsg);
        } else {
          this.showToast('danger', item.content);
        }
      }
    });

  }


  submitForm() {
    let userName = this.validateForm.controls.userName.value;
    let password = this.validateForm.controls.password.value;
    let status = 0;
    if (userName != null && userName != undefined && userName != '') {

      if (this.isPoneAvailable(userName)) {
        //判断是否为手机号
        status = 2;
        // this.showToast('success',"当前登录方式为手机号");
      } else if (this.isEmail(userName)) {
        //判断是否为邮箱
        status = 3;
        // this.showToast('success',"当前登录方式为邮箱");
      } else if (this.CardID(userName)) {
        //判断是否为身份证
        status = 1;
        //  this.showToast('success',"当前登录方式为身份证");
      } else if (this.special(userName)) {
        //判断是否为系统账号
        status = 0;
        //  this.showToast('success',"当前登录方式为系统账号");
      } else {
        this.showToast('warn', '请输入正确的账号');
        return false;
      }
    } else {
      this.showToast('warn', '请输入账号');
      return false;
    }
    if (userName == null || userName == undefined || userName == '') {
      this.showToast('warn', '请输入密码');
      return false;
    }
    //登录
    this.usersService.loginUsers(userName, password, status).subscribe((data) => {
      if (data != null) {
        if (data.statusCode == 200) {
          this.tokenService.saveToken(data.content.token);
          this.tokenService.saveAccount(data.content.useraccount);
         let accoun=this.tokenService.getAccoun();
          CustomerReuseStrategy.handlers = new Map();
          this.router.navigate(['/menu']);
        }else {
          if (data.content.code == 40002) {
            this.showToast('danger', data.content.subMsg);
          } else {
            this.showToast('danger', data.content);
          }
        }
      } else {
        if (data.content.code == 40002) {
          this.showToast('danger', data.content.subMsg);
        } else {
          this.showToast('danger', data.content);
        }
      }
    });

    return false;
  }


  /**
   * 手机号校验
   * @param poneInput
   */
  isPoneAvailable(poneInput: any) {
    let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!myreg.test(poneInput)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * 是否为邮箱
   * @param str
   */
  isEmail(str: any) {
    let re = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (re.test(str) != true) {
      return false;
    } else {
      return true;
    }
  }


  showToast(type: any, message: any) {
    switch (type) {
      case 'success':
        this.msgs = [{severity: type, summary: 'ATW提示', detail: message, life: 100000}];
        break;
      case 'link':
        this.msgs = [{severity: 'info', summary: '相对地址', detail: `<a class="devui-link" href="/home" target="_blank">返回首页</a>`}];
        break;
      case 'multiple':
        this.msgs = [{severity: 'info', summary: 'ATW提示', detail: message}];
        break;
      case 'noTitle':
        this.msgs = [{severity: 'warn', summary: 'ATW提示', detail: message}];
        break;
      default:
        this.msgs = [{severity: type, summary: 'ATW提示', detail: message}];
    }
  }


  activeTabChange(e) {
    // console.log(e);
  }

  //添加form列
  addform(): void {
    this.validateForm.addControl('userName', new FormControl());
    this.validateForm.addControl('password', new FormControl());
  }


  /**
   * 身份证算法
   * @param card
   * @constructor
   */
  CardID(card: any) {
    let vcity = {
      11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古',
      21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏',
      33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南',
      42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆',
      51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃',
      63: '青海', 64: '宁夏', 65: '新疆', 71: '台湾', 81: '香港', 82: '澳门', 91: '国外'
    };
//检查号码是否符合规范，包括长度，类型
    let isCardNo = function(card) {
      //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      let reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
      if (reg.test(card) === false) {
        return false;
      }
      return true;
    };
//取身份证前两位,校验省份
    let checkProvince = function(card) {
      let province = card.substr(0, 2);
      if (vcity[province] == undefined) {
        return false;
      }
      return true;
    };
//检查生日是否正确
    let checkBirthday = function(card) {
      let len = card.length;
      //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
      if (len == '15') {
        let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
        let arr_data = card.match(re_fifteen);
        let year = arr_data[2];
        let month = arr_data[3];
        let day = arr_data[4];
        let birthday = new Date('19' + year + '/' + month + '/' + day);
        return verifyBirthday('19' + year, month, day, birthday);
      }
      //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
      if (len == '18') {
        let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
        let arr_data = card.match(re_eighteen);
        let year = arr_data[2];
        let month = arr_data[3];
        let day = arr_data[4];
        let birthday = new Date(year + '/' + month + '/' + day);
        return verifyBirthday(year, month, day, birthday);
      }
      return false;
    };
//校验日期
    let verifyBirthday = function(year, month, day, birthday) {
      let now = new Date();
      let now_year = now.getFullYear();
      //年月日是否合理
      if (birthday.getFullYear() == year && (birthday.getMonth() + 1) == month && birthday.getDate() == day) {
        //判断年份的范围（3岁到100岁之间)
        let time = now_year - year;
        if (time >= 3 && time <= 100) {
          return true;
        }
        return false;
      }
      return false;
    };
//校验位的检测
    let checkParity = function(card) {
      //15位转18位
      card = changeFivteenToEighteen(card);
      let len = card.length;
      if (len == '18') {
        let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
        let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
        let cardTemp = 0, i, valnum;
        for (i = 0; i < 17; i++) {
          cardTemp += card.substr(i, 1) * arrInt[i];
        }
        valnum = arrCh[cardTemp % 11];
        if (valnum == card.substr(17, 1)) {
          return true;
        }
        return false;
      }
      return false;
    };
//15位转18位身份证号
    let changeFivteenToEighteen = function(card) {
      if (card.length == '15') {
        let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
        let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
        let cardTemp = 0, i;
        card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
        for (i = 0; i < 17; i++) {
          cardTemp += card.substr(i, 1) * arrInt[i];
        }
        card += arrCh[cardTemp % 11];
        return card;
      }
      return card;
    };

    //是否为空
    if (card === '') {
      // mui.alert('请输入身份证号，身份证号不能为空');
      return false;
    }
    //校验长度，类型
    if (isCardNo(card) === false) {
      //  mui.alert('您输入的身份证号码不正确，请重新输入');
      return false;
    }
    //检查省份
    if (checkProvince(card) === false) {
      //  mui.alert('您输入的身份证号码不正确,请重新输入');
      return false;
    }
    //校验生日
    if (checkBirthday(card) === false) {
      //  mui.alert('您输入的身份证号码生日不正确,请重新输入');
      return false;
    }
    //检验位的检测
    if (checkParity(card) === false) {
      //  mui.alert('您的身份证校验位不正确,请重新输入');
      return false;
    }
//    console.info("OK");
    return true;
  };

  special(value) {
    let pattern = /@/;
    if (value === '' || value === null) {
      return false;
    }
    if (pattern.test(value)) {
      return false;
    } else if (value.length > 10) {
      return false;
    }
    return true;
  }
}
