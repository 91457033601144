import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {electronicsService, fileOssService, TokenService, usersService} from '../core/services';
import {NzFormatEmitEvent, NzTreeNodeOptions, NzUploadFile} from 'ng-zorro-antd';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Router} from '@angular/router';
import {util} from '../Util';
import endOfMonth from 'date-fns/endOfMonth';


@Component({
  selector: 'app-electronics',
  templateUrl: './electronics.component.html',
  styleUrls: ['./electronics.component.css']
})
export class ElectronicsComponent implements OnInit {
  public validateForm!: FormGroup;
  public controlArray: Array<{ index: number; show: boolean }> = [];
  public isCollapse = true;
  public searchValue = '';
  public nodes: NzTreeNodeOptions[] = [];
  @ViewChild('leftheight') leftheight: ElementRef;
  public leftoverheight = '10000px';
  public list: any[] = [];
  public listOfData: any[] = [];
  public trValue: any = null;
  public isVisible = false;
  public map: any = null;
  public file: NzUploadFile;
  public previewImage = '';
  public imageId = null;
  public fileType = 'image';
  public previewVisible = false;
  public functionId = 0;
  public total = 10;
  public loading = true;
  public pageSize = 10;
  public pageIndex = 1;
  public pageNumber = 1;
  public ranges1 = {Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())]};
  public ranges2 = {Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())]};
  public selectedValue = null;
  public formatterPercent = (value: number) => `${value} %`;
  public electronics: any = new Object();
  public weight: any = new Object();
  public javaData: any = new Object();
  @ViewChild('body') elementView: ElementRef;
  public scrollY = '0px';

  constructor(
    private fb: FormBuilder,
    private electronicsService: electronicsService,
    private tokenService: TokenService,
    private usersService: usersService,
    private message: NzMessageService,
    private router: Router,
    private util: util,
    private fileOssService: fileOssService
  ) {
  }


  imageClick(id: string): void {
    let electronics: any = this.listOfData.filter(item => {
      if (item.id == id) {
        return item;
      }
    });
    if (electronics.length > 0) {
      this.imageId = electronics[0].id;
      this.fileType = electronics[0].fileType;
      this.previewImage = 'https://jcup-images-01.oss-cn-shanghai.aliyuncs.com' + electronics[0].fileUrl;
    } else {
      this.imageId = null;
      this.fileType = null;
      this.previewImage = null;
    }

    this.previewVisible = true;
  }

  javaDataReash(): void {
    this.loading = true;
    this.javaData['pageSize'] = this.pageSize;
    this.javaData['pageNum'] = this.pageNumber;
    this.javaData['functionId'] = this.functionId;

    this.javaData['functionId'] = this.functionId;
    this.javaData['functionId'] = this.functionId;

    let Delivery = null;
    let licenseNumber = null;
    let shaftNumber = null;
    let isOverWeight = null;
    let OverrunRateStart = null;
    let OverrunRateEnd = null;
    let OverWeightStart = null;
    let OverWeightEnd = null;
    let serialNumber = null;
    if (!this.util.isNull(this.validateForm)) {
      Delivery = this.validateForm.controls.Delivery.value;
      licenseNumber = this.validateForm.controls.licenseNumber.value;
      shaftNumber = this.validateForm.controls.shaftNumber.value;
      isOverWeight = this.validateForm.controls.isOverWeight.value;
      OverrunRateStart = this.validateForm.controls.OverrunRateStart.value;
      OverrunRateEnd = this.validateForm.controls.OverrunRateEnd.value;
      OverWeightStart = this.validateForm.controls.OverWeightStart.value;
      OverWeightEnd = this.validateForm.controls.OverWeightEnd.value;
      serialNumber = this.validateForm.controls.serialNumber.value;
    }


    if (!this.util.isNull(Delivery)) {
      this.electronics['updateTime'] = this.util.formatTime(new Date(Delivery[0]));
      this.electronics['endTime'] = this.util.formatTime(new Date(Delivery[1]));
      this.javaData['updateTime'] = this.util.formatTime(new Date(Delivery[0]));
      this.javaData['endTime'] = this.util.formatTime(new Date(Delivery[1]));
    }

    if (!this.util.isNull(licenseNumber)) {
      this.electronics['licenseNumber'] = licenseNumber.replace(/\s+/g, '');
      this.javaData['licenseNumber'] = licenseNumber.replace(/\s+/g, '');
      ;
    }

    if (!this.util.isNull(shaftNumber)) {
      this.electronics['shaftNumber'] = shaftNumber;
      this.javaData['shaftNumber'] = shaftNumber;
    }

    if (!this.util.isNull(isOverWeight)) {
      this.weight['weightStatus'] = isOverWeight;
      this.javaData['weightStatus'] = isOverWeight;
    }
    if (!this.util.isNull(OverrunRateStart)) {
      this.weight['OverrunRateStart'] = OverrunRateStart;
      this.javaData['OverrunRateStart'] = OverrunRateStart;
    } else if (OverrunRateStart == 0) {
      this.weight['OverrunRateStart'] = OverrunRateStart;
      this.javaData['OverrunRateStart'] = OverrunRateStart;
    }
    if (!this.util.isNull(OverrunRateEnd)) {
      this.weight['OverrunRateEnd'] = OverrunRateEnd;
      this.javaData['OverrunRateEnd'] = OverrunRateEnd;
    }
    if (!this.util.isNull(OverWeightStart)) {
      this.weight['OverWeightStart'] = OverWeightStart;
      this.javaData['OverWeightStart'] = OverWeightStart;
    } else if (OverWeightStart == 0) {
      this.weight['OverWeightStart'] = OverWeightStart;
      this.javaData['OverWeightStart'] = OverWeightStart;
    }
    if (!this.util.isNull(OverWeightEnd)) {
      this.weight['OverWeightEnd'] = OverWeightEnd;
      this.javaData['OverWeightEnd'] = OverWeightEnd;
    }

    if (!this.util.isNull(OverWeightEnd)) {
      this.weight['OverWeightEnd'] = OverWeightEnd;
      this.javaData['OverWeightEnd'] = OverWeightEnd;
    }

    if (!this.util.isNull(serialNumber)) {
      this.weight['serialNumber'] = serialNumber.replace(/\s+/g, '');
      ;
      this.javaData['serialNumber'] = serialNumber.replace(/\s+/g, '');
      ;
    }


  }

  ngOnInit(): void {
    this.trValue = {
      serialNumber: '',
      enterpriseName: '',
      functionName: '',
      licenseNumber: '',
      shaftNumber: '',
      trueweight: 0,
      vehicle: 0,
      createTime: ''
    };
    let accoun = this.tokenService.getAccoun();
    this.validateForm = this.fb.group({});
    this.addForm();
    this.validateForm.controls.OverrunRateStart.setValue(0);
    this.validateForm.controls.OverrunRateEnd.setValue(0);
    this.validateForm.controls.OverWeightStart.setValue(0);
    this.validateForm.controls.OverWeightEnd.setValue(0);

    this.usersService.findFunction(accoun).subscribe((data) => {
      //成功
      if (data.statusCode == 200) {
        this.nodes = this.forArrayXzTree(data.content);
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(['/login']);
        } else {
          this.message.create('error', data.content);
        }
      }
    });

    this.javaDataReash();
    let data = this.javaData;


    this.electronicsService.findAllPage(data).subscribe(data => {
      if (data.statusCode == 200) {
        this.list = data.content.list;
        this.total = data.content.total;
        this.loading = false;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(['/login']);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

  onChangeWeighTime(result: Date[]): void {
    //console.log('时间值改变:', result);
  }

  addForm() {
    this.validateForm.addControl(`Delivery`, new FormControl());
    this.validateForm.addControl(`licenseNumber`, new FormControl());
    this.validateForm.addControl(`shaftNumber`, new FormControl());
    this.validateForm.addControl(`axlesNumber`, new FormControl());
    this.validateForm.addControl(`isOverWeight`, new FormControl());
    this.validateForm.addControl(`OverrunRateStart`, new FormControl());
    this.validateForm.addControl(`OverrunRateEnd`, new FormControl());
    this.validateForm.addControl(`OverWeightStart`, new FormControl());
    this.validateForm.addControl(`OverWeightEnd`, new FormControl());
    this.validateForm.addControl(`serialNumber`, new FormControl());
  }

  submitForm() {
    let Delivery = this.validateForm.controls.Delivery.value;
    let licenseNumber = this.validateForm.controls.licenseNumber.value;
    let shaftNumber = this.validateForm.controls.shaftNumber.value;
    let isOverWeight = this.validateForm.controls.isOverWeight.value;
    let OverrunRateStart = this.validateForm.controls.OverrunRateStart.value;
    let OverrunRateEnd = this.validateForm.controls.OverrunRateEnd.value;
    let OverWeightStart = this.validateForm.controls.OverWeightStart.value;
    let OverWeightEnd = this.validateForm.controls.OverWeightEnd.value;


    if (!this.util.isNull(Delivery)) {
      this.electronics['updateTime'] = this.util.formatTime(new Date(Delivery[0]));
      this.electronics['endTime'] = this.util.formatTime(new Date(Delivery[1]));
    }

    if (!this.util.isNull(licenseNumber)) {
      this.electronics['licenseNumber'] = licenseNumber;
    }

    if (!this.util.isNull(shaftNumber)) {
      this.electronics['shaftNumber'] = shaftNumber;
    }

    if (!this.util.isNull(isOverWeight)) {
      this.weight['weightStatus'] = isOverWeight;
    }
    if (!this.util.isNull(OverrunRateStart)) {
      this.weight['OverrunRateStart'] = OverrunRateStart;
    } else if (OverrunRateStart == 0) {
      this.weight['OverrunRateStart'] = OverrunRateStart;
    }
    if (!this.util.isNull(OverrunRateEnd)) {
      this.weight['OverrunRateEnd'] = OverrunRateEnd;
    }
    if (!this.util.isNull(OverWeightStart)) {
      this.weight['OverWeightStart'] = OverWeightStart;
    } else if (OverWeightStart == 0) {
      this.weight['OverWeightStart'] = OverWeightStart;
    }
    if (!this.util.isNull(OverWeightEnd)) {
      this.weight['OverWeightEnd'] = OverWeightEnd;
    }

    this.javaDataReash();
    let data = this.javaData;
    this.electronicsService.findAllPage(data).subscribe(data => {
      if (data.statusCode == 200) {
        this.list = data.content.list;
        this.total = data.content.total;
        this.loading = false;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(['/login']);
        } else {
          this.message.create('error', data.content);
        }
      }
    });


  }

  PageSizeChange(data: any) {

    this.pageSize = data;
    this.javaDataReash();

    this.electronicsService.findAllPage(this.javaData).subscribe(data => {
      if (data.statusCode == 200) {
        this.list = data.content.list;
        this.total = data.content.total;
        this.loading = false;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(['/login']);
        } else {
          this.message.create('error', data.content);
        }
      }
    });

  }

  PageIndexChange(data: any) {

    this.loading = true;
    this.pageNumber = data;
    this.javaDataReash();

    this.electronicsService.findAllPage(this.javaData).subscribe(data => {
      if (data.statusCode == 200) {
        this.list = data.content.list;
        this.total = data.content.total;
        this.loading = false;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(['/login']);
        } else {
          this.message.create('error', data.content);
        }
      }
    });

  }


  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    //console.log('Button ok clicked!');
    this.isVisible = false;
  }

  handleCancel(): void {
    //console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  vauleChange(data: any) {

    if (data.weightsId > 0) {
      if (data.trueweight > data.vehicle) {
        return '异常';
      } else {
        return '已过磅';
      }
    } else {
      return '未过磅';
    }


    // if (value == true) {
    //   return '已过磅';
    // } else {
    //   return '未过磅';
    // }
  }


  clickLeft() {
    let id = this.imageId;
    let value = this.listOfData;
    let nextLeftValue: any;
    for (let i = 0; value.length > i; i++) {
      let data = value[i];
      if (data.id == id) {
        if (i == 0) {
          nextLeftValue = value[value.length - 1];
          break;
        } else {
          nextLeftValue = value[i - 1];
          break;
        }
      }
    }
    this.previewImage = 'https://jcup-images-01.oss-cn-shanghai.aliyuncs.com' + nextLeftValue.fileUrl;
    this.imageId = nextLeftValue.id;
    this.fileType = nextLeftValue.fileType;
  }

  clickRight() {
    let id = this.imageId;
    let value = this.listOfData;
    let nextLeftValue: any;
    for (let i = 0; value.length > i; i++) {
      let data = value[i];
      if (i + 1 >= value.length) {
        nextLeftValue = value[0];
        break;
      }
      if (data.id == id) {
        nextLeftValue = value[i + 1];
        break;
      }
    }
    this.previewImage = 'https://jcup-images-01.oss-cn-shanghai.aliyuncs.com' + nextLeftValue.fileUrl;
    this.imageId = nextLeftValue.id;
    this.fileType = nextLeftValue.fileType;
  }


  imageUrl(electronicsId: number, weightsId: number, relation: string, data: any) {

    this.trValue = data;
    if (electronicsId != null) {
      this.fileOssService.findFileOssByRelation(relation, electronicsId).subscribe(data => {
        if (data.statusCode == 200) {
          let list = data.content;
          if (list != null) {
            this.listOfData = list;
          } else {
            this.listOfData = [];
          }
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(['/login']);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
      if (weightsId != null) {
        this.fileOssService.findWeightByIdImageORVideo(weightsId).subscribe(data => {
          if (data.statusCode == 200) {
            this.map = data.content;
            if (this.map.Images.length > 0) {
              let Images = this.map.Images;
              Images.forEach(data => {
                this.listOfData.push({
                  fileUrl: data.imageurl,
                  fileType: 'image',
                  id: this.getUuid()
                });
              });
            }
            if (this.map.Videos.length > 0) {
              let Videos = this.map.Videos;
              Videos.forEach(data => {
                this.listOfData.push({
                  fileUrl: data.videoUrl,
                  fileType: 'video',
                  id: this.getUuid()
                });
              });
            }

          } else {
            if (data.content.code == 40002) {
              this.message.create('error', data.content.subMsg);
            } else if (data.content.code == 20001) {
              this.message.create('error', data.content.subMsg);
              this.router.navigate(['/login']);
            } else {
              this.message.create('error', data.content);
            }
          }

        });
      }
    } else {
      this.listOfData = [];
      this.map = null;
    }
    this.showModal();

  }

  NullOfZero(value: number) {
    if (value == null) {
      return 0;
    } else {
      return value;
    }
  }


  changeValue(trueweight: number, vehicle: number) {
    if (trueweight != null) {
      if (vehicle != null) {
        let abc = trueweight / vehicle;
        return abc;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }


  getUuid() {
    let s = [];
    let hexDigits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    let uuid = s.join('');
    return uuid;
  }


  ChangeWeight(trueweight: number, vehicle: number) {
    if (trueweight != null) {
      if (vehicle != null) {
        let abc = trueweight - vehicle;
        return abc;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }


  timeChange(value: string) {
    return this.util.formatTime(new Date(value));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let isnull = this.leftheight.nativeElement.offsetParent;
      if (isnull != null && isnull != undefined && isnull != 0) {
        let height = this.leftheight.nativeElement.offsetParent.clientHeight;
        let worm = height / 100;
        let x = Math.round(worm * 93);
        this.leftoverheight = x + 'px';

        let height2 = this.elementView.nativeElement.offsetParent.clientHeight;
        //  let head = this.elementhead.nativeElement.clientHeight
        let dij = height2 / 10;
        let hedas = height2 - (dij * 6);
        //400
        this.scrollY = hedas + 'px';
        //this.scrollY ='400px';
      } else {
        this.scrollY = '400px';
      }
    });
  }


  /**
   * 点击区域文字
   * @param event
   */
  nzEvent(event: NzFormatEmitEvent): void {
    let id = event.node.key['id'];
    let children = event.node.children;
    let list = null;
    this.pageNumber = 1;
    this.pageSize = 10;
    if (children.length > 0) {
      list = children.filter(data => {
        //最终功能
        if (data.key['isfunction'] == false) {
          return data;
        }
      });
    } else {
      if (event.node.key['isfunction'] == false) {
        list = [];
        list.push(event.node.key);
      }

    }
    if (list != null && list.length > 0) {
      this.functionId = id;
      this.javaDataReash();
      this.electronicsService.findAllPage(this.javaData).subscribe(data => {
        if (data.statusCode == 200) {
          this.list = data.content.list;
          this.total = data.content.total;
          this.loading = false;
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(['/login']);
          } else {
            this.message.create('error', data.content);
          }
        }
      });

    } else {
      this.functionId = 0;
      this.list = [];
      this.total = 0;
    }
  }


  forArrayXzTreemou(obj: any, obj2: number) {
    let datasnodes = [];
    let array = JSON.parse(JSON.stringify(obj));
    ;
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == obj2) {
          let father: NzTreeNodeOptions = {
            title: obj[i].functionname,
            key: obj[i],
            children: [],
            icon: 'apartment',
            isLeaf: false
          };
          let thisid = obj[i].id;
          array.splice(i, 1);
          let das = this.forArrayXzTreemou(array, thisid);
          if (das.length > 0) {
            father.children = das;
            father.icon = 'apartment';
          } else {
            father.isLeaf = true;
            father.icon = 'home';
          }
          datasnodes.push(father);
        }
      }
    }
    return datasnodes;
  }

  forArrayXzTree(obj: any) {
    let array = JSON.parse(JSON.stringify(obj));
    //开始遍历大层遍历
    let datasnodes = [];
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == null || obj[i].functionid == undefined || obj[i].functionid == 0) {
          if (obj[i].functionname != null && obj[i].functionname != undefined) {
            let father: NzTreeNodeOptions = {
              title: obj[i].functionname,
              key: obj[i],
              icon: 'apartment',
              children: [],
              isLeaf: false
            };
            //删除了一条数据
            let thisid = obj[i].id;
            let das = this.forArrayXzTreemou(array, thisid);
            array.splice(i, 1);
            if (das.length > 0) {
              father.children = das;
            } else {
              father.isLeaf = true;
            }
            datasnodes.push(father);

          }

        }
      }
    }
    return datasnodes;
  }


  resetForm(): void {
    this.validateForm.reset();
    this.validateForm.controls.OverrunRateStart.setValue(0);
    this.validateForm.controls.OverrunRateEnd.setValue(0);
    this.validateForm.controls.OverWeightStart.setValue(0);
    this.validateForm.controls.OverWeightEnd.setValue(0);
  }


}
