<nz-layout class="app-layout"></nz-layout>
<div nz-row style="height:100%;width:100%;">


  <div class="box">
    <div class="front">我是正面</div>
    <div class="back">我是反面</div>
  </div>



</div>

