import {ActivatedRouteSnapshot, DetachedRouteHandle, Route, RouteReuseStrategy} from "@angular/router";

export class CustomerReuseStrategy implements RouteReuseStrategy {
  abstract;
  null;
  void;


  static nowHandler: any = null;

  static handlers: Map<Route, DetachedRouteHandle> = new Map();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if(route.routeConfig.path == "register"){
      return false;
    }
    return !route.firstChild;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!CustomerReuseStrategy.handlers.has(route.routeConfig);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
    return curr.routeConfig === future.routeConfig;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!!route.firstChild) {
      return null;
    }
    CustomerReuseStrategy.nowHandler = CustomerReuseStrategy.handlers.get(route.routeConfig);
    return CustomerReuseStrategy.handlers.get(route.routeConfig);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    CustomerReuseStrategy.handlers.set(route.routeConfig, handle);
  }

}
