import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class util{

  isNull(obj:any) {
   return obj == null || obj == undefined || obj == '' || obj.length == 0 || obj.size == 0 ? true : false;
 }
  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) { }

   fillZero = (n) => {
    let result = (n).toString().length === 1 ? ('0' + n): n;
    return result;
  }


  formatTime = (t = new Date()) => {
    let d = new Date(t);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let date = d.getDate();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let seconds = d.getSeconds();
    let result = year+'-'+this.fillZero(month)+'-'+this.fillZero(date)+' '+ this.fillZero(hours)+':'+this.fillZero(minutes)+':'+this.fillZero(seconds);
    return result;
  }

  /**
   * 时间转换
   * @param jsDtae
   */
  parSeStr(jsDtae: string) {
    let date = new Date(jsDtae);
    let Year: string;
    let Month: string;
    let Datel: string;
    let Hours: string;
    let Minutes: string;
    let Seconds: string;
    Year = '' + date.getFullYear();
    Month = '' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1));
    Datel = '' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    Hours = '' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    Minutes = '' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    Seconds = '' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    let youStrDate = Year + '-' + Month + '-' + Datel + ' ' + Hours + ':' + Minutes + ':' + Seconds;
    return youStrDate;
  }

  /**
   * 获取每一天
   * @param begin
   * @param end
   */
  getDayAll(begin,end){
    let dateAllArr = new Array();
    let db = new  Date(formatDate(begin,'yyyy-MM-dd',this.locale))
    let de = new  Date(formatDate(end,'yyyy-MM-dd',this.locale));
    let unixDb=db.getTime();
    let unixDe=de.getTime();
    let k=unixDb;
    while (k<=unixDe){
      dateAllArr.push(formatDate(new Date(parseInt(String(k))),'yyyy-MM-dd',this.locale));
      k=k+24*60*60*1000;
    }
    return dateAllArr;
  }

  /**
   * 获取每个小时
   * @param begin
   * @param end
   */
  getDayHours(begin,end){
    let dateAllArr = new Array();
    let db = new  Date(formatDate(begin,'yyyy-MM-dd HH:00:00',this.locale))
    let de = new  Date(formatDate(end,'yyyy-MM-dd HH:00:00',this.locale));

    let unixDb=db.getTime();
    let unixDe=de.getTime();
    let k=unixDb;
    while (k<=unixDe){
      dateAllArr.push(formatDate(new Date(parseInt(String(k))),'yyyy-MM-dd HH',this.locale));
      k=k+60*60*1000;
    }
    return dateAllArr;
  }

  /**
   * 十六进制颜色随机
   */
   color16(){
    let r = Math.floor(Math.random()*256);
    let g = Math.floor(Math.random()*256);
    let b = Math.floor(Math.random()*256);
    let color = '#'+r.toString(16)+g.toString(16)+b.toString(16);
    return color;
  }

  /**
   * 获取每一周
   * @param begin
   * @param end
   */
   getWeekAll(begin,end){
    let dateAllArr = new Array();
     let db = new  Date(formatDate(begin,'yyyy-MM-dd',this.locale))
     let de = new  Date(formatDate(end,'yyyy-MM-dd',this.locale));
     let unixDb=db.getTime();
     let unixDe=de.getTime();
    for(var k=unixDb;k<=unixDe;){
      dateAllArr.push(formatDate(new Date(parseInt(String(k))),'yyyy-MM-dd',this.locale));
      k=k+7*24*60*60*1000;
    }
    return dateAllArr;
  }

  /**
   * 获取2个时间之间的年
   *
   * @param begin
   * @param end
   */
   getYearAll(begin,end) {
    let d1 = begin;
    let d2 = end;
    let dateArry = new Array();
    let s1 = d1.split("-");
    let s2 = d2.split("-");
    let mYearCount = parseInt(s2[0]) - parseInt(s1[0])+1;
    let startY = parseInt(s1[0]);
    for (let i = 0; i < mYearCount;i++) {
      dateArry[i] = startY;
      startY += 1;
    }
    return dateArry;
  }

//获取两日期之间日期列表函数
   BetweenDay(stime,etime){
    //初始化日期列表，数组
    let diffdate = new Array();
     let i=0;
    //开始日期小于等于结束日期,并循环
    while(stime<=etime){
      diffdate[i] = stime;

      //获取开始日期时间戳
      let stime_ts = new Date(stime).getTime();

      //增加一天时间戳后的日期
      let next_date = stime_ts + (24*60*60*1000);

      //拼接年月日，这里的月份会返回（0-11），所以要+1
      let next_dates_y = new Date(next_date).getFullYear()+'-';
      let next_dates_m = (new Date(next_date).getMonth()+1 < 10)?'0'+(new Date(next_date).getMonth()+1)+'-':(new Date(next_date).getMonth()+1)+'-';
      let next_dates_d = (new Date(next_date).getDate() < 10)?'0'+new Date(next_date).getDate():new Date(next_date).getDate();

      stime = next_dates_y+next_dates_m+next_dates_d;

      //增加数组key
      i++;
    }
    return diffdate;
  }
  /**
   * 计算日期间的所有月
   * @param start
   * @param end
   */
  getMonthBetween(start,end){
    let result = [];
    let s = start.split("-");
    let e = end.split("-");
    let min = new Date();
    let max = new Date();
    min.setFullYear(s[0],s[1]);
    max.setFullYear(e[0],e[1]);
    let curr = min;
    while(curr <= max){
      let month = curr.getMonth();
      let month1=month<10?'0'+month:month;
      let str=curr.getFullYear()+"-"+(month1);
      let s=curr.getFullYear()+"-0";
      if(str==s){
        str=curr.getFullYear()+"-12";
      }
      result.push(str);
      curr.setMonth(month+1);
    }
    return result;
  }




}
