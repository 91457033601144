import {Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzFormatEmitEvent, NzTreeNodeOptions} from 'ng-zorro-antd';
import {TokenService, usersService, fuctionService, weightService} from '../core/services';
import {weight} from '../core/models';
import endOfMonth from 'date-fns/endOfMonth';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
declare var echarts: any;
import {EChartOption} from 'echarts';
import {util} from '../Util';
import {formatDate} from '@angular/common';
import {Router} from '@angular/router';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  /** 下拉地址框*/
  public leftoverheight = '10000px';
  public nodes: NzTreeNodeOptions[] = [];
  public searchValue = '';
  /** Tabs选框*/
    //，默认选择
  public tab1acticeID = 'tab1';
  /**表格 */
  public scrollY = '500px';
  public pagesize = 10;
  public pagenum = 1;
  public Total = 0;
  public nzLoading = false;
  public listOfData: any[] = [];
  public functionId = 0;
  @ViewChild('leftheight') leftheight: ElementRef;
  @ViewChild('body') elementView: ElementRef;
  /**日期 */
  public ranges1 = {'天': [new Date(), new Date()], '月': [new Date(), endOfMonth(new Date())]};
  public statrtime: string;
  public endtime: string;
  /**
   * 统计图
   */
  public graphics = [];
  public graphicspipe = [];
  public type = [];
  public timeweek = [];
  public clounmDate=[];
  /**
   * 默认选择
   */
  public selectedValue = 'month';
  /**
   * form表单
   */
  public validateForm!: FormGroup;
  public now: any = +new Date(1997, 9, 3);
  public oneDay = 24 * 3600 * 1000;
  public value = Math.random() * 1000;
  public data = [];
  @ViewChild('myCharts') myCharts: ElementRef;
  public colorValue:['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'];
  public optionsPieChart = {
    title: {
      text: '环形图',
      x:'center'
    },
   color:['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'vertical',
      left: 10,
      data: []
    },
    series: [
      {
        name: '站点名称',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '30',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: []
      }
    ]
  };
  public optionsColumnar = {
    title: {
      text: '柱状图 '
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: [],
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: '过磅数量 ',
        type: 'bar',
        barWidth: '60%',
        itemStyle: {
          normal: {
            color: function(params) {                        // 给出颜色组
              let colorList =['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'];
              return colorList[params.dataIndex]
            },
          }
        },

        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold'
          }
        },

        data: []
      }
    ]
  }

  public options = {
    title: {
      text: '折线图堆叠'
    },
   color:['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'],
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data:  []
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: []
  };

  constructor(
    private message: NzMessageService,
    private tokenService: TokenService,
    private usersService: usersService,
    private fuctionService: fuctionService,
    private weightinfoService: weightService,
    private fb: FormBuilder,
    private Util: util,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) {

  }


  ngOnInit(): void {
    let that = this;
    this.validateForm = this.fb.group({});
    this.addform();
    let accoun = this.tokenService.getAccoun();
    this.usersService.findFunction(accoun).subscribe((data) => {
      //成功
      if (data.statusCode == 200) {
        this.nodes = this.forArrayXzTree(data.content);
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          that.message.create('error', data.content);
        }
      }
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {

      let isnull = this.leftheight.nativeElement.offsetParent;
      if (isnull != null && isnull != undefined && isnull != 0) {
        let height = this.leftheight.nativeElement.offsetParent.clientHeight;
        let worm = height / 100;

        let x = Math.round(worm * 93);
        this.leftoverheight = x + 'px';


        let height2 = this.elementView.nativeElement.offsetParent.clientHeight;
        //  let head = this.elementhead.nativeElement.clientHeight
        let dij = height2 / 10;
        // let hedas = height2 - dij - (dij / 2) - head;
        let hedas = height2 - dij - (dij / 2);
        this.scrollY = hedas + 'px';

      }
    });
  }


  /** ******************下拉地址******************/
  /**
   * 点击区域文字
   * @param event
   */
  nzEvent(event: NzFormatEmitEvent): void {
    let id = event.node.key['id'];
    this.functionId = id;
    let type = 0;
    switch (this.selectedValue) {
      case 'year':
        type = 1;
        break;
      case 'month':
        type = 2;
        break;
      case 'day':
        type = 3;
        break;
      case 'hours':
        type = 4;
        break;
      case 'minutes':
        type = 5;
        break;
      case 'seconds':
        type = 6;
        break;
    }
    if (this.isNotNull(this.statrtime) && this.isNotNull(this.endtime)) {

      this.weightinfoService.findReport(this.statrtime, this.endtime, id, type, this.pagesize, this.pagenum).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,this.statrtime,this.endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
          this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          this.message.create('error', data.messages);
        }
      });
    } else {
      let myDate = this.getCurrentMonth();
      let strartime = this.parSeStr('' + myDate[0]);
      let str = myDate[1].getFullYear() + '-' + (myDate[1].getMonth() + 1) + '-' + myDate[1].getDate() + ' ' + 23 + ':' + 59 + ':' + 59;
      let endtime = this.parSeStr(str);

      this.weightinfoService.findReport(strartime, endtime, id, type, this.pagesize, this.pagenum).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,strartime,endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
          this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    }

  }

  forArrayXzTreemou(obj: any, obj2: number) {
    let datasnodes = [];
    let array = JSON.parse(JSON.stringify(obj));;
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == obj2) {
          let father: NzTreeNodeOptions = {
            title: obj[i].functionname,
            key: obj[i],
            children: [],
            icon: 'apartment',
            isLeaf: false
          };
          let thisid = obj[i].id;
          array.splice(i, 1);
          let das = this.forArrayXzTreemou(array, thisid);
          if (das.length > 0) {
            father.children = das;
            father.icon = 'apartment';
          } else {
            father.isLeaf = true;
            father.icon = 'home';
          }
          datasnodes.push(father);
        }
      }
    }
    return datasnodes;
  }

  forArrayXzTree(obj: any) {
    let array = JSON.parse(JSON.stringify(obj));;
    //开始遍历大层遍历
    let datasnodes = [];
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == null || obj[i].functionid == undefined || obj[i].functionid == 0) {
          if (obj[i].functionname != null && obj[i].functionname != undefined) {
            let father: NzTreeNodeOptions = {
              title: obj[i].functionname,
              key: obj[i],
              icon: 'apartment',
              children: [],
              isLeaf: false
            };

            //删除了一条数据
            let thisid = obj[i].id;
            let das = this.forArrayXzTreemou(array, thisid);
            array.splice(i, 1);
            if (das.length > 0) {
              father.children = das;
            } else {
              father.isLeaf = true;
            }
            datasnodes.push(father);


          }
        }
      }
    }
    return datasnodes;
  }

  /** ******************END******************/

  /**********************Tabs选框************/
  /**
   * 点击标签头
   * @param id 名称
   */
  activeTabChange(id) {
    //console.log(id);
  }

  /** 表格*/
  /**
   * 页数改变
   * @param event
   */
  changepage(event) {
    this.nzLoading = true;
    this.pagenum = event;
    let type = 0;
    switch (this.selectedValue) {
      case 'year':
        type = 1;
        break;
      case 'month':
        type = 2;
        break;
      case 'day':
        type = 3;
        break;
      case 'hours':
        type = 4;
        break;
      case 'minutes':
        type = 5;
        break;
      case 'seconds':
        type = 6;
        break;
    }
    if (this.isNotNull(this.statrtime) && this.isNotNull(this.endtime)) {

      this.weightinfoService.findReport(this.statrtime, this.endtime, this.functionId, type, this.pagesize, this.pagenum).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,this.statrtime,this.endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
          this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          this.message.create('error', data.messages);

        }
      });
    } else {
      let myDate = this.getCurrentMonth();
      let strartime = this.parSeStr('' + myDate[0]);
      let str = myDate[1].getFullYear() + '-' + (myDate[1].getMonth() + 1) + '-' + myDate[1].getDate() + ' ' + 23 + ':' + 59 + ':' + 59;
      let endtime = this.parSeStr(str);
      this.weightinfoService.findReport(strartime, endtime, this.functionId, type, this.pagesize, this.pagenum).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,strartime,endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
          this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    }
    this.nzLoading = false;
  }

  /**
   * 当前页数
   * @param event
   */
  changePageSize(event) {
    this.nzLoading = true;
    this.pagesize = event;
    let type = 0;
    switch (this.selectedValue) {
      case 'year':
        type = 1;
        break;
      case 'month':
        type = 2;
        break;
      case 'day':
        type = 3;
        break;
      case 'hours':
        type = 4;
        break;
      case 'minutes':
        type = 5;
        break;
      case 'seconds':
        type = 6;
        break;
    }
    if (this.isNotNull(this.statrtime) && this.isNotNull(this.endtime)) {
      this.weightinfoService.findReport(this.statrtime, this.endtime, this.functionId, type, this.pagesize, this.pagenum).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,this.statrtime,this.endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
          this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    } else {
      let myDate = this.getCurrentMonth();
      let strartime = this.parSeStr('' + myDate[0]);
      let str = myDate[1].getFullYear() + '-' + (myDate[1].getMonth() + 1) + '-' + myDate[1].getDate() + ' ' + 23 + ':' + 59 + ':' + 59;
      let endtime = this.parSeStr(str);
      this.weightinfoService.findReport(strartime, endtime, this.functionId, type, this.pagesize, this.pagenum).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,strartime,endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
          this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    }
    this.nzLoading = false;
  }

  /** 日期选择框*/
  onChangecreate(result: any[]): void {
    if (this.isNotNull(result[0])) {
      this.statrtime = this.parSeStr(result[0]);
    } else {
      this.statrtime = '';
    }
    if (this.isNotNull(result[1])) {
      this.endtime = this.parSeStr(result[1]);
    } else {
      this.endtime = '';
    }
  }

  /**
   * 时间转换
   * @param jsDtae
   */
  parSeStr(jsDtae: string) {
    let date = new Date(jsDtae);
    let Year: string;
    let Month: string;
    let Datel: string;
    let Hours: string;
    let Minutes: string;
    let Seconds: string;
    Year = '' + date.getFullYear();
    Month = '' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1));
    Datel = '' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    Hours = '' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    Minutes = '' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    Seconds = '' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    let youStrDate = Year + '-' + Month + '-' + Datel + ' ' + Hours + ':' + Minutes + ':' + Seconds;
    return youStrDate;
  }


  /** form*/
  addform(): void {
    this.validateForm.addControl('createtime', new FormControl());
    this.validateForm.addControl('type', new FormControl());
  }

  /**
   * 查询
   */
  serach(): void {

    let type = 0;
    switch (this.selectedValue) {
      case 'year':
        type = 1;
        break;
      case 'month':
        type = 2;
        break;
      case 'day':
        type = 3;
        break;
      case 'hours':
        type = 4;
        break;
      case 'minutes':
        type = 5;
        break;
      case 'seconds':
        type = 6;
        break;
    }
    if (this.isNotNull(this.statrtime) && this.isNotNull(this.endtime)) {
      this.weightinfoService.findReport(this.statrtime, this.endtime, this.functionId, type, 10, 1).subscribe((data) => {
        //成功
        if (data.statusCode == 200) {
          this.listOfData = data.content.list;
          this.Total = data.content.total;
          this.pagenum = data.content.pageNum;
          this.pagesize = data.content.pageSize;
          this.timeUpdate(type,this.statrtime,this.endtime);
          this.type = this.nameSet(data.content.list, 'functionname');
          this.graphics = this.lambdaFor(data.content.list, 'functionname');
          this.reashEcharts();
          this.graphicspipe=this.lambdaPieFor(data.content.list, 'functionname')
          this.reashEchartsPipe();
           this.clounmDate=this.lambdaCloumFor(data.content.list, 'functionname');
          this.reashCloum();
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    } else {
      this.message.create('warning', '请选择开始时间与结束时间');
    }


  }


  DateDiff(sDate1, sDate2) {    //sDate1和sDate2是2006-12-18格式
    let aDate, oDate1, oDate2, iDays;
    aDate = sDate1.split('-');
    oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]);    //转换为12-18-2006格式
    aDate = sDate2.split('-');
    oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]);
    let abc = '' + Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24;
    iDays = parseInt(abc);  //把相差的毫秒数转换为天数
    return iDays;
  }

  selectchange(event): void {
    this.selectedValue = event;
  }

  isNotNull(obj: any) {
    if (obj == null || obj == undefined || obj == '' || obj.length == 0 || obj.size == 0) {
      return false;
    } else {
      return true;
    }

  }

  /**
   * 名称分组去重
   */
  nameSet(list, types) {
    let fieldList = [];
    list.map((e) => {
      fieldList.push(e[types]);
    });
    fieldList = fieldList.filter((e, i, self) => {
      return self.indexOf(e) == i;
    });
    return fieldList;
  }



  /**
   * 对饼图数据进行遍历取出响应的值
   * @param str
   * @param data
   */
  lambdaCloumFor(list, field) {
    let fieldList = [], att = [];

    list.map((e) => {
      fieldList.push(e[field]);
    });
    //数组去重
    fieldList = fieldList.filter((e, i, self) => {
      return self.indexOf(e) == i;
    });
    fieldList.forEach(obj=>{
      let opptonlist = list.filter(x => x.functionname == obj);
      let count = 0;
      if (this.isNotNull(opptonlist)) {
        opptonlist.forEach(search=>{
          count=count+search.trueweight;
        });
      }

      att.push(count);
    });
    return att;
  }

  /**
   * 对饼图数据进行遍历取出响应的值
   * @param str
   * @param data
   */
  lambdaPieFor(list, field) {
    let fieldList = [], att = [];

    list.map((e) => {
      fieldList.push(e[field]);
    });
    //数组去重
    fieldList = fieldList.filter((e, i, self) => {
      return self.indexOf(e) == i;
    });
    let arr = [];

    fieldList.forEach(obj=>{
      let opptonlist = list.filter(x => x.functionname == obj);
      let arrayMap=0;
      let afterCout=0;
      let carCount=0;
      if (this.isNotNull(opptonlist)) {
        let count = 0;
        opptonlist.forEach(search=>{
          carCount=carCount+search.weightscount;
          count=count+search.percentage;
          afterCout=afterCout+search.weightscount*(search.percentage/100);
        });
        arrayMap=count;
      }

     arrayMap=opptonlist.length==1? arrayMap:(afterCout/carCount)*100;
      att.push({
        value:arrayMap,
        name: obj
      });
    });
    return att;
  }



  /**
   * 对折线图数据进行遍历取出响应的值
   * @param str
   * @param data
   */
  lambdaFor(list, field) {
    let fieldList = [], att = [];

    list.map((e) => {
      fieldList.push(e[field]);
    });
    //数组去重
    fieldList = fieldList.filter((e, i, self) => {
      return self.indexOf(e) == i;
    });
    let arr = [];
    for (let j = 0; j < this.timeweek.length; j++) {
      //过滤出匹配到的数据  3条
      fieldList.forEach((obj) => {
        let opptonlist = list.filter(x => x.functionname == obj && x.time == this.timeweek[j]);
        //去重之后不会重复一个人的数据
        let arrayMap=arr[obj];
        if (!this.isNotNull(arrayMap)) {
          arrayMap=[];
        }
        if (this.isNotNull(opptonlist)) {
          let count = 0;
          if ((opptonlist[0].trueweight - opptonlist[0].totalamount) < 0) {
            arrayMap.push(count);
          } else {
            arrayMap.push((opptonlist[0].trueweight - opptonlist[0].totalamount));
          }
        } else {
          arrayMap.push(0);
        }
        arr[obj]=arrayMap;
      });

    }
    fieldList.forEach((obj) => {
      att.push({
        name: obj,
        type: 'line',
        data: arr[obj]
      });
    });


    return att;
  }


  /***
   * 获得本月的起止时间
   */
  getCurrentMonth() {
    //起止日期数组
    var startStop = new Array();
    //获取当前时间
    var currentDate = new Date();
    //获得当前月份0-11
    var currentMonth = currentDate.getMonth();
    //获得当前年份4位年
    var currentYear = currentDate.getFullYear();
    //求出本月第一天
    var firstDay = new Date(currentYear, currentMonth, 1);

    //当为12月的时候年份需要加1
    //月份需要更新为0 也就是下一年的第一个月
    if (currentMonth == 11) {
      currentYear++;
      currentMonth = 0;//就为
    } else {
      //否则只是月份增加,以便求的下一月的第一天
      currentMonth++;
    }
    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;
    //下月的第一天
    var nextMonthDayOne = new Date(currentYear, currentMonth, 1);
    //求出上月的最后一天
    var lastDay = new Date((nextMonthDayOne.getTime() - millisecond));
    //添加至数组中返回
    startStop.push(firstDay);
    startStop.push(lastDay);
    //返回
    return startStop;
  }

  /**
   * 折线图时间该笔那
   */
  timeUpdate(type,start,end):void{
    switch (type) {
      case 1:
        this.timeweek = this.Util.getYearAll(start, end);
        break;
      case 2:
        this.timeweek = this.Util.getMonthBetween(start, end);
        break;
      case 3:
        this.timeweek = this.Util.getDayAll(start, end);
        break;
      case 4:
        this.timeweek = this.Util.getDayHours(start, end);
        break;
      case 5:
        break;
      case 6:
        break;
    }
  }

  reashEchartsPipe():void{
    // let color=[];
    // this.type.forEach(obj=>{
    //   color.push(this.Util.color16());
    // });
    this.optionsPieChart = {
      title: {
        text: '环形图',
        x:'center'
      },
     color:['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: 10,
        data:this.type
      },
      // color: color,
      series: [
        {
          name: '站点名称',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data:this.graphicspipe
        }
      ]
    };
  }

  reashEcharts():void{
    this.options = {
      title: {
        text: '折线图'
      },
      color:['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'],
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: this.type
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.timeweek
      },
      yAxis: {
        type: 'value'
      },
      series: this.graphics
    };
  }

  reashCloum():void{
    let  that=this;
    that.optionsColumnar={
      title: {
        text: '柱状图 '
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: that.type,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '过磅数量 ',
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            normal: {
              color: function(params) {                        // 给出颜色组
                let colorList =['#378eda','#FFDB5C','#FF9F7F','#FB7293','#E062AE','#32c5e9','#67d0e3','#9FE6B8','#E690D1','#E7BCF3','#9D96F5','#8378EA','#96BFFF'];
                return colorList[params.dataIndex]
              },
            }
          },

          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },

          data: that.clounmDate
        }
      ]
    }
  }
}
