import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {usersService} from '../core/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-actor',
  templateUrl: './actor.component.html',
  styleUrls: ['./actor.component.css']
})
export class ActorComponent implements OnInit {
  validateForm: FormGroup;

  constructor(
    private router: Router,
    private usersService: usersService,
    private message: NzMessageService,
    private fb: FormBuilder
  ) {
    this.validateForm = this.fb.group({
      roleName: ['', [this.confirmValidator]],
      levelId: ['', [this.confirmValidator]],
      management: ['', [this.confirmValidator]],
    });
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {error: true, required: true};
    }
    return {};
  };
  listOfData: any[] = [];
  isVisible = false;
  loading = false;
  role: any = null;

  autoTips: Record<string, Record<string, string>> = {
    'zh-cn': {
      required: '必填项',
    }
  };

  ngOnInit(): void {
    let that = this;
    that.usersService.findUserByUserInformation().subscribe(data => {
      if (data.statusCode == 200) {
        that.role = data.content.role;
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });

    that.usersService.findRole().subscribe(data => {
      if (data.statusCode == 200) {
        that.listOfData = data.content;
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });
  }

  submitForm(value: { roleName: string; levelId: number; management: boolean; }): void {
    let that = this;
    if (Number(that.role.levelid) < value.levelId) {
      this.usersService.SaveRole(value.roleName, value.levelId + '', value.management).subscribe(data => {
        if (data.statusCode == 200) {
          that.ngOnInit();
        } else {
          if (data.content.code == 40002) {
            that.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            that.message.create('error', data.content.subMsg);
          } else {
            that.message.create('error', data.content);
          }
        }
      });
    }else {
      that.message.create('warning',"等级不能高于自己");
    }
  }


  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  clickSwitch(id: number, value: boolean): void {
    let that = this;
    if (!this.loading) {
      this.loading = true;
      that.listOfData.forEach(item => {
        if (item.id == id) {
          item.management = item.management;
          value = item.management;
        }
      });
      this.usersService.updateRole(id, !value).subscribe(data => {
        if (data.statusCode == 200) {
          this.loading = false;
          that.message.create('success', '更改成功');
        } else {
          if (data.content.code == 40002) {
            that.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            that.message.create('error', data.content.subMsg);
          } else {
            that.listOfData.forEach(item => {
              if (item.id == id) {
                item.management = !item.management;
                value = item.management;
              }
            });
            that.message.create('error', data.content + '-请刷新');
          }
        }
      });
    }
  }

}
