import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NzCascaderOption} from 'ng-zorro-antd/cascader';

declare var BMap: any;
declare var BMapLib: any;
declare var BMAP_ANCHOR_BOTTOM_RIGHT: any;
declare var BMAP_DRAWING_MARKER: any;
declare var BMAP_DRAWING_CIRCLE: any;
declare var BMAP_ANCHOR_TOP_LEFT: any;
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzFormatEmitEvent, NzTreeNodeOptions} from 'ng-zorro-antd';
import {fuctionService, TokenService, usersService} from '../core/services';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {TransferItem} from 'ng-zorro-antd/transfer';
import {util} from '../Util';
import {Title} from '@angular/platform-browser';

let options = [];

export interface ThisPoint {
  lng: number
  lat: number
  id: number
}


@Component({

  selector: 'app-themap',
  templateUrl: './themap.component.html',
  styleUrls: ['./themap.component.css']
})


export class ThemapComponent implements OnInit {


  public leftoverheight = '10000px';
  @ViewChild('leftheight') leftheight: ElementRef;
  @ViewChild('baidumap') baiudmap: ElementRef;
  @ViewChild('body') elementView: ElementRef;
  public scrollY = '0px';
  public nodes: NzTreeNodeOptions[] = [];
  public searchValue = '';
  public drawingTypeboolean = false;
  public nzdrawer = false;
  public validateForm!: FormGroup;
  public modelclick = false;
  public functionclick = false;
  public abc: any;
  declare popop: any;
  public id = 0;
  public map: any;
  public drawingManager: any;
  public overlays: any[] = [];
  //modal文本框
  public isVisible = false;
  public isConfirmLoading = false;
  public choseValue = [];
  nzOptionsLeft: NzCascaderOption[] = options;
  valuesLeft: string[] | null = null;
  nzOptionsRight: NzCascaderOption[] = options;
  valuesRight: string[] | null = null;
  leftNumber: number = 0;
  rightNumber: number = 0;
  list: TransferItem[] = [];
  disabled = false;
  public tsFer: any[] = [];
  public mapThe = [];
  public fatherId: any[] = [];
  public x = 116.404;
  public y = 39.915;
  private productId: number;
  constructor(
    private tokenService: TokenService,
    private usersService: usersService,
    private fuctionService: fuctionService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private router: Router,
    private util:util,
    private activate:ActivatedRoute,
    private pageTitle:Title
  ) {
  }



  onChangesLeft(values: string[]): void {
    this.list = this.list.filter((item) => item.direction != 'left');
    let array: TransferItem[] = JSON.parse(JSON.stringify(this.list));
    if (values.length > 0) {
      this.leftNumber = Number(values[values.length - 1]);
      this.fuctionService.findChiDreName(Number(values[values.length - 1])).subscribe(data => {
        if (data.statusCode == 200) {
          data.content.forEach(item => {
            array.push({
              key: item.id,
              title: item.functionname,
              disabled: false,
              direction: 'left'
            });
          });
          this.getData(array);
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    } else {
      this.leftNumber = 0;
    }
    if (this.leftNumber != this.rightNumber) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  validateLeft(option: NzCascaderOption, _index: number): boolean {
    const value = option.value as string;
    return 1 >= 0;
  }

  onChangesRight(values: string[]): void {
    this.list = this.list.filter((item) => item.direction != 'right');
    const array: TransferItem[] = JSON.parse(JSON.stringify(this.list));
    if (values.length > 0) {
      this.rightNumber = Number(values[values.length - 1]);
      this.fuctionService.findChiDreName(Number(values[values.length - 1])).subscribe(data => {
        if (data.statusCode == 200) {
          data.content.forEach(item => {
            array.push({
              key: item.id,
              title: item.functionname,
              disabled: false,
              direction: 'right'
            });
          });
          this.getData(array);
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    } else {
      this.rightNumber = 0;
    }
    if (this.leftNumber != this.rightNumber) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  getData(array: TransferItem[]): void {
    this.list = array;
  }

  validateRight(option: NzCascaderOption, _index: number): boolean {
    const value = option.value as string;
    return 1 >= 0;
  }


  /**
   * 选中
   * @param ret
   */
  select(ret: {}): void {
    console.log('nzSelectChange', ret);
  }

  /**
   * 移动
   * @param ret
   */
  change(ret: any): void {
    let that = this;
    //移入添加至某节点
    if (ret.to == 'left') {
      //移动至Id
      let fatherId = this.leftNumber;
      //需要移动Id
      let keys: any[] = [];
      ret.list.forEach(item => {
        keys.push({id: item.key, functionId: fatherId});
      });
      //存在删除不存在添加

      if (that.fatherId.length > 0) {
        let c: any[] = that.fatherId.filter(f => {
          return !keys.map(v => v.id).includes(f.id);
        });
        that.fatherId = c;
      } else {
        that.fatherId = keys;
      }
    } else {
      let fatherId = this.rightNumber;
      //需要移动Id
      let keys: any[] = [];
      ret.list.forEach(item => {
        keys.push({id: item.key, functionId: fatherId});
      });
      if (that.fatherId.length > 0) {
        let c: any[] = that.fatherId.filter(f => {
          return !keys.map(v => v.id).includes(f.id);
        });
        that.fatherId = c;
      } else {
        that.fatherId = keys;
      }

    }
  }


  loadData():void{
    let that = this;
    that.map = new BMap.Map('map', {enableMapClick: false});//创建地图实例

    const point = new BMap.Point(this.x, this.y);//创建点坐标
    that.map.centerAndZoom(point, 15);//初始化地图，设置中心点坐标和地图级别
    that.map.enableScrollWheelZoom(true);//开启鼠标滚轮缩放

    let cityControl = new BMap.CityListControl({
      // 控件的停靠位置（可选，默认左上角）
      anchor: BMAP_ANCHOR_TOP_LEFT,
      // 控件基于停靠位置的偏移量（可选）
      offset: new BMap.Size(10, 5)
    });

    that.map.addControl(cityControl)

    function addClickHandler(content, marker, functionId, opts) {
      marker.addEventListener('click', function(e) {
          openInfo(content, e, functionId, opts);
        }
      );
      marker.addEventListener('open', function(e) {
        marker.getLabel().hidden();
      });
      marker.addEventListener('close', function(e) {
        marker.getLabel().show();
      });
    }

    function openInfo(content, e, functionId, opts) {
      let p = e.target;
      let point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
      let infoWindow = new BMap.InfoWindow(content, opts);  // 创建信息窗口对象
      that.map.openInfoWindow(infoWindow, point); //开启信息窗口
      setTimeout(function() {
        let dom = document.getElementById('click' + functionId);
        dom.addEventListener('click', function() {
          that.showWindow(functionId);
        });
        let link = document.getElementById('link' + functionId);
        link.addEventListener('click', function() {
          that.router.navigate(['menu/thisDataGeorAphic/' + functionId]);
        });

      }, 200);

    }

    function createIcon(json) {
      let icon = new BMap.Icon(' http://api.map.baidu.com/lbsapi/creatmap/images/us_mk_icon.png', new BMap.Size(json.w, json.h), {
        imageOffset: new BMap.Size(-json.l, -json.t),
        infoWindowOffset: new BMap.Size(json.lb + 5, 1),
        offset: new BMap.Size(json.x, json.h)
      });
      return icon;
    }


    this.fuctionService.findChiDreNOrg(0).subscribe(data => {
      if (data.statusCode == 200) {
        let list = data.content;
        for (let i = 0; i < list.length; i++) {

          let iconImg = createIcon({w: 23, h: 25, l: 23, t: 21, x: 9, lb: 12});
          //标点
          let marker = new BMap.Marker(new BMap.Point(list[i].xshaft, list[i].yshaft, {icon: iconImg}));

          // let markerMenu = new BMap.ContextMenu();
          // let removeMarker = function(e, ee, marker) {
          //   that.isVisible = true;
          //   //that.map.removeOverlay(marker);
          // };
          //
          // markerMenu.addItem(new BMap.MenuItem('绑定节点', removeMarker.bind(marker)));
          //
          // marker.addContextMenu(markerMenu);

          let functionname = list[i].functionname;

          let content = '<div class="col-xs-12" style="margin-left: -20px">' +
            '                    <ul class="list-unstyled">' +
            '                      <li style="list-style-type:none;">站点名称：' + functionname + '</li>' +
            '                      <li style="list-style-type:none;">经度：' + list[i].xshaft + '</li>' +
            '                      <li style="list-style-type:none;">纬度：' + list[i].yshaft + '</li>' +
            '                      <li style="list-style-type:none;">备注：' + list[i].remark + '</li>' +
            '                      <li id="click' + list[i].id + '" style="list-style-type:none;float: right;margin-top: -10px;" ><a href="javaScript:void(0)">详情</a></li>' +
            '                    </ul>' +
            '                  </div>';
          that.map.addOverlay(marker);


          let opts = {}
          if (functionname==undefined || functionname==null || functionname==""){
            opts = {
              width: 0,     // 信息窗口宽度
              height: 0,     // 信息窗口高度
              title: '<h3 style=\'float: left\'>信息窗口</h3>', // 信息窗口标题
              enableMessage: true//设置允许信息窗发送短息
            };
          }else {
            opts = {
              width: 0,     // 信息窗口宽度
              height: 0,     // 信息窗口高度
              title: '<h3 style=\'float: left\'>信息窗口</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style="color: aqua" id="link' + data.content.id + '" href="javaScript:void(0)">数据展示</a>', // 信息窗口标题
              enableMessage: true//设置允许信息窗发送短息
            };

          }

          addClickHandler(content, marker, list[i].id, opts);

          //文本框
          let label = new BMap.Label(list[i].functionname, {offset: new BMap.Size(12 - 9 + 10, -20)});
          label.setStyle({});
          marker.setLabel(label);
          label.setStyle({
            borderColor: '#808080',
            color: '#333',
            cursor: 'pointer'
          });


        }
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);

        } else {
          this.message.create('error', data.content);
        }
      }
    });


    this.validateForm = this.fb.group({
      id: [{value: null, disabled: true}],
      functionid: [{value: null, disabled: true}],
      createtime: [{value: null, disabled: true}],
      updatetime: [{value: null, disabled: true}],
      xshaft: [{value: null, disabled: true}],
      yshaft: [{value: null, disabled: true}]
    });
    this.addform();
    let accoun = this.tokenService.getAccoun();
    this.usersService.findFunction(accoun).subscribe((data) => {
      //成功
      if (data.statusCode == 200) {
        this.tsFer = data.content;
        this.nodes = this.forArrayXzTree(data.content);
        that.nzOptionsLeft = that.mapThe;
        that.nzOptionsRight = that.mapThe;
        this.disabled = true;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });


    const overlaycomplete = function(e) {
      that.fuctionService.saveFunctio(e.overlay.point.lng, e.overlay.point.lat).subscribe(data => {
        if (data.statusCode == 200) {
          let functionId = data.content.id;
          let content = '<div class="col-xs-12" style="margin-left: -20px">' +
            '                    <ul class="list-unstyled">' +
            '                      <li style="list-style-type:none;">站点名称：' + data.content.functionname + '</li>' +
            '                      <li style="list-style-type:none;">经度：' + data.content.xshaft + '</li>' +
            '                      <li style="list-style-type:none;">纬度：' + data.content.yshaft + '</li>' +
            '                      <li style="list-style-type:none;">备注：' + data.content.remark + '</li>' +
            '                      <li id="click' + data.content.id + '" style="list-style-type:none;float: right;margin-top: -10px;" ><a href="javaScript:void(0)">详情</a></li>' +
            '                    </ul>' +
            '                  </div>';



          let opts = {}
          if (data.content.functionname==undefined || data.content.functionname==null || data.content.functionname==""){
            opts = {
              width: 0,     // 信息窗口宽度
              height: 0,     // 信息窗口高度
              title: '<h3 style=\'float: left\'>信息窗口</h3>', // 信息窗口标题
              enableMessage: true//设置允许信息窗发送短息
            };
          }else {
            opts = {
              width: 0,     // 信息窗口宽度
              height: 0,     // 信息窗口高度
              title: '<h3 style=\'float: left\'>信息窗口</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style="color: aqua" id="link' + data.content.id + '" href="javaScript:void(0)">数据展示</a>', // 信息窗口标题
              enableMessage: true//设置允许信息窗发送短息
            };

          }
          e.overlay.addEventListener('click', function() {
            let point2 = new BMap.Point(e.overlay.point.lng, e.overlay.point.lat);
            let infoWindow = new BMap.InfoWindow(content, opts);  // 创建信息窗口对象
            that.map.openInfoWindow(infoWindow, point2); //开启信息窗口
            setTimeout(function() {
              let dom = document.getElementById('click' + functionId);
              dom.addEventListener('click', function() {
                that.showWindow(functionId);
              });
            }, 200);
          });
        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
          } else {
            this.message.create('error', data.content);
            this.message.create('warning', data.content);
          }
        }
      });
    };
    let styleOptions = {
      strokeColor: 'red',    //边线颜色。
      fillColor: 'red',      //填充颜色。当参数为空时，圆形将没有填充效果。
      strokeWeight: 3,       //边线的宽度，以像素为单位。
      strokeOpacity: 0.8,	   //边线透明度，取值范围0 - 1。
      fillOpacity: 0.6,      //填充的透明度，取值范围0 - 1。
      strokeStyle: 'solid' //边线的样式，solid或dashed。
    };
    //实例化鼠标绘制工具
    this.drawingManager = new BMapLib.DrawingManager(that.map, {
      isOpen: false, //是否开启绘制模式
      enableDrawingTool: true, //是否显示工具栏
      drawingToolOptions: {
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT, //位置
        offset: new BMap.Size(5, 5), //偏离值
        drawingModes: [BMAP_DRAWING_MARKER]
      },
      circleOptions: styleOptions, //圆的样式
      polylineOptions: styleOptions, //线的样式
      polygonOptions: styleOptions, //多边形的样式
      rectangleOptions: styleOptions //矩形的样式
    });
    this.drawingManager.addEventListener('overlaycomplete', overlaycomplete);


  }



  ngOnInit(): void {
    let accoun=this.tokenService.getAccoun();
    this.loadData();
  }



  public showWindow(id: number): void {
    this.fuctionService.findByIdFunction(id).subscribe((data) => {
      //成功
      if (data.statusCode == 200) {
        let content = data.content;
        this.nzdrawer = true;
        this.modelclick = !data.content.isdel;
        this.functionclick = !data.content.isfunction;
        this.validateForm.controls.id.setValue(content.id);
        this.validateForm.controls.functionname.setValue(content.functionname);
        this.validateForm.controls.functionid.setValue(content.functionid);
        this.validateForm.controls.createtime.setValue(content.createtime);
        this.validateForm.controls.updatetime.setValue(content.updatetime);
        this.validateForm.controls.xshaft.setValue(content.xshaft);
        this.validateForm.controls.yshaft.setValue(content.yshaft);
        this.validateForm.controls.isfunction.setValue(content.isfunction);
        this.validateForm.controls.remark.setValue(content.remark);
        this.validateForm.controls.isdel.setValue(content.isdel);
        this.id = content.id;
        // }
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });


  }

    openModal():void{
      this.isVisible = true;
    }

  close(): void {
    this.nzdrawer = false;
  }


  ngAfterViewInit() {
    let a: any;
    setTimeout(() => {
      let isnull = this.leftheight.nativeElement.offsetParent;
      if (isnull != null && isnull != undefined && isnull != 0) {
        let height = this.leftheight.nativeElement.offsetParent.clientHeight;
        let worm = height / 100;

        let x = Math.round(worm * 93);
        this.leftoverheight = x + 'px';


        let height2 = this.elementView.nativeElement.offsetParent.clientHeight;
        //  let head = this.elementhead.nativeElement.clientHeight
        let dij = height2 / 10;
        // let hedas = height2 - dij - (dij / 2) - head;
        let hedas = height2 - dij - (dij / 2);
        this.scrollY = hedas + 'px';

      }
    });


  }

  forArrayXzTreemou(obj: any, obj2: number) {
    let datasnodes = {mob: [], mos: []};
    let array = JSON.parse(JSON.stringify(obj));
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == obj2) {
          let luck = {mob: [], mos: []};
          let father: NzTreeNodeOptions = {
            title: obj[i].functionname,
            key: obj[i],
            children: [],
            icon: 'apartment',
            isLeaf: false
          };
          let moneyNice = {
            value: obj[i].id,
            label: obj[i].functionname,
            children: [],
            isLeaf: false
          };
          this.choseValue.push(obj[i].functionname);
          let thisid = obj[i].id;
          array.splice(i, 1);
          let das = this.forArrayXzTreemou(array, thisid);

          if (das.mob.length > 0) {
            father.children = das.mob;
            moneyNice.children = das.mos;
            father.icon = 'apartment';
          } else {
            father.isLeaf = true;
            moneyNice.isLeaf = true;
            father.icon = 'home';
          }
          datasnodes.mob.push(father);
          datasnodes.mos.push(moneyNice);
        }
      }
    }
    return datasnodes;
  }

  forArrayXzTree(obj: any) {
    let that = this;
    let array = JSON.parse(JSON.stringify(obj));
    //开始遍历大层遍历
    let datasnodes = [];

    for (let i = 0; i < obj.length; i++) {
      if (obj[i] != null) {
        if (obj[i].functionid == null || obj[i].functionid == undefined || obj[i].functionid == 0) {
          if (obj[i].functionname != null && obj[i].functionname != undefined) {
            let father: NzTreeNodeOptions = {
              title: obj[i].functionname,
              key: obj[i],
              icon: 'apartment',
              children: [],
              isLeaf: false
            };
            let moneyNice = {
              value: obj[i].id,
              label: obj[i].functionname,
              children: [],
              isLeaf: false
            };
            that.choseValue.push(obj[i].functionname);
            //删除了一条数据
            let thisid = obj[i].id;
            let das = this.forArrayXzTreemou(array, thisid);
            array.splice(i, 1);
            if (das.mob.length > 0) {
              father.children = das.mob;
              moneyNice.children = das.mos;
            } else {
              father.isLeaf = true;
              moneyNice.isLeaf = true;
            }
            datasnodes.push(father);
            that.mapThe.push(moneyNice);
          }
        }
        if (obj[i].functionname != '' && obj[i].functionname != null && obj[i].functionname != undefined && (obj[i].functionid == null || obj[i].functionid == undefined || obj[i].functionid == 0)) {
          that.list.push({
            key: obj[i].id,
            title: obj[i].functionname,
            disabled: false,
            direction: 'left'
          });
          that.list.push({
            key: obj[i].id,
            title: obj[i].functionname,
            disabled: false,
            direction: 'right'
          });
        }
      }

    }
    return datasnodes;
  }


  reahSelct():void{
    let accoun = this.tokenService.getAccoun();
    this.usersService.findFunction(accoun).subscribe((data) => {
      //成功
      if (data.statusCode == 200) {
        this.tsFer = data.content;
        this.nodes = this.forArrayXzTree(data.content);
        this.nzOptionsLeft = this.mapThe;
        this.nzOptionsRight = this.mapThe;
        this.disabled = true;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }



  /**
   * 点击区域文字
   * @param event
   */
  nzEvent(event: NzFormatEmitEvent): void {
    let that = this;
    that.map.clearOverlays();
    let xshaft=event.node.key['xshaft'];
    let yshaft=event.node.key['yshaft'];
    console.log(event.node.key);

    if (!this.util.isNull(yshaft) && !this.util.isNull(xshaft)){
        this.x=xshaft;
        this.y=yshaft;
      const point = new BMap.Point(this.x, this.y);//创建点坐标
      this.map.centerAndZoom(point, 15);//初始化地图，设置中心点坐标和地图级别
    }

    function addClickHandler(content, marker, functionId, opts) {
      marker.addEventListener('click', function(e) {
          openInfo(content, e, functionId, opts);
        }
      );
      marker.addEventListener('open', function(e) {
        marker.getLabel().hidden();
      });
      marker.addEventListener('close', function(e) {
        marker.getLabel().show();
      });

    }

    function openInfo(content, e, functionId, opts) {
      let p = e.target;
      let point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
      let infoWindow = new BMap.InfoWindow(content, opts);  // 创建信息窗口对象
      that.map.openInfoWindow(infoWindow, point); //开启信息窗口
      setTimeout(function() {
        let dom = document.getElementById('click' + functionId);
        dom.addEventListener('click', function() {
          that.showWindow(functionId);
        });
      }, 200);

    }

    function createIcon(json) {
      let icon = new BMap.Icon(' http://api.map.baidu.com/lbsapi/creatmap/images/us_mk_icon.png', new BMap.Size(json.w, json.h), {
        imageOffset: new BMap.Size(-json.l, -json.t),
        infoWindowOffset: new BMap.Size(json.lb + 5, 1),
        offset: new BMap.Size(json.x, json.h)
      });
      return icon;
    }

    let functionId = event.node.key['id'];
    this.fuctionService.findChiDreNOrg(functionId).subscribe(data => {
      if (data.statusCode == 200) {
        let list = data.content;
        for (let i = 0; i < list.length; i++) {

          let iconImg = createIcon({w: 23, h: 25, l: 23, t: 21, x: 9, lb: 12});

          //标点
          let marker = new BMap.Marker(new BMap.Point(list[i].xshaft, list[i].yshaft, {icon: iconImg}));


          let functionname = list[i].functionname;
          let content = '<div class="col-xs-12" style="margin-left: -20px">' +
            '                    <ul class="list-unstyled">' +
            '                      <li style="list-style-type:none;">站点名称：' + functionname + '</li>' +
            '                      <li style="list-style-type:none;">经度：' + list[i].xshaft + '</li>' +
            '                      <li style="list-style-type:none;">纬度：' + list[i].yshaft + '</li>' +
            '                      <li style="list-style-type:none;">备注：' + list[i].remark + '</li>' +
            '                      <li  id="click' + list[i].id + '" style="list-style-type:none;float: right;margin-top: -10px;"><a href="javaScript:void(0)">详情</a></li>' +
            '                    </ul>\n' +
            '                  </div>';
          that.map.addOverlay(marker);
          let opts = {}
          if (functionname==undefined || functionname==null || functionname==""){
            opts = {
              width: 0,     // 信息窗口宽度
              height: 0,     // 信息窗口高度
              title: '<h3 style=\'float: left\'>信息窗口</h3>', // 信息窗口标题
              enableMessage: true//设置允许信息窗发送短息
            };
          }else {
            opts = {
              width: 0,     // 信息窗口宽度
              height: 0,     // 信息窗口高度
              title: '<h3 style=\'float: left\'>信息窗口</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a style="color: aqua" id="link' + list[i].id + '" href="javaScript:void(0)">数据展示</a>', // 信息窗口标题
              enableMessage: true//设置允许信息窗发送短息
            };

          }

          addClickHandler(content, marker, list[i].id, opts);
          //文本框
          let label = new BMap.Label(list[i].functionname, {offset: new BMap.Size(12 - 9 + 10, -20)});
          marker.setLabel(label);
          label.setStyle({
            borderColor: '#808080',
            color: '#333',
            cursor: 'pointer'
          });


        }
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });


  }

  functionclickSwitch(): void {
    this.functionclick = !this.functionclick;
  }

  modelclickSwitch(): void {
    this.modelclick = !this.functionclick;
  }

  submit(): void {

    let id = this.validateForm.controls.id.value;
    let functionname = this.validateForm.controls.functionname.value;
    let remark = this.validateForm.controls.remark.value;
    let isdel = !this.modelclick;
    let isfunction = !this.functionclick;
    this.fuctionService.updateFunction(id, functionname, remark, isdel, isfunction).subscribe((data) => {
      if (data.statusCode == 200) {
        this.message.create('success', '更改成功');
        document.getElementById('click' + id).parentNode.children[0].textContent = '站点名称：' + data.content.functionname;
        document.getElementById('click' + id).parentNode.children[3].textContent = '备注：' + data.content.remark;
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }

      }
    });

  }


  handleOk(): void {
    let that = this;
    this.isConfirmLoading = true;
    that.fuctionService.updateFunctionObj(JSON.stringify(that.fatherId)).subscribe(data => {
      if (data.statusCode == 200) {
        this.message.create('success', '更改成功');
        //this.reahSelct();
        this.loadData();
        setTimeout(() => {
          this.isVisible = false;
          this.isConfirmLoading = false;
        }, 2000);
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
         this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
        this.isVisible = false;
        this.isConfirmLoading = false;
      }
    });


  }

  handleCancel(): void {
    this.isVisible = false;
  }


  addform(): void {
    this.validateForm.addControl('id', new FormControl());
    this.validateForm.addControl('functionname', new FormControl());
    this.validateForm.addControl('functionid', new FormControl());
    this.validateForm.addControl('createtime', new FormControl());
    this.validateForm.addControl('updatetime', new FormControl());
    this.validateForm.addControl('xshaft', new FormControl());
    this.validateForm.addControl('yshaft', new FormControl());
    this.validateForm.addControl('isfunction', new FormControl());
    this.validateForm.addControl('remark', new FormControl());
    this.validateForm.addControl('isdel', new FormControl());
  }


  mouserver() {
    alert('触发了');
  }

  onChange(status: boolean): void {
    console.log(status);
  }
}




