import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(
    private apiService: ApiService
  ) { }

  findEquipmentByFunctionId(functionId:number){
    return this.apiService.callApi('/EquipmentController/findEquipmentByFunctionId',{functionId:functionId}).pipe(
      map(data => {
          return data;
        }
      ));
  }


  addEquipment(deviceName:string,deviceSerial:string,validateCode:string,functionId:number,appKey:string,appSecret:string){
    return this.apiService.callApi('/EquipmentController/addEquipment',
      {
        deviceName:deviceName,
        deviceSerial:deviceSerial,
        validateCode:validateCode,
        functionId:functionId,
        appKey:appKey,
        appSecret:appSecret
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  deleteEquipment(id:number){
    return this.apiService.callApi('/EquipmentController/deleteEquipment',
      {
        id:id
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }


}
