import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fuctionService, weightService} from '../core/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {util} from '../Util';
declare const echarts: any;

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})

export class ShowComponent implements OnInit {
  public chart: any;
  public FunctionId = 1;
  public letBottomListOfData=[];
  public rightCenterListOfData=[];
  public CenterCenterBottomListOfData=[];
  public rightBottomListOfData=[];
  public obj:any;
  public pageIndex=1;
  public rightCenterPageIndex=1;
  public CenterPageIndex=1;
  public isOpenShow=false;
  public isVisible = true;
  public selectedValue = null;
  public functionValue = [];
  public functionId=0;
  public  WeatherImage="";
  public  weather:any;
  public  weatherFont="";
  public  temperature="";
  public  windPower="";
  public  functionname="";
  public time="";
  constructor(
    private weightinfoService: weightService,
    private fuction: fuctionService,
    private message: NzMessageService,
    private getparam: ActivatedRoute,
    private router: Router,
    private Util: util,
  ) {
  }

  option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      textStyle:{
        color:'#ffffff',
        fontStyle:'normal'
      },
      data: ['过磅总数','超载数','未超载']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '6%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel : {
        textStyle: {
          color: '#ffffff'
        }
      },
      data: []
    },
    yAxis: {
      splitLine: {
        show: true,
        lineStyle:{
          color: ['#aaa', '#ddd'],
          type:'dotted'
        }
      },
      axisLabel : {
        textStyle: {
          color: '#ffffff'
        }
      },
      type: 'value'
    },
    series: []
  };

   dataAxis = [];
   data = [];



  optionPillars = {
    tooltip: {},
    xAxis: {
      data: this.dataAxis,
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      }
    },
    yAxis: {
      axisLine: {
        show: true
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: '#999'
        }
      }
    },
    dataZoom: [
      {
        type: 'inside'
      }
    ],
    series: [
      {
        type: 'bar',
        itemStyle: {
          color: 'rgba(0,0,0,0.05)'
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        animation: false
      },
      {
        type: 'bar',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              {offset: 0, color: '#83bff6'},
              {offset: 0.5, color: '#188df0'},
              {offset: 1, color: '#188df0'}
            ]
          )
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                {offset: 0, color: '#2378f7'},
                {offset: 0.7, color: '#2378f7'},
                {offset: 1, color: '#83bff6'}
              ]
            )
          }
        },
        data: this.data
      }
    ]
  };

  closeOk(): void {
    this.isVisible=false;
  }


  handleOk(): void {
    if (this.functionId !=0){
      this.isVisible = false;
      this.isOpenShow=true;
      let  functions=this.functionId
      this.Weather(functions);
      this.leftTopDataEchart(functions);
      this.letBottom(functions);
      this.rightCenter(functions);
      this.centerBottomAndCenter(functions);
      this.rightBottomData(functions);
      this.findStatistical(functions);
      this.timeNow();
    }
  }

  ngOnInit(): void {
    this.obj={
      statistical:0,
      transfinite:0,
      count:0,
      rate:0
    };

    let that = this;
    this.getparam.params.subscribe(result => {
      if (result.functionId != undefined && result.functionId != 0 && result.functionId != null) {
        that.functionId = result.functionId;
      }
    });

    //获取功能ID -- 获取当前底下所有坐标
    this.fuction.findChiDreNOrg(that.functionId).subscribe(data=>{
      if (data.statusCode == 200) {
        let list=data.content;
        console.log(list)
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });

    //获取当前ID坐标
    this.fuction.findByIdFunction(that.functionId).subscribe(data=>{
      if (data.statusCode == 200) {
        let item=data.content;
        console.log(item);

      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });


    //获取当前ID 及坐标

    this.handleOk();
    setInterval(()=>{
      this.handleOk();
    },1200000);

  }


  valueRatio(count:number,transfinite:number){
    if (this.Util.isNull(count) && this.Util.isNull(transfinite)){
        return 0;
    }else {
      return (transfinite/count)*100;
    }
  }



  log(value:any): void {
    this.functionId=value;
  }




  Weather(id:number){
    this.weightinfoService.findWeather(id).subscribe(data=>{
      if (data.statusCode == 200) {
        this.weather=data.content.Weather.showapi_res_body.f1;
        let myDate = new Date();
       let  hours= myDate.getHours();
        this.WeatherImage=data.content.Weather.showapi_res_body.now.weather_pic;
        this.weatherFont=data.content.Weather.showapi_res_body.now.weather;
        this.temperature=data.content.Weather.showapi_res_body.now.temperature;
        this.windPower=data.content.Weather.showapi_res_body.now.wind_power;
        this.functionname=data.content.function.functionname;
        //明日天气
       // if ((hours>17 && hours<24) || (hours>0 && 5>hours)){
       //   this.WeatherImage=data.content.Weather.showapi_res_body.f1.night_weather_pic;
       // }else {
       //   this.WeatherImage=data.content.Weather.showapi_res_body.f1.day_weather_pic;
       // }



      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }







  leftTopDataEchart(id:number): void {
    // FunctionId=>查询过磅数据=>当前时间前一个月=》
    this.weightinfoService.findWeightBYRole(id).subscribe(data => {
      if (data.statusCode == 200) {
       let  list=data.content;

        let  time=list.map(item=>item.createTime.substring(5,item.createTime.length));
        let  count=list.map(item=>item.AllCount);
        let  overweight=list.map(item=>item.overweight);
        let  normal=list.map(item=>item.normal);
        this.dataAxis=time;
        this.data=overweight;
        this.PillarsReash();
        let obj=[
          {
            name: '过磅总数',
            type: 'line',
            data: count,
            itemStyle: {
              normal: {
                color: '#0088D4', //改变折线点的颜色
                lineStyle: {
                  color: '#0088D4' //改变折线颜色
                }
              }
            },
            smooth: true
          },
          {
            name: '超载数',
            type: 'line',
            data: overweight,
            itemStyle: {
              normal: {
                color: '#DB3233', //改变折线点的颜色
                lineStyle: {
                  color: '#DB3233' //改变折线颜色
                }
              }
            },
            smooth: true
          },
          {
            name: '未超载',
            type: 'line',

            data: normal,
            itemStyle: {
              normal: {
                color: '#89BD1B', //改变折线点的颜色
                lineStyle: {
                  color: '#89BD1B' //改变折线颜色
                }
              }
            },
            smooth: true
          }
        ];
        this.option={
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            textStyle:{
              color:'#ffffff',
              fontStyle:'normal'
            },
            data: ['过磅总数','超载数','未超载']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '6%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel : {
              textStyle: {

                color: '#ffffff'
              }
            },
            data: time
          },
          yAxis: {
            splitLine: {
              show: true,
              lineStyle:{
                color: ['#aaa', '#ddd'],
                type:'dotted'
              }
            },
            axisLabel : {
              textStyle: {
                color: '#ffffff'
              }
            },
            type: 'value'
          },
          series: obj
        }
      } else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }





  PillarsReash():void{
    this.optionPillars = {
      tooltip: {},
      xAxis: {
        data: this.dataAxis,
        axisLabel: {
          textStyle: {
            color: '#fff'
          }
        }
      },
      yAxis: {
        axisLine: {
          show: true
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          textStyle: {
            color: '#999'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside'
        }
      ],
      series: [
        {
          type: 'bar',
          itemStyle: {
            color: 'rgba(0,0,0,0.05)'
          },
          barGap: '-100%',
          barCategoryGap: '40%',
          animation: false
        },
        {
          type: 'bar',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                {offset: 0, color: '#83bff6'},
                {offset: 0.5, color: '#188df0'},
                {offset: 1, color: '#188df0'}
              ]
            )
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#2378f7'},
                  {offset: 0.7, color: '#2378f7'},
                  {offset: 1, color: '#83bff6'}
                ]
              )
            }
          },
          data: this.data
        }
      ]
    };
  }


  letBottom(id:number):void{
    this.weightinfoService.findWeightRate(id).subscribe(data=>{

      if (data.statusCode == 200) {
      let  list=data.content;
       this.letBottomListOfData=list;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

  rightBottomData(id:number): void {
    this.weightinfoService.findImageBYRoleIdAndToday(id).subscribe(data=>{
      if (data.statusCode == 200) {
        let  list=data.content;
        this.rightBottomListOfData=list;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }


  rightCenter(id:number):void{
    this.weightinfoService.findWeiGhtRateToday(id).subscribe(data=>{
      if (data.statusCode == 200) {
        let  list=data.content;
        this.rightCenterListOfData=list;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }
  findStatistical(id:number):void{
    this.weightinfoService.findStatistical(id).subscribe(data=>{
      if (data.statusCode == 200) {
        let  list=data.content;
        this.obj=list;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

  findWeather():void{
    this.weightinfoService.findWeather(0).subscribe(data=>{
      if (data.statusCode == 200) {
        let  list=data.content;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }

  centerBottomAndCenter(id:number):void{
    this.weightinfoService.findWeightBYRoleIdAndToday(id).subscribe(data=>{
      if (data.statusCode == 200) {
        let  list=data.content;
        this.CenterCenterBottomListOfData=list;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
  }


  timeNow():void{
    setInterval(()=>{
      let myDate = new Date();
    let  thatTime=myDate.getFullYear()+'年'+(myDate.getMonth()+1)+'月'+ myDate.getDate()+'日'+myDate.getHours()+'时'+myDate.getMinutes()+'分'+myDate.getSeconds()+'秒';
    this.time=thatTime;
    },1000);
  }

  ngAfterViewInit(): void {
    let  that=this;
    this.getparam.params.subscribe(result => {
      if (result.functionId != undefined && result.functionId != 0 && result.functionId != null) {
        that.functionId = result.functionId;
        this.handleOk();
      }
    });


    setInterval(()=>{
      let count=Math.ceil(that.letBottomListOfData.length / 3)
      let rightCenterCount=Math.ceil(that.rightCenterListOfData.length / 4)
      let centerCenterBottomCount=Math.ceil(that.CenterCenterBottomListOfData.length / 10)
      if (that.pageIndex>count){
        that.pageIndex=1;
      }else {
        if (that.pageIndex==count){
          that.pageIndex=1;
        }else {
        that.pageIndex=++that.pageIndex;
        }
      }

      if (that.rightCenterPageIndex>rightCenterCount){
        that.rightCenterPageIndex=1;
      }else {
        if (that.rightCenterPageIndex==rightCenterCount){
          that.rightCenterPageIndex=1;
        }else {
          that.rightCenterPageIndex=++that.rightCenterPageIndex;
        }
      }

      if (that.CenterPageIndex>centerCenterBottomCount){
        that.CenterPageIndex=1;
      }else {
        if (that.CenterPageIndex==centerCenterBottomCount){
          that.CenterPageIndex=1;
        }else {
          that.CenterPageIndex=++that.CenterPageIndex;
        }
      }
    },120000);


  }



}
