import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class usersService {
  constructor(
    private apiService: ApiService
  ) { }

  loginUsers(username:string,password:string,status:number){
    return this.apiService.callApi('/userController/login',
      {
        username:username,
        password:password,
        status:status
      }).pipe(
      map(data => {
        return data;
      }
    ));

  }


  findFunction(useraccount:string){
    return this.apiService.callApi('/fuctionController/findFunction',
      {
        useraccount:useraccount,
      }).pipe(
      map(data => {
          return data;
        }
      ));

  }



  checklogin(token:string,account:string){
    return this.apiService.callApi('/userController/checklogin',
      {
        token:token,
        account:account
      }).pipe(
      map(data => {
          return data;
        }
      ));

  }

  DeleteLogin(token:string){
    return this.apiService.callApi('/userController/DeleteLogin',
      {
        token:token,
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  sendLogin(iPhone:string){
    return this.apiService.callApi('/userController/RegisterLogin',
      {
        iPhone:iPhone,
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  SaveRegister(username:string,passwordd:string,idcard:string,phone:string,email:string,code:string,company:number,registrationcode:string){
  return this.apiService.callApi('/userController/SaveRegister',
    {
      username:username,
      passwordd:passwordd,
      idcard:idcard,
      phone:phone,
      email:email,
      code:code,
      companykey:company,
      registrationcode:registrationcode
    }
    ).pipe(
    map(data => {
        return data;
      }
    ));
}

  findAccount(account:string){
    return this.apiService.callApi('/userController/findAccount',
      {
        account:account,
      }
    ).pipe(
      map(data => {
          return data;
        }
      ));
  }


  findUsers(){
    return this.apiService.callApi('/userController/findUsers').pipe(
      map(data => {
          return data;
        }
      ));
  }

  findUserByUserId(id:number){
    return this.apiService.callApi('/userController/findUserByUserId',
      {
        id:id
      }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findRole(){
    return this.apiService.callApi('/userController/findRole').pipe(
      map(data => {
          return data;
        }
      ));
  }

  SaveUserRole(userId:number,roleId:number){
    return this.apiService.callApi('/userController/SaveUserRole',{
      userId:userId,
      roleId:roleId
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  SaveUserTime(userId:number,time:string){
    return this.apiService.callApi('/userController/SaveUserTime',{
      userId:userId,
      time:time
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }
  SaveUserPass(userId:number,pass:string){
    return this.apiService.callApi('/userController/SaveUserPass',{
      userId:userId,
      pass:pass
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }
  SaveUsermess(userId:number,age:number,username:string){
    return this.apiService.callApi('/userController/SaveUsermess',{
      userId:userId,
      age:age,
      username:username
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  updateRole(id:number,management:boolean){
    return this.apiService.callApi('/userController/updateRole',{
      id:id,
      management: management
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findUserByUserInformation(){
    return this.apiService.callApi('/userController/findUserByUserInformation').pipe(
      map(data => {
          return data;
        }
      ));
  }

  SaveRole(rolename:string,levelid:string,management:boolean){
    return this.apiService.callApi('/userController/SaveRole',{
      rolename:rolename,
      levelid:levelid,
      management:management
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  RetrieveUsers(userName:string,status:number){
    return this.apiService.callApi('/userController/RetrieveUsers',{
      userName:userName,
      status:status
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }


  checkCodeValue(userName:string,code:string){
    return this.apiService.callApi('/userController/checkCodeValue',{
      userName:userName,
      code:code
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }


  upUserPassword(userName:string,passWord:string,status:number){
    return this.apiService.callApi('/userController/upUserPassword',{
      userName:userName,
      passWord:passWord,
      status:status
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }



}
