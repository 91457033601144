<div nz-row class="body">
  <div ng-zorro nz-col [nzSpan]="24">
    <!--上 -->
    <div nz-row class="top">
      <div ng-zorro nz-col [nzSpan]="24"><span class="headSpan">源头治超实时监管平台</span></div>
    </div>
    <!-- 下-->
    <div nz-row class="bottom">
      <!-- 下左 -->
      <div ng-zorro nz-col [nzSpan]="6">
        <div nz-row class="left-top">
          <div ng-zorro nz-col [nzSpan]="24">
            <div nz-row>
              <div ng-zorro nz-col [nzSpan]="24">
                <span class="tile-span">月走势图</span>
                <img class="tile-head" src="http://zcs.hengqibao.com/heighttitle.png">
              </div>
            </div>
            <div nz-row style="height: 90%;">
              <div ng-zorro nz-col [nzSpan]="24" #myCharts echarts [options]="option"></div>
            </div>
          </div>
        </div>
        <div nz-row class="left-center">
          <div ng-zorro nz-col [nzSpan]="24">
            <div nz-row>
              <div ng-zorro nz-col [nzSpan]="24">
                <span class="tile-span">月超限统计</span>
                <img class="tile-head" src="http://zcs.hengqibao.com/heighttitle.png">
              </div>
            </div>
            <div nz-row style="height: 90%;">
              <div ng-zorro nz-col [nzSpan]="24" id="container" echarts [options]="optionPillars"></div>
            </div>
          </div>
        </div>
        <div nz-row class="left-bottom">
          <div ng-zorro nz-col [nzSpan]="24">
            <div nz-row>
              <div ng-zorro nz-col [nzSpan]="24">
                <span class="tile-span">月超限排行</span>
                <img class="tile-head" src="http://zcs.hengqibao.com/heighttitle.png">
              </div>
            </div>
            <div nz-row class="left-bottom-tables">
              <nz-table #basicTable [nzData]="letBottomListOfData" nzSize="default" class="left-bottom-table"
                        [nzShowPagination]="false" [nzPageSize]="3" [nzPageIndex]="pageIndex">
                <thead>
                <tr>
                  <th>站点</th>
                  <th>过磅数</th>
                  <th>超限数</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of basicTable.data">
                  <td><i nz-icon nzType="home" nzTheme="twotone"
                         [nzTwotoneColor]="'#46daff'"></i> {{ data.functionname }}</td>
                  <td>{{ data.total }}</td>
                  <td>{{ data.overload }}({{ data.rate }}%)</td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
      <!-- 下中 -->
      <div ng-zorro nz-col [nzSpan]="12">
        <div nz-row class="center-top">
          <div ng-zorro nz-col [nzSpan]="5" class="center-top-smil">
            <h3 nz-typography>站点总数</h3>
            <p>{{obj.statistical}}</p>
          </div>
          <div ng-zorro nz-col [nzSpan]="7" class="center-top-long">
            <h3 nz-typography>今日检超限数</h3>
            <p>{{obj.transfinite}}</p>
          </div>
          <div ng-zorro nz-col [nzSpan]="5" class="center-top-smil">
            <h3 nz-typography>今日检测数</h3>
            <p>{{obj.count}}</p>
          </div>
          <div ng-zorro nz-col [nzSpan]="7" class="center-top-long">
            <h3 nz-typography>今日检超限率</h3>
            <p>{{valueRatio(obj.count,obj.transfinite)}}%</p>
          </div>
        </div>
        <div nz-row class="center-center">
          <div ng-zorro nz-col [nzSpan]="24">
            <nz-table #basicTableCenter [nzData]="CenterCenterBottomListOfData" nzSize="default"
                      [nzShowPagination]="false" [nzPageSize]="10" [nzPageIndex]="CenterPageIndex" class="center-table">
              <thead>
              <tr>
                <th>站点</th>
                <th>车号</th>
                <th>限重</th>
                <th>实重</th>
                <th>超限率</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let data of basicTableCenter.data">
                <td *ngIf="data.trueweight/data.vehicle <1"><i nz-icon nzType="home" nzTheme="twotone"
                                                               [nzTwotoneColor]="'#46daff'"></i> {{ data.functionname }}
                </td>
                <td *ngIf="data.trueweight/data.vehicle <1">{{ data.carid }}</td>
                <td *ngIf="data.trueweight/data.vehicle <1">{{ data.vehicle }}</td>
                <td *ngIf="data.trueweight/data.vehicle <1">{{ data.trueweight }}</td>
                <td
                  *ngIf="data.trueweight/data.vehicle <1; else reateTrue">{{ data.trueweight / data.vehicle > 1 ? ((data.trueweight / data.vehicle) - 1) * 100 : 0}}
                  %
                </td>

                <ng-template #reateTrue style="background-color: red;text-align: center">
                  <td style="background-color: red;color: white;"><i nz-icon nzType="home" nzTheme="twotone"
                                                                     [nzTwotoneColor]="'#46daff'"></i> {{ data.functionname }}
                  </td>
                  <td style="background-color: red;color: white;">{{ data.carid }}</td>
                  <td style="background-color: red;">{{ data.vehicle }}</td>
                  <td style="background-color: red;color: white;">{{ data.trueweight }}</td>
                  <td style="background-color: red;color: white;">
                    {{ data.trueweight / data.vehicle > 1 ? ((data.trueweight / data.vehicle) - 1)>=1 ? 100 :((data.trueweight / data.vehicle) - 1) * 100 : 0}}%
                  </td>
                </ng-template>
              </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>
      <!-- 下右 -->
      <div ng-zorro nz-col [nzSpan]="6">
        <div nz-row class="right-top">
          <div ng-zorro nz-col [nzSpan]="24">
            <div nz-row>
              <div ng-zorro nz-col [nzSpan]="24">
                <span class="tile-span">地区情势情况</span>
                <img class="tile-head" src="http://zcs.hengqibao.com/heighttitle.png">
              </div>
            </div>
            <div nz-row style="height: 80%">
              <div ng-zorro nz-col [nzSpan]="24">
                <div nz-row>
                  <div ng-zorro nz-col [nzSpan]="24">
                    <h2 nz-typography style="color: white;text-align: center;">{{functionname}}</h2>
                  </div>
                </div>
                <div nz-row>
                  <div ng-zorro nz-col [nzSpan]="12" style="height: 30%;">
                    <img src="{{WeatherImage}}" style="height: 60%;width: 60%;margin-top: -10%;">
                  </div>

                  <div ng-zorro nz-col [nzSpan]="12" style="height: 30%;">
                    <div>
                      <span style="color: white;font-size: 20px;">天气:{{weatherFont}}</span>
                    </div>
                    <div>
                      <span style="color: white;font-size: 20px;">温度:{{temperature}}℃</span>
                    </div>
                    <div>
                      <span style="color: white;font-size: 20px;">风力:{{windPower}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-row>
                <div ng-zorro nz-col [nzSpan]="24">
                  <h3  nz-typography  style="color: white;text-align: center;padding: 0% 14%">{{time}}</h3>
                </div>
              </div>

              <div class="icon sunny" *ngIf="false">
                <div class="sun">
                  <div class="rays"></div>
                </div>
              </div>

              <div class="icon rainy" *ngIf="false">
                <div class="cloud"></div>
                <div class="rain"></div>
              </div>

              <div class="icon cloudy" *ngIf="false">
                <div class="cloud"></div>
                <div class="cloud"></div>
              </div>

              <div class="icon flurries" *ngIf="false">
                <div class="cloud"></div>
                <div class="snow">
                  <div class="flake"></div>
                  <div class="flake"></div>
                </div>
              </div>


              <div class="icon thunder-storm" *ngIf="false">
                <div class="cloud"></div>
                <div class="lightning">
                  <div class="bolt"></div>
                  <div class="bolt"></div>
                </div>
              </div>


              <div class="icon sun-shower" *ngIf="false">
                <div class="cloud"></div>
                <div class="sun">
                  <div class="rays"></div>
                </div>
                <div class="rain"></div>
              </div>


            </div>
          </div>
        </div>
        <div nz-row class="right-center">
          <div ng-zorro nz-col [nzSpan]="24">
            <div nz-row>
              <div ng-zorro nz-col [nzSpan]="24">
                <span class="tile-span">当日站点排行</span>
                <img class="tile-head" src="http://zcs.hengqibao.com/heighttitle.png">
              </div>
            </div>
            <div nz-row class="left-bottom-tables">
              <nz-table #basicTable2 [nzData]="rightCenterListOfData" nzSize="default" class="left-bottom-table"
                        [nzShowPagination]="false" [nzPageSize]="4" [nzPageIndex]="rightCenterPageIndex">
                <thead>
                <tr>
                  <th>站点</th>
                  <th>过磅数</th>
                  <th>超限数</th>
                  <th>超限率</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of basicTable2.data">
                  <td><i nz-icon nzType="home" nzTheme="twotone"
                         [nzTwotoneColor]="'#46daff'"></i> {{ data.functionname }}</td>
                  <td>{{ data.total }}</td>
                  <td>{{ data.overload }}</td>
                  <td>{{ data.rate }}%</td>
                </tr>
                </tbody>
              </nz-table>

            </div>
          </div>
        </div>
        <div nz-row class="right-bottom">
          <div ng-zorro nz-col [nzSpan]="24" style="height: 100%">
            <div nz-row>
              <div ng-zorro nz-col [nzSpan]="24">
                <span class="tile-span">抓拍超限</span>
                <img class="tile-head" src="http://zcs.hengqibao.com/heighttitle.png">
              </div>
            </div>
            <div nz-row style="height: 87%">
              <div ng-zorro nz-col [nzSpan]="24">
                <nz-carousel nzDotPosition="bottom" nzAutoPlay nzAutoPlaySpeed="40000">
                  <div nz-carousel-content *ngFor="let data of rightBottomListOfData">
                    <img height="90%" width="90%" style="margin: 0 auto;"
                         src="https://jcup-images-01.oss-cn-shanghai.aliyuncs.com{{data.imageurl }}"/>
                    <div style="position: absolute;color: red;top: 0px;right: 5%;font-size: 1.3rem;font-weight: bold;">
                      <p>{{data.functionname}}</p>
                      <p>{{data.carid}}</p>
                      <p>{{data.weightCreateTime}}</p>
                      <p>{{data.rate}}</p>
                    </div>
                  </div>
                </nz-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

