import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NzDrawerPlacement, NzFormatEmitEvent, NzTreeNodeOptions} from 'ng-zorro-antd';
import {fileOssService, TokenService, usersService, videosService, weightService} from '../core/services';
import {images, weight} from '../core/models';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import endOfMonth from 'date-fns/endOfMonth';
import {imagesService} from '../core/services/images.service';
import {videos} from '../core/models/videos.member.model';
import {NzMessageService} from 'ng-zorro-antd/message';
import {util} from '../Util';


@Component({
  selector: 'app-geographic-data',
  templateUrl: './geographic-data.component.html',
  styleUrls: ['./geographic-data.component.css']
})
export class GeographicDataComponent implements OnInit {

  editCache: { [key: string]: { edit: boolean; data: weight } } = {};
  listOfData: weight[] = [];
  images:images[]=[];
  videos:videos[]=[];
  urlList: any[] = [];
  public scrollY = '760px';
  public Total = 0;
  public pagesize = 10;
  public pagenum = 1;
  visible = false;
  visible2 = false;
  placement: NzDrawerPlacement = 'top';
  nzPlacement: NzDrawerPlacement = 'bottom';
  public validateForm!: FormGroup;
  public searchValue = '';
  public carIdseach = '';
  public leftoverheight = '10000px';
  public linkfunctionId=0;
  public functionid=0;
   nzLoading=false;
  ShowMessage=false;
  divewheight = 0;
  nzTitle="";
   nzCard:any=[];
  ranges1 = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  ranges2 = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  ranges3 = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  ranges4 = { Today: [new Date(), new Date()], 'This Month': [new Date(), endOfMonth(new Date())] };
  @ViewChild('body') elementView: ElementRef;
  @ViewChild('editRowTable') editRowTable: ElementRef;
  public imageId = null;
  public fileType = 'image';
  public previewImage = '';
  public previewVisible = false;

  startEdit(id: string): void {

    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: { ...this.listOfData[index] },
      edit: false
    };
  }

  saveEdit(id: string): void {
    const index = this.listOfData.findIndex(item => item.id === id);
    Object.assign(this.
      listOfData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: { ...item }
      };
    });
  }


  @ViewChild('leftheight') leftheight: ElementRef;
  nodes: NzTreeNodeOptions[] = [];


  constructor(
    private tokenService: TokenService,
    private usersService: usersService,
    private weightinfoService:weightService,
    private imagesservice:imagesService,
    private videosService:videosService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private getparam:ActivatedRoute,
    private router: Router,
    private util: util,
    private fileOssService: fileOssService
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let isnull = this.leftheight.nativeElement.offsetParent;
      if (isnull != null && isnull != undefined && isnull != 0) {
        let height = this.leftheight.nativeElement.offsetParent.clientHeight;
        let worm = height / 100;

        let x = Math.round(worm * 93);
        this.leftoverheight = x + 'px';


        let height2 = this.elementView.nativeElement.offsetParent.clientHeight;
        //  let head = this.elementhead.nativeElement.clientHeight
        let dij = height2 / 10;
        // let hedas = height2 - dij - (dij / 2) - head;
        let hedas = height2 - dij - (dij / 2);
        this.scrollY = hedas + 'px';

      }
    });
  }

  ngOnDestroy(){
    let isnull = this.leftheight.nativeElement.offsetParent;
    if (isnull != null && isnull != undefined && isnull != 0) {
      let height = this.leftheight.nativeElement.offsetParent.clientHeight;
      let worm = height / 100;

      let x = Math.round(worm * 93);
      this.leftoverheight = x + 'px';


      let height2 = this.elementView.nativeElement.offsetParent.clientHeight;
      //  let head = this.elementhead.nativeElement.clientHeight
      let dij = height2 / 10;
      // let hedas = height2 - dij - (dij / 2) - head;
      let hedas = height2 - dij - (dij / 2);
      this.scrollY = hedas + 'px';

    }
  }

  ngOnInit(): void {
    let  that=this;
    this.getparam.params.subscribe(result=>{
      if (result.linkfunctionId !=undefined && result.linkfunctionId !=0 && result.linkfunctionId !=null)
        that.linkfunctionId=result.linkfunctionId;
    })

    this.validateForm = this.fb.group({});
    this.addform();
    let accoun = this.tokenService.getAccoun();
    this.usersService.findFunction(accoun).subscribe((data) => {
      //成功
      if (data.statusCode==200){
        this.nodes=this.forArrayXzTree(data.content);
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
    if (this.linkfunctionId !=0){

      let id=this.linkfunctionId;
      let accoun = this.tokenService.getAccoun();
      this.weightinfoService.findFunctioMapresult(this.pagesize,this.pagenum,id,accoun).subscribe((data) => {
        //成功
        if (data.statusCode==200){
          this.listOfData=data.content.list;

          this.listOfData.forEach(item => {
            this.editCache[item.id] = {
              edit: false,
              data: { ...item }
            };
          });

          this.Total=data.content.total;
          this.pagenum=data.content.pageNum;
        }else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    }



  }


  forArrayXzTreemou(obj:any,obj2:number){
    let  datasnodes=[];
    let array=JSON.parse(JSON.stringify(obj));
    for (let i=0;i<obj.length;i++) {
      if (obj[i] != null) {
      if (obj[i].functionid==obj2) {
        let father: NzTreeNodeOptions = {
          title: obj[i].functionname,
          key: obj[i],
          children: [],
          icon:'apartment',
          isLeaf: false
        };
        let thisid=obj[i].id;
        array.splice(i,1);
        let  das=this.forArrayXzTreemou(array,thisid);
        if (das.length>0){
          father.children=das;
          father.icon='apartment';
        }else {
          father.isLeaf = true;
          father.icon='home';
        }
        datasnodes.push(father);
      }
    }
    }
    return datasnodes;
  }

  forArrayXzTree(obj:any){
    let array=JSON.parse(JSON.stringify(obj));
    //开始遍历大层遍历
    let datasnodes=[];
    for (let i=0;i<obj.length;i++){
      if (obj[i] != null) {
      if (obj[i].functionid==null || obj[i].functionid==undefined || obj[i].functionid==0){
        if (obj[i].functionname !=null && obj[i].functionname !=undefined){
        let father: NzTreeNodeOptions = {
          title: obj[i].functionname,
          key: obj[i],
          icon:'apartment',
          children: [],
          isLeaf: false
        };
        //删除了一条数据
        let thisid=obj[i].id;
        let  das=this.forArrayXzTreemou(array,thisid);
        array.splice(i, 1);
        if (das.length>0){
          father.children=das;
        }else {
          father.isLeaf = true;
        }
        datasnodes.push(father);

        }

      }
      }
    }
return datasnodes;
  }

  /**
   * 点击区域文字
   * @param event
   */
  nzEvent(event: NzFormatEmitEvent): void {
      let functionid=event.node.key['id'];
       if (event.node.key['isfunction']==false){


      this.functionid=functionid;
      let accoun = this.tokenService.getAccoun();
      this.weightinfoService.findFunctioMapresult(this.pagesize,this.pagenum,functionid,accoun).subscribe((data) => {
        //成功
        if (data.statusCode==200){
          this.listOfData=data.content.list;

          this.listOfData.forEach(item => {
            this.editCache[item.id] = {
              edit: false,
              data: { ...item }
            };
          });

          this.Total=data.content.total;
          this.pagenum=data.content.pageNum;
        }else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(["/login"]);
          } else {
            this.message.create('error', data.content);
          }
        }
      });
    }

  }

  amountChang(trueweight:number,vehicle:number){

    if (trueweight != null) {
      if (vehicle != null) {
        let abc =0;
        if (vehicle<trueweight){
          abc = trueweight-vehicle;
        }
        return abc;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }


  dataBase(data:any){

    this.nzTitle='车牌号：'+data.carId
    this.nzCard=data;
    this.nzVisibleOpen();
    this.urlList=[];
    this.imagesservice.findImagesByweightId(data.id).subscribe((data)=>{
      if (data.statusCode==200){
        this.images=data.content;
      }else {
        alert(data.messages);
      }
    });

    this.videosService.findvodeos(data.id).subscribe((data)=>{
      if (data.statusCode==200){
        this.videos=data.content;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    })
    let weightsId=data.id;
    if (weightsId != null) {
      this.fileOssService.findWeightByIdImageORVideo(weightsId).subscribe(data => {
        if (data.statusCode == 200) {
          let map = data.content;
          if (map.Images.length > 0) {
            let Images = map.Images;
            Images.forEach(data => {
              this.urlList.push({
                fileUrl: data.imageurl,
                fileType: 'image',
                id: this.getUuid()
              });
            });
          }
          if (map.Videos.length > 0) {
            let Videos = map.Videos;
            Videos.forEach(data => {
              this.urlList.push({
                fileUrl: data.videoUrl,
                fileType: 'video',
                id: this.getUuid()
              });
            });
          }

        } else {
          if (data.content.code == 40002) {
            this.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            this.message.create('error', data.content.subMsg);
            this.router.navigate(['/login']);
          } else {
            this.message.create('error', data.content);
          }
        }

      });
    }
  }

  imageClick(id: string): void {
    let electronics: any = this.urlList.filter(item => {
      if (item.id == id) {
        return item;
      }
    });
    if (electronics.length > 0) {
      this.imageId = electronics[0].id;
      this.fileType = electronics[0].fileType;
      this.previewImage = 'https://jcup-images-01.oss-cn-shanghai.aliyuncs.com' + electronics[0].fileUrl;
    } else {
      this.imageId = null;
      this.fileType = null;
      this.previewImage = null;
    }

    this.previewVisible = true;
  }

  clickLeft() {
    let id = this.imageId;
    let value = this.urlList;
    let nextLeftValue: any;
    for (let i = 0; value.length > i; i++) {
      let data = value[i];
      if (data.id == id) {
        if (i == 0) {
          nextLeftValue = value[value.length - 1];
          break;
        } else {
          nextLeftValue = value[i - 1];
          break;
        }
      }
    }
    this.previewImage = 'https://jcup-images-01.oss-cn-shanghai.aliyuncs.com' + nextLeftValue.fileUrl;
    this.imageId = nextLeftValue.id;
    this.fileType = nextLeftValue.fileType;
  }

  clickRight() {
    let id = this.imageId;
    let value = this.urlList;
    let nextLeftValue: any;
    for (let i = 0; value.length > i; i++) {
      let data = value[i];
      if (i + 1 >= value.length) {
        nextLeftValue = value[0];
        break;
      }
      if (data.id == id) {
        nextLeftValue = value[i + 1];
        break;
      }
    }
    this.previewImage = 'https://jcup-images-01.oss-cn-shanghai.aliyuncs.com' + nextLeftValue.fileUrl;
    this.imageId = nextLeftValue.id;
    this.fileType = nextLeftValue.fileType;
  }




  getUuid() {
    let s = [];
    let hexDigits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    let uuid = s.join('');
    return uuid;
  }

  timeChange(value: string) {
    return this.util.formatTime(new Date(value));
  }


  /**
   * 页数改变
   * @param event
   */
  changepage(event) {
    this.nzLoading=true;
    this.pagenum = event;
    let functionid=this.functionid;
    let accoun = this.tokenService.getAccoun();
    let  data=this.formDatajson();
    this.weightinfoService.findFunctioMapresult2(this.pagesize,this.pagenum,functionid,accoun,
      this.datavalunfind(data.id),
      data.serialNumber,
      data.carId,
      this.datavalunfind(data.zfXs),
      this.datavalunfind(data.fl),
      this.datavalunfind(data.decWeight),
      this.datavalunfind(data.grossWeight),
      this.datavalunfind(data.actualTare),
      this.datavalunfind(data.netWeight),
      this.datavalunfind(data.trueWeight),
      this.datavalunfind(data.unitPrice),
      this.datavalunfind(data.weighingFee),
      data.mzWeighMan,
      data.pzWeighMan,
      this.datavalunfind(data.theMoney),
      data.createTime,
      data.createTimeEnd,
      data.updateTime,
      data.updateTimeEnd,
      data.weighType,
      data.weighTime,
      data.weighTimeEnd,
      data.weightWoTime,
      data.weightWoTimeEnd
      ).subscribe((data) => {
      //成功
      if (data.statusCode==200){
        this.listOfData=data.content.list

        this.listOfData.forEach(item => {
          this.editCache[item.id] = {
            edit: false,
            data: { ...item }
          };
        });

        this.Total=data.content.total;
        this.pagenum=data.content.pageNum;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
    this.nzLoading=false;
  }

  /**
   * 当前页数
   * @param event
   */
  changePageSize(event) {
    this.nzLoading=true;
    this.pagesize = event;
    let functionid=this.functionid;
    let accoun = this.tokenService.getAccoun();
    let  data=this.formDatajson();
    this.weightinfoService.findFunctioMapresult2(this.pagesize,this.pagenum,functionid,accoun,
      this.datavalunfind(data.id),
      data.serialNumber,
      data.carId,
      this.datavalunfind(data.zfXs),
      this.datavalunfind(data.fl),
      this.datavalunfind(data.decWeight),
      this.datavalunfind(data.grossWeight),
      this.datavalunfind(data.actualTare),
      this.datavalunfind(data.netWeight),
      this.datavalunfind(data.trueWeight),
      this.datavalunfind(data.unitPrice),
      this.datavalunfind(data.weighingFee),
      data.mzWeighMan,
      data.pzWeighMan,
      this.datavalunfind(data.theMoney),
      data.createTime,
      data.createTimeEnd,
      data.updateTime,
      data.updateTimeEnd,
      data.weighType,
      data.weighTime,
      data.weighTimeEnd,
      data.weightWoTime,
      data.weightWoTimeEnd
    ).subscribe((data) => {
      //成功
      if (data.statusCode==200){
        this.listOfData=data.content.list

        this.listOfData.forEach(item => {
          this.editCache[item.id] = {
            edit: false,
            data: { ...item }
          };
        });

        this.Total=data.content.total;
        this.pagenum=data.content.pageNum;
       //window.location.reload();
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
    this.nzLoading=false;
  }
  reset(): void {
    this.carIdseach = '';
      this.search();
    }

    //查询车号
  search(): void {

    //  this.visible = true;

    let accoun = this.tokenService.getAccoun();
    this.nzLoading=true;
   let  data=this.formDatajson();
    this.weightinfoService.findFunctioMapresult2(
      this.pagesize,
      1,
      this.functionid,
      accoun,
      this.datavalunfind(data.id),
      data.serialNumber,
      data.carId,
      this.datavalunfind(data.zfXs),
      this.datavalunfind(data.fl),
      this.datavalunfind(data.decWeight),
      this.datavalunfind(data.grossWeight),
      this.datavalunfind(data.actualTare),
      this.datavalunfind(data.netWeight),
      this.datavalunfind(data.trueWeight),
      this.datavalunfind(data.unitPrice),
      this.datavalunfind(data.weighingFee),
      data.mzWeighMan,
      data.pzWeighMan,
      this.datavalunfind(data.theMoney),
      data.createTime,
      data.createTimeEnd,
      data.updateTime,
      data.updateTimeEnd,
      data.weighType,
      data.weighTime,
      data.weighTimeEnd,
      data.weightWoTime,
      data.weightWoTimeEnd
      ).subscribe((data) => {
      //成功
      if (data.statusCode==200){
        this.listOfData=data.content.list

        this.listOfData.forEach(item => {
          this.editCache[item.id] = {
            edit: false,
            data: { ...item }
          };
        });

        this.Total=data.content.total;
        this.pagenum=data.content.pageNum;
      }else {
        if (data.content.code == 40002) {
          this.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          this.message.create('error', data.content.subMsg);
          this.router.navigate(["/login"]);
        } else {
          this.message.create('error', data.content);
        }
      }
    });
    this.nzLoading=false;
  //  this.listOfDisplayData = this.listOfData.filter((item: DataItem) => item.name.indexOf(this.searchValue) !== -1);
  }

  /**
   * js日期格式转换字符
   */

  formDatajson(){
    let  id=this.validateForm.controls.id.value;
    let  serialNumber=this.validateForm.controls.serialNumber.value;
    let  carId=this.validateForm.controls.carId.value;
    let  zfXs=this.validateForm.controls.zfXs.value;
    let  fl=this.validateForm.controls.fl.value;
    let  decWeight=this.validateForm.controls.decWeight.value;
    let  grossWeight=this.validateForm.controls.grossWeight.value;
    let  actualTare=this.validateForm.controls.actualTare.value;
    let  netWeight=this.validateForm.controls.netWeight.value;
    let  trueWeight=this.validateForm.controls.trueWeight.value;
    let  unitPrice=this.validateForm.controls.unitPrice.value;
    let  weighingFee=this.validateForm.controls.weighingFee.value;
    let  mzWeighMan=this.validateForm.controls.mzWeighMan.value;
    let  pzWeighMan=this.validateForm.controls.pzWeighMan.value;
    let  theMoney=this.validateForm.controls.theMoney.value;
    let  createTime=this.validateForm.controls.createTime.value;
    let  updateTime=this.validateForm.controls.updateTime.value;
    let  weighType=this.validateForm.controls.weighType.value;
    let  weighTime=this.validateForm.controls.weighTime.value;
    let  weightWoTime=this.validateForm.controls.weightWoTime.value;
    var createTimeStrat=null;
    var createTimeEnd=null;
    var updateTimeStrat=null;
    var updateTimeEnd=null;
    var weighTimeStrat=null;
    var weighTimeEnd=null;
    var weightWoTimeStrat=null;
    var weightWoTimeEnd=null;
    if (!this.isNull(createTime[0])){
      createTimeStrat=this.parseStr(createTime[0]);
    }
    if (!this.isNull(createTime[1])){
      createTimeEnd=this.parseStr(createTime[1]);
    }
    if (!this.isNull(updateTime[0])){
      updateTimeStrat=this.parseStr(updateTime[0]);
    }
    if (!this.isNull(updateTime[1])){
      updateTimeEnd=this.parseStr(updateTime[1]);
    }
    if (!this.isNull(weighTime[0])){
      weighTimeStrat=this.parseStr(weighTime[0]);
    }
    if (!this.isNull(weighTime[1])){
      weighTimeEnd=this.parseStr(weighTime[1]);
    }
    if (!this.isNull(weightWoTime[0])){
      weightWoTimeStrat=this.parseStr(weightWoTime[0]);
    }
    if (!this.isNull(weightWoTime[1])){
      weightWoTimeEnd=this.parseStr(weightWoTime[1]);
    }

    let data={
      id:id,
      serialNumber:serialNumber,
      carId:carId,
      weighType:weighType,
      grossWeight:grossWeight,
      actualTare:actualTare,
      netWeight:netWeight,
      decWeight:decWeight,
      trueWeight:trueWeight,
      unitPrice:unitPrice,
      theMoney:theMoney,
      zfXs:zfXs,
      fl:fl,
      weighingFee:weighingFee,
      mzWeighMan:mzWeighMan,
      pzWeighMan:pzWeighMan,
      weighTime:weighTimeStrat,
      weighTimeEnd:weighTimeEnd,
      createTime:createTimeStrat,
      createTimeEnd:createTimeEnd,
      updateTime:updateTimeStrat,
      updateTimeEnd:updateTimeEnd,
      weightWoTime:weightWoTimeStrat,
      weightWoTimeEnd:weightWoTimeEnd
    }
    return data;
  }
   isNull(obj:any){
    if (obj==null || obj==undefined || obj=='' || obj.length==0 || obj.size==0){
      return true;
    }else {
      return false;
    }

  }

  datavalunfind(obj:any){
   if (this.isNull(obj)){
     obj=0
   }
   return obj;
  }


  parseStr(jsDtae:string){
    let date = new Date(jsDtae)
    let  youStrDate=date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    return youStrDate;
  }


  nzVisibleOpen(): void {
    let a = this.elementView.nativeElement.offsetParent.offsetParent.clientHeight / 5
    this.divewheight = a * 3;
    this.ShowMessage = true;
  }
  Closeimage(): void {
     this.ShowMessage = false;

  }


  nzVisibleClose(event,index): void {
   //this.nzVisible = false;
    setTimeout(function() {

      event.path[16].childNodes[13].style.cssText='z-index:9999 !important;position:absolute';

    },10)
   // this.elementView.nativeElement.root.siblings();
  }

  open(): void {
    this.visible2 = true;
  }

  close(): void {
    this.visible2 = false;
  }







  onChangecreate(result: Date[]): void {
   // console.log('From: ', result[0], ', to: ', result[1]);
  }

  onChangeUpdate(result: Date[]): void {
   // console.log('From: ', result[0], ', to: ', result[1]);
  }


  onChangeweighTime(result: Date[]): void {
    //console.log('From: ', result[0], ', to: ', result[1]);
  }
  onChangeweightWoTime(result: Date[]): void {
    //console.log('From: ', result[0], ', to: ', result[1]);
  }


  addform():void{
    this.validateForm.addControl('id', new FormControl());
    this.validateForm.addControl('serialNumber', new FormControl());
    this.validateForm.addControl('carId', new FormControl());
    this.validateForm.addControl('functionId', new FormControl());
    this.validateForm.addControl('address', new FormControl());
    this.validateForm.addControl('zfXs', new FormControl());
    this.validateForm.addControl('fl', new FormControl());
    this.validateForm.addControl('decWeight', new FormControl());
    this.validateForm.addControl('grossWeight', new FormControl());
    this.validateForm.addControl('actualTare', new FormControl());
    this.validateForm.addControl('netWeight', new FormControl());
    this.validateForm.addControl('trueWeight', new FormControl());
    this.validateForm.addControl('unitPrice', new FormControl());
    this.validateForm.addControl('weighingFee', new FormControl());
    this.validateForm.addControl('mzWeighMan', new FormControl());
    this.validateForm.addControl('pzWeighMan', new FormControl());
    this.validateForm.addControl('theMoney', new FormControl());
    this.validateForm.addControl('createTime', new FormControl());
    this.validateForm.addControl('updateTime', new FormControl());
    this.validateForm.addControl('weighTime', new FormControl());
    this.validateForm.addControl('weighType', new FormControl());
    this.validateForm.addControl('weightWoTime', new FormControl());
  }

  }


