<div nz-row style="height: 100%">
  <nz-layout>

    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
      <div class="logo">
        <h2  nz-typography style="color:#001529">后台管理</h2>
      </div>
      <ul nz-menu nzTheme="dark" nzMode="inline">
        <li nz-submenu nzTitle="人员管理" nzIcon="user">
          <ul *ngFor="let data of users">
<!--            [routerLink]= "'use/'+data.id"-->
            <li nz-menu-item (click)="jump(data.id)" >{{data.username}}</li>
          </ul>
        </li>
        <li nz-submenu nzTitle="角色管理" nzIcon="team">
          <ul>
            <li nz-menu-item  [routerLink]="['Actor']">角色管理</li>
          </ul>
<!--          <ul *ngFor="let role of roles">-->
<!--            <li nz-menu-item>{{role.rolename}}</li>-->
<!--          </ul>-->
        </li>

        <li nz-submenu nzTitle="公司管理" nzIcon="home" *ngIf="levId ==0">
          <ul>
            <li nz-menu-item  [routerLink]="['Company']">公司管理</li>
          </ul>
        </li>
      </ul>
    </nz-sider>
    <nz-layout>



      <nz-header>

        <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i>

        <i nz-icon nzType="home" nzTheme="outline" [nzTheme]="'twotone'" style="margin-left: 91%;font-size: 37px;margin-top: 15px;" (click)="homeJump()"></i>
      </nz-header>

      <nz-content>
        <nz-breadcrumb>
<!--          <nz-breadcrumb-item>User</nz-breadcrumb-item>-->
<!--          <nz-breadcrumb-item>Bill</nz-breadcrumb-item>-->
        </nz-breadcrumb>
        <div class="inner-content">
          <router-outlet style="height: 100%;width: 100%;" #child1></router-outlet>
        </div>
      </nz-content>
      <nz-footer>@上海鲸创电子科技有限公司</nz-footer>
    </nz-layout>
  </nz-layout>
</div>
