import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class companyService {
  constructor(
    private apiService: ApiService
  ) { }

  findAll(){
    return this.apiService.callApi('/CompanyController/findAll'
    ).pipe(
      map(data => {
          return data;
        }
      ));
  }

  addCompany(id:number,companyName:string,count:string){
    return this.apiService.callApi('/userController/Registrationcode',{
      id:id,
      company:companyName,
      count:count
      }
    ).pipe(
      map(data => {
          return data;
        }
      ));
  }

}

