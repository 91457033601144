import { Injectable } from '@angular/core';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class fileOssService {
  constructor(
    private apiService: ApiService
  ) { }



  findFileOssByRelation(relation:string,electronicId:number){
    return this.apiService.callApi('/FileOssController/findFileOssByRelation',{
      relation:relation,
      electronicId:electronicId
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }

  findWeightByIdImageORVideo(weightId:number){
    return this.apiService.callApi('/WeightController/findWeightByIdImageORVideo',{
      weightId:weightId
    }).pipe(
      map(data => {
          return data;
        }
      ));
  }



}
