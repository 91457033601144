<nz-layout class="app-layout"></nz-layout>

<div nz-row style="height:100%;width:100%;">
  <div ng-zorro nz-col [nzSpan]="4" #leftheight class="leftoption">
    <nz-input-group [nzSuffix]="suffixIcon">
      <input type="text" nz-input placeholder="查找名称" [(ngModel)]="searchValue"/>
    </nz-input-group>
    <ng-template #suffixIcon>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-tree
      [nzVirtualHeight]="leftoverheight"
      [nzData]="nodes"
      [nzSearchValue]="searchValue"
      (nzClick)="nzEvent($event)"
      (nzExpandChange)="nzEvent($event)"
      (nzSearchValueChange)="nzEvent($event)"
      [nzExpandAll]="false"
      nzShowIcon>
    </nz-tree>
  </div>
  <div ng-zorro nz-col [nzSpan]="20" #body style="height:98%;">
    <div style="height:100%;width:100%;">

      <div id="map" style="width:100%;height: 100%;">

      </div>



      <div id="up-map-div" (click)="openModal()">
        <button nz-button nzType="primary" nzShape="circle"><i nz-icon nzType="edit"></i></button>
      </div>

    </div>
  </div>
</div>

<nz-drawer [nzClosable]="false" nzWidth="300" [nzVisible]="nzdrawer" nzPlacement="right" nzTitle="地理信息"
           (nzOnClose)="close()">

  <form nz-form [formGroup]="validateForm">
    <nz-form-item>
      <nz-form-label [nzSpan]="6">标识</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="id" type="number" [disabled]="true"/>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="6">名称</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="functionname">
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label [nzSpan]="6">父id</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="functionid" type="text">
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label [nzSpan]="6">创建时间</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="createtime" type="text">
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label [nzSpan]="6">修改时间</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="updatetime" type="text">
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label [nzSpan]="6">经度</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="xshaft" type="number">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="6">维度</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="yshaft" type="number">
      </nz-form-control>
    </nz-form-item>


    <nz-form-item>
      <nz-form-label [nzSpan]="6">备注</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <input nz-input formControlName="remark" type="text">
      </nz-form-control>
    </nz-form-item>
  </form>
  <div nz-row>
    <label>最终功能：</label>
    <nz-switch [ngModel]="functionclick"  (click)="functionclickSwitch()" nzUnCheckedChildren="开" nzCheckedChildren="关"></nz-switch>
  </div>
  <div nz-row>
    <label>启用：</label>
    <nz-switch [ngModel]="modelclick"  (click)="modelclickSwitch()" nzUnCheckedChildren="开" nzCheckedChildren="关"></nz-switch>
  </div
  >
  <br/>
  <br/>
  <br/>
  <div nz-row>
    <div nz-col [nzSpan]="8">
      <button nz-button type="submit" nzType="primary" (click)="submit()">提交</button>
    </div>
    <div nz-col [nzSpan]="8">
      <button nz-button type="reset" nzDanger>重置</button>
    </div>
    <!--    <div nz-col [nzSpan]="8">  <a nz-button nzType="link" href="javaScript:void(0)" (click)="linkdata(id)">详细</a></div>-->
  </div>

</nz-drawer>

<nz-modal
  [(nzVisible)]="isVisible"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()">
  <!-- 头部-->
  <ng-template #modalTitle>
    Custom Modal Title
  </ng-template>
  <!--内容 -->
  <ng-template #modalContent>
    <div nz-row>
      <div nz-col [nzSpan]="12">
        <nz-cascader [nzChangeOn]="validateLeft" [nzOptions]="nzOptionsLeft" [(ngModel)]="valuesLeft"
                     (ngModelChange)="onChangesLeft($event)"></nz-cascader>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-cascader [nzChangeOn]="validateRight" [nzOptions]="nzOptionsRight" [(ngModel)]="valuesRight"
                     (ngModelChange)="onChangesRight($event)"></nz-cascader>
      </div>
    </div>
    <div nz-row>
      <div nz-col [nzSpan]="24">
        <nz-transfer
          [nzDataSource]="list"
          [nzDisabled]="disabled"
          [nzTitles]="['前', '后']"
          [nzListStyle]="{ 'width.px': 200, 'height.px': 300 }"
          (nzSelectChange)="select($event)"
          [nzSelectedKeys]="['0', '2']"
          (nzChange)="change($event)">
        </nz-transfer>
      </div>
    </div>
  </ng-template>
  <!-- 尾部-->
  <ng-template #modalFooter>
    <span>Modal Footer: </span>
    <button nz-button nzType="default" (click)="handleCancel()">重置</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">保存</button>
  </ng-template>
</nz-modal>



