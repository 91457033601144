import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {  ApiService } from '../../core';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private apiService: ApiService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.can();
  }

  can():boolean{
    this.apiService.callApi("/user/api/info.json").subscribe((res) => {
      if(res.statusCode == "200"){
        this.router.navigate(["/"])
      }else{
      }
    });
    return true;
  }

}
