import {Component, OnInit} from '@angular/core';
import {NzSafeAny} from 'ng-zorro-antd/core/types';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {fuctionService, usersService} from '../core/services';
import {NzMessageService} from 'ng-zorro-antd/message';
import endOfMonth from 'date-fns/endOfMonth';
import {NzDateMode} from 'ng-zorro-antd';
import {util} from '../Util';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  validateForm: FormGroup;
  timeForm: FormGroup;
  roleForm: FormGroup;
  passForm: FormGroup;
  userId = 0;
  basi = true;
  timeOver = false;
  passWord = false;
  setupthe = false;
  function = false;
  dateMode: NzDateMode = 'time';
  deadline = Date.now();
  startValue: Date | null = new Date;
  selectedProvince = '1';
  rolses: any[];
  listOfData: any[] = [];
  loginOfData: any[] = [];
  editId: string | null = null;
  isVisible = false;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private getparam: ActivatedRoute,
    private userService: usersService,
    private message: NzMessageService,
    private Util: util,
    private fuctionService: fuctionService,
  ) {
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showModal(): void {
    this.isVisible = true;
  }


  startEdit(id: string): void {
    this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }


  addRowList(id: string): void {
    let that = this;
    this.fuctionService.addFunction(this.userId, Number(id)).subscribe(data => {
      if (data.statusCode == 200) {
        that.message.create('success', '赋给成功');
        that.reaSh();

      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });
  }

  deleteRow(id: string): void {
    let that = this;
    this.fuctionService.deleteFunctionByUserId(this.userId, Number(id)).subscribe(data => {
      if (data.statusCode == 200) {
        this.listOfData = this.listOfData.filter(d => d.id !== id);
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });

  }

  reaSh(): void {
    let that=this;
    /**
     * 功能列表
     */
    this.fuctionService.findFunctionByuserId(that.userId).subscribe(data => {
      if (data.statusCode == 200) {
        let list = data.content;
        that.listOfData = list;
        if (list == null || list.length <= 0) {
          that.listOfData = [];
        }
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });
  }

  ngAfterViewInit() {
// 监听路由变化
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }


  ngOnInit(): void {
    let that = this;
    this.getparam.params.subscribe(result => {
      if (result.usersId != undefined && result.usersId != 0 && result.usersId != null) {
        that.userId = result.usersId;
      }
    });

    this.validateForm = this.fb.group({
      useId: [{value: '', disabled: true}, [Validators.required], [this.userNameAsyncValidator]],
      userName: ['', [Validators.required], [this.userNameAsyncValidator]],
      email: [{value: '', disabled: true}, [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]],
      mobile: [{value: '', disabled: true}, [this.confirmValidator]],
      comment: [{value: '', disabled: true}, [Validators.required]],
      idCard: [{value: '', disabled: true}, [Validators.required]],
      age: ['', [Validators.required]],
      company: [{value: '', disabled: true}, [Validators.required]],
      roleName: [{value: '', disabled: true}, [Validators.required]],
      roleId: ['', [Validators.required]],
      failure: ['', [Validators.required]],
    });


    this.timeForm = this.fb.group({
      failure: ['', [Validators.required]],
    });
    this.roleForm = this.fb.group({
      roleName: [{value: '', disabled: true}, [Validators.required]],
      roleNameup: ['', [Validators.required]],
    });
    this.passForm = this.fb.group({
      password: ['', [Validators.required]],
      confirm: [{value: '', disabled: true}, [this.confirmValidator]],
    });


    this.userService.findUserByUserId(that.userId).subscribe(data => {
      if (data.statusCode == 200) {
        let users = data.content.users;
        let role = data.content.role;
        let company = data.content.company;
        that.validateForm.controls.useId.setValue(users.id);
        that.validateForm.controls.userName.setValue(users.username);
        that.validateForm.controls.email.setValue(users.email);
        that.validateForm.controls.mobile.setValue(users.phone);
        that.validateForm.controls.idCard.setValue(users.idcard);
        that.validateForm.controls.age.setValue(users.age);
        that.validateForm.controls.failure.setValue(users.failure);
        that.timeForm.controls.failure.setValue(users.failure);
        let date = new Date(users.failure);
        that.deadline = date.getTime();
        if (role != null) {
          that.validateForm.controls.roleId.setValue(role.id);
          that.validateForm.controls.roleName.setValue(role.rolename);
          that.roleForm.controls.roleName.setValue(role.rolename);
        }
        if (company != null) {
          that.validateForm.controls.company.setValue(company.companyName);
        }
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }

    });

    this.userService.findRole().subscribe(data => {
      if (data.statusCode == 200) {
        that.rolses = data.content;
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }


    });

    /**
     * 功能列表
     */
    this.fuctionService.findFunctionByuserId(that.userId).subscribe(data => {
      if (data.statusCode == 200) {
        let list = data.content;
        that.listOfData = list;
        if (list == null || list.length <= 0) {
          that.listOfData = [];
        }
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });

    this.fuctionService.loginFunction().subscribe(data => {
      if (data.statusCode == 200) {
        let list = data.content;
        that.loginOfData = list;
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }

    });


  }

  submitrole() {
    let that = this;
    if (this.validateForm.controls.roleId.value != this.selectedProvince) {
      let userId = this.validateForm.controls.useId.value;
      let roleIdId = this.selectedProvince;
      this.userService.SaveUserRole(userId, Number(roleIdId)).subscribe(data => {
        if (data.statusCode == 200) {
          that.validateForm.controls.roleId.setValue(data.content.roleid);
          that.message.create('success', '更改成功');
        } else {
          if (data.content.code == 40002) {
            that.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            that.message.create('error', data.content.subMsg);
          } else {
            that.message.create('error', data.content);
          }
        }
      });

    } else {
      return false;
    }
  }


  timeSubmit() {
    let that = this;
    if (this.startValue != null) {
      let str = this.Util.parSeStr(String(this.timeForm.controls.failure.value));
      let userId = this.validateForm.controls.useId.value;
      this.userService.SaveUserTime(userId, str).subscribe(data => {
        if (data.statusCode == 200) {
          that.validateForm.controls.failure.setValue(data.content.failure);
          let date = new Date(data.content.failure);
          that.deadline = date.getTime();
          that.message.create('success', '更改成功');
        } else {
          if (data.content.code == 40002) {
            that.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            that.message.create('error', data.content.subMsg);
          } else {
            that.message.create('error', data.content);
          }
        }
      });
    }

  }

  passWordSubmit() {
    let that = this;
    let passWord = this.validateForm.controls.password.value;
    let userId = this.validateForm.controls.useId.value;
    if (passWord != null) {
      that.userService.SaveUserPass(userId, passWord).subscribe(data => {
        if (data.statusCode == 200) {
          that.message.create('success', '更改成功');
        } else {
          if (data.content.code == 40002) {
            that.message.create('error', data.content.subMsg);
          } else if (data.content.code == 20001) {
            that.message.create('error', data.content.subMsg);
          } else {
            that.message.create('error', data.content);
          }
        }
      });
    }
  }


  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.comment.updateValueAndValidity());
  }

  handleDateOpenChange(open: boolean): void {
    if (open) {
      this.dateMode = 'time';
    }
  }

  handleDatePanelChange(mode: string | NzDateMode[] | string[]): void {
    console.log('handleDatePanelChange: ', mode);
  }

  submitForm(value: { userName: string; email: string; password: string; confirm: string; comment: string; age: number }): void {
    let that = this;
    let userName = value.userName;
    let age = value.age;
    let userId = this.validateForm.controls.useId.value;
    that.userService.SaveUsermess(userId, age, userName).subscribe(data => {
      if (data.statusCode == 200) {
        that.message.create('success', '更改成功');
      } else {
        if (data.content.code == 40002) {
          that.message.create('error', data.content.subMsg);
        } else if (data.content.code == 20001) {
          that.message.create('error', data.content.subMsg);
        } else {
          that.message.create('error', data.content);
        }
      }
    });


    // for (const key in this.validateForm.controls) {
    //   this.validateForm.controls[key].markAsDirty();
    //   this.validateForm.controls[key].updateValueAndValidity();
    // }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  validateConfirmPassword(): void {
    this.validateForm.controls.confirm.updateValueAndValidity();
  }

  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({error: true, duplicated: true});
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {error: true, required: true};
    } else if (control.value !== this.validateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  };

  clickshowall(mon: number) {
    let that = this;
    switch (mon) {
      case 0:
        that.basi = true;
        that.timeOver = false;
        that.passWord = false;
        that.setupthe = false;
        that.function = false;
        break;
      case 1:
        that.basi = false;
        that.timeOver = true;
        that.passWord = false;
        that.setupthe = false;
        that.function = false;
        break;
      case 2:
        that.basi = false;
        that.timeOver = false;
        that.passWord = true;
        that.setupthe = false;
        that.function = false;
        break;
      case 3:
        that.basi = false;
        that.timeOver = false;
        that.passWord = false;
        that.setupthe = true;
        that.function = false;
        break;
      case 4:
        that.basi = false;
        that.timeOver = false;
        that.passWord = false;
        that.setupthe = false;
        that.function = true;
        break;
    }
  }


}
