<nz-layout class="app-layout"></nz-layout>
<div nz-row style="height:100%;width:100%;">
  <!-- 树形下拉-->
  <div ng-zorro nz-col [nzSpan]="4" #leftheight>
    <nz-input-group [nzSuffix]="suffixIcon">
      <input type="text" nz-input placeholder="查找名称" [(ngModel)]="searchValue"/>
    </nz-input-group>
    <ng-template #suffixIcon>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-tree
      [nzVirtualHeight]="leftoverheight"
      [nzData]="nodes"
      [nzSearchValue]="searchValue"
      (nzClick)="nzEvent($event)"
      (nzExpandChange)="nzEvent($event)"
      (nzSearchValueChange)="nzEvent($event)"
      [nzExpandAll]="false"
      nzShowIcon>
    </nz-tree>
  </div>
  <div class="interval"></div>
  <!-- 主体结构-->
  <div ng-zorro nz-col [nzSpan]="19" #body>
    <div nz-row style="height: 10%">
      <div ng-zorro nz-col [nzSpan]="24">
        <form nz-form [formGroup]="validateForm">
          <div nz-row nzGutter="12">

            <div ng-zorro nz-col [nzSpan]="8">
              <nz-form-item>
                <nz-form-label nzFor="email">时间</nz-form-label>
                <nz-form-control>
                  <nz-range-picker
                    [nzRanges]="ranges1"
                    nzShowTime
                    nzFormat="yyyy/MM/dd HH:mm:ss"
                    ngModel
                    formControlName="createtime"
                    (ngModelChange)="onChangecreate($event)"
                  ></nz-range-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div ng-zorro nz-col [nzSpan]="8">
              <nz-form-item>
                <nz-form-label>报表类型</nz-form-label>
                <nz-form-control>
                  <nz-select style="width: 50%" formControlName="type" (ngModelChange)="selectchange($event)"
                             [ngModel]="selectedValue" nzShowSearch nzAllowClear nzPlaceHolder="报表类型选择">
                    <!--                <nz-option nzLabel="秒报表" nzValue="seconds"></nz-option>-->
                    <!--                <nz-option nzLabel="分钟报表" nzValue="minutes"></nz-option>-->
                    <nz-option nzLabel="小时报表" nzValue="hours"></nz-option>
                    <nz-option nzLabel="日报表" nzValue="day"></nz-option>
                    <nz-option nzLabel="月报表" nzValue="month"></nz-option>
                    <nz-option nzLabel="年报表" nzValue="year"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div ng-zorro nz-col [nzSpan]="8">
              <button nz-button nzType="primary" type="submit" (click)="serach()">查询</button>
              <button nz-button nzType="primary" nzDanger type="reset" style="margin-left: 10px">重置</button>
            </div>

          </div>
        </form>
      </div>
    </div>
    <div nz-row style="height: 86%">
      <div ng-zorro nz-col [nzSpan]="24">
        <section>
          <d-tabs [type]="'tabs'" [(activeTab)]="tab1acticeID" (activeTabChange)="activeTabChange($event)">
            <d-tab id="tab1" title="表格" tabId="tab1">
              <ng-template dTabContent>
                <nz-table #editRowTable
                          [nzShowPagination]="false"
                          [nzScroll]="{ y: scrollY}"
                          [nzPageSize]="pagesize"
                          [nzLoading]="nzLoading"
                          [nzData]="listOfData" nzTableLayout="fixed">

                  <thead>
                  <tr>
                    <th nzWidth="140px">站点名称</th>
                    <th nzWidth="140px">时间</th>
                    <th nzWidth="140px">过磅记录</th>
                    <th nzWidth="140px">超载量率</th>
                    <th nzWidth="140px">超载率</th>
                    <th nzWidth="140px">车辆总容量量</th>
                    <th nzWidth="140px">过磅总量</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of editRowTable.data">
                    <ng-container>
                      <td>{{data.functionname}}</td>
                      <td>{{data.time}}</td>
                      <td>{{data.weightscount}}</td>
                      <td>{{data.division != null ? data.division : 0}}%</td>
                      <td>{{data.percentage != null ? data.percentage : 0}}%</td>
                      <td>{{data.totalamount}}</td>
                      <td>{{data.trueweight}}</td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <nz-pagination
                        [nzPageIndex]="pagenum"
                        [nzTotal]="Total"
                        (nzPageIndexChange)="changepage($event)"
                        (nzPageSizeChange)="changePageSize($event)"
                        [nzPageSizeOptions]=[10,20,30,50,100]
                        nzShowSizeChanger

                        nzShowQuickJumper></nz-pagination>
                    </td>
                  </tr>
                  </tbody>

                </nz-table>

                <p></p>
              </ng-template>
            </d-tab>

            <d-tab id="tab2" title="图形" tabId="tab2">
              <ng-template dTabContent >
                <div nz-row style="height: 48%">
                  <div ng-zorro nz-col [nzSpan]="24">
                    <div #myCharts echarts [options]="options"   class="demo-chart"></div>
                  </div>
                </div>
                <div nz-row style="height: 48%">
                  <div ng-zorro nz-col [nzSpan]="12"  style="height: 100%" >
                    <div echarts [options]="optionsPieChart" style="height: 320%"  class="demo-chart"></div>
                  </div>
                  <div ng-zorro nz-col [nzSpan]="12" style="height: 100%">
                    <div echarts [options]="optionsColumnar"  style="height: 320%" class="demo-chart"></div>
                  </div>
                </div>
              </ng-template>
            </d-tab>

          </d-tabs>
        </section>
      </div>
    </div>
  </div>

</div>
