<nz-layout class="app-layout"></nz-layout>
<div nz-row style="height: 100%">
  <div nz-col nzSpan="24">
    <button nz-button (click)="showModal()" nzType="primary">添加</button>
    <nz-table #editRowTable nzBordered [nzData]="listOfData">
      <thead>
      <tr>
        <th nzWidth="30%">公司名称</th>
        <th>生成码</th>
        <th>创建时间</th>
        <th>修改时间</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of editRowTable.data" class="editable-row">
        <td>
          <div class="editable-cell">
            {{ data.companyName }}
          </div>
        </td>
        <td>{{ data.code }}</td>
        <td>{{ data.createTime }}</td>
        <td>{{ data.updateTime }}</td>
        <td><a (click)="update(data.companyName,data.id)">添加用户数量</a></td>
      </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="添加" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <form nz-form [nzAutoTips]="autoTips" [formGroup]="validateForm" (ngSubmit)="submitForm(validateForm.value)">

    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>公司名称</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <input nz-input formControlName="companyName" placeholder="请输入您的公司名称" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="7" nzRequired>可以生成账号数量</nz-form-label>
      <nz-form-control [nzSpan]="12">
        <nz-input-number   formControlName="count" [ngModel]="1"   [nzMin]="1"  [nzStep]="1"></nz-input-number>
      </nz-form-control>
    </nz-form-item>



    <nz-form-item>
      <nz-form-control [nzOffset]="7" [nzSpan]="12">
        <button nz-button nzType="primary">提交</button>
      </nz-form-control>
    </nz-form-item>

  </form>


</nz-modal>

