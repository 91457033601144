<nz-layout class="app-layout">
</nz-layout>
<!-- 总 -->
<div nz-row style="height: 100%">
  <!-- 左边导航栏目 -->
  <div ng-zorro nz-col [nzSpan]="isCollapsed ? 1 : 4" id="leg">

    <div nz-row>
      <div ng-zorro nz-col [nzSpan]="20">
        <i class="ATW" *ngIf="isCollapsed==false" nzDisabled>ATW</i>
        <button class="DHhide" *ngIf="isCollapsed==true" nz-button nzType="primary" (click)="toggleCollapsed()">
          <i nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </button>
      </div>
      <div nz-col nzSpan="4">
        <button nz-button nzType="primary" *ngIf="isCollapsed==false" (click)="toggleCollapsed()">
          <i nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </button>
      </div>

    </div>


    <div nz-row>
      <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <li nz-submenu nzTitle="超限业务" nzIcon="bars" nzSelected>
          <ul>
            <!--              [routerLink]=""-->
            <li nz-submenu nzTitle="GIS地图"  [routerLink]="['TheMap']"    (click)="headandjump2('/TheMap','GIS地图')"
                nzIcon="environment"></li>
          </ul>
          <!--            <ul>-->
          <!--              <li nz-submenu nzTitle="历史数据" (click)="headandjump2(' ','历史数据')" nzIcon="table" ></li>-->
          <!--            </ul>-->
          <ul>
            <li nz-submenu nzTitle="统计报表" [routerLink]="['Report']"  (click)="headandjump2('/Report','统计报表')"
                nzIcon="pie-chart"></li>
          </ul>

          <ul>
            <li nz-submenu nzTitle="电子围栏"  [routerLink]="['electronics']"  routerLinkActive="active" (click)="headandjump2('/electronics','电子围栏')"
                nzIcon="car" nzTheme="twotone"></li>
          </ul>

        </li>
        <li nz-submenu nzTitle="实时数据" nzIcon="bar-chart" nzSelected>
          <ul>
            <li nz-submenu nzTitle="实时数据" [routerLink]="['thisDataGeorAphic/0']"
                (click)="headandjump2('/thisDataGeorAphic/0','实时数据')" nzIcon="bar-chart"></li>
          </ul>
        </li>


        <li nz-submenu nzTitle="实时视频" nzIcon="video-camera" nzSelected>
          <ul>
            <li nz-submenu nzTitle="实时视频"   [routerLink]="['showVideo']" (click)="headandjump2('/showVideo','实时视频')" nzIcon="video-camera"></li>
          </ul>
        </li>

        <li nz-submenu nzTitle="大屏投射" nzIcon="expand" nzSelected>
          <ul>
            <li nz-submenu nzTitle="大屏投射"  (click)="headand('/show','大屏投射')" nzIcon="expand"></li>
          </ul>
        </li>



        <li nz-submenu nzTitle="后台管理" nzIcon="expand" nzSelected *ngIf="management==true">
          <ul>
            <li nz-submenu nzTitle="后台管理"  [routerLink]="['/Management']"  (click)="headandjump2('/Management','后台管理')" nzIcon="expand"></li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
  <div nz-col [nzSpan]="isCollapsed ? 23 : 20" class="ATWFater">
    <!-- 右部分 -->
    <div nz-row class="TOP">
      <div nz-col nzSpan="20">
        <ul nz-menu nzMode="horizontal" style="height: 100%;">
          <li nz-menu-item (click)="goHeroes(heads.connecturl)" *ngFor="let heads of headarrmenu; let i = index"
              [nzSelected]="i==index ? true:false" routerLinkActive="active">
            {{heads.name}}
            <i nz-icon class="ATWCLOSE" nzType="close" (click)="onClick($event,i)"></i>
          </li>
          <!--   ant-menu-item active-->
        </ul>
      </div>
      <div nz-col nzSpan="4">
        <div nz-row>
          <div  nz-col nzSpan="14">
            <nz-countdown style="margin: auto;width: 100%; " [nzValue]="deadline" [nzTitle]="'剩余使用时间'"
                          [nzFormat]="'D 天 H 时'" ></nz-countdown>
          </div>
          <div  nz-col nzSpan="10">
            <nz-avatar [nzSize]="53" nzIcon="user"
                       nz-popover
                       [nzPopoverTitle]="accoun"
                       [(nzPopoverVisible)]="visible"
                       nzPopoverTrigger="click"
                       [nzPopoverContent]="contentTemplate"></nz-avatar>
            <ng-template #contentTemplate >
              <div nz-row>
                <a nz-button nzType="primary"
                   nz-popconfirm
                   (nzOnConfirm)="clickMe()"
                   (nzOnCancel)="cancel()"
                   nzPopconfirmTitle="确 认 退 出 登 录?"
                ><i nz-icon [nzIconfont]="'icon-tuichu'"></i>退出</a>
              </div>
            </ng-template>

          </div>
        </div>



      </div>
    </div>

    <!--主窗口 -->
    <div nz-row id="MASTER" class="DOWN">
      <div id="fromUpdate">
        <router-outlet style="margin-left: 12px;" (activate)="onActivate($event)"></router-outlet>
      </div>
    </div>
  </div>
</div>


<nz-modal [(nzVisible)]="isVisible" nzTitle="选择您的地区"    (nzOnCancel)="closeOk()" (nzOnOk)="handleOk()" *ngIf="isVisible">
  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" [(ngModel)]="selectedValue" (ngModelChange)="log($event)"
             style="width: 60%;margin: 0 100px;">
    <nz-option *ngFor="let data of functionValue" nzLabel="{{data.functionname}}" nzValue="{{data.id}}"></nz-option>
  </nz-select>

</nz-modal>
